import Cookies from 'js-cookie';
import apiConfigs from '../configs/apiConfigs';
export const maskUSPhone = (text: string) => {
    let x: any = text.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    text = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    return text;
}
export const takeOnlyDigit = (maskedText: string) => {
    return maskedText.replace(/[^0-9]/g, "");
}
export const takeOnlyDigitAndPlus = (maskedText: string) => {
    return maskedText.replace(/[^0-9+]/g, "");
}
export const preapreSubmitForm = (submitForm: any) => {
    const finalObj = {};
    Object.assign(finalObj, { speciality: 9 });
    Object.assign(finalObj, { referrer: "" });
    Object.assign(finalObj, { distance: 10 });
    /* Checks location present */
    if (submitForm.location) {
        Object.assign(finalObj, { location: submitForm.location });
    } else {
        Object.assign(finalObj, { location: null });
    }
    /* Checks attitude present */
    if (submitForm.attitude) {
        Object.assign(finalObj, { attitude: submitForm.attitude.value });
    } else {
        Object.assign(finalObj, { attitude: null });
    }
    /* Check goals present */
    if (submitForm.goals) {
        Object.assign(finalObj, { goals: submitForm.goals });
    } else {
        Object.assign(finalObj, { goals: null });
    }
    /* Check criterias present */
    if (submitForm.criterias) {
        Object.assign(finalObj, { criterias: submitForm.criterias });
    } else {
        Object.assign(finalObj, { criterias: null });
    }
    /* Check Reason present. */
    const reason = {};
    if (submitForm.dentistServices) {
        Object.assign(reason, { reasonids: submitForm.dentistServices });
    } else {
        Object.assign(reason, { reasonids: null });
    }
    if (submitForm.dentistUrgent) {
        Object.assign(reason, {
            reasonUrgent: {
                id: submitForm.dentistUrgent.id,
                description: submitForm.dentistUrgent.reason
            }
        });
    } else {
        Object.assign(reason, { reasonUrgent: null });
    }
    if (submitForm.reason) {
        Object.assign(reason, { title: submitForm.reason });
        Object.assign(finalObj, { reason: reason });
    } else {
        Object.assign(finalObj, { reason: null });
    }
    /* Check for last visited */
    if (submitForm.lastVisited) {
        Object.assign(finalObj, { lastVisited: submitForm.lastVisited.value + " " + submitForm.lastVisited.label });
    } else {
        Object.assign(finalObj, { lastVisited: null });
    }
    /* Check for dental anxiety */
    if (submitForm.dentalAnxiety) {
        Object.assign(finalObj, { dentalAnxiety: submitForm.dentalAnxiety.value + " " + submitForm.dentalAnxiety.label });
    } else {
        Object.assign(finalObj, { dentalAnxiety: null });
    }
    /* Check for availability */
    if (submitForm.availabilities) {
        const mappedAvailability = submitForm.availabilities.map((value: any) => {
            return value.value;
        });
        Object.assign(finalObj, { availability: mappedAvailability });
    } else {
        Object.assign(finalObj, { availability: null });
    }
    /* Check for how soon */
    if (submitForm.howSoon) {
        Object.assign(finalObj, { howSoon: submitForm.howSoon.value });
    } else {
        Object.assign(finalObj, { howSoon: null });
    }
    /* Check for insurance */
    if (submitForm.insurance) {
        Object.assign(finalObj, { hasInsurance: submitForm.insurance.value });
    } else {
        Object.assign(finalObj, { hasInsurance: null });
    }
    /* Check for insurance details */
    if (submitForm.insuranceDetail) {
        Object.assign(finalObj, { insurance: submitForm.insuranceDetail });
    } else {
        Object.assign(finalObj, { insurance: null });
    }
    /* Check for gender */
    if (submitForm.gender) {
        Object.assign(finalObj, { gender: submitForm.gender.label });
    } else {
        Object.assign(finalObj, { gender: null });
    }
    /* Check for social assistance */
    if (submitForm.socialAssistance) {
        Object.assign(finalObj, { socialAssistance: submitForm.socialAssistance.value });
    } else {
        Object.assign(finalObj, { socialAssistance: null });
    }
    /* Check for ready to book */
    if (submitForm.readyToBook) {
        Object.assign(finalObj, { readyToBook: submitForm.readyToBook.label });
        if (submitForm.readyToBook.label.toLowerCase() === "no") {
            if (submitForm.notreadyreason) {
                Object.assign(finalObj, { notReadyReason: submitForm.notreadyreason });
            } else {
                Object.assign(finalObj, { notReadyReason: null });
            }
        }
    } else {
        Object.assign(finalObj, { readyToBook: null });
    }
    /* Check for email */
    if (submitForm.email) {
        Object.assign(finalObj, { email: submitForm.email });
    } else {
        Object.assign(finalObj, { email: null });
    }

    /* Check for email */
    if (submitForm.phone) {
        Object.assign(finalObj, { phone: "" + submitForm.phone });
    } else {
        Object.assign(finalObj, { phone: null });
    }
    return finalObj;
}

export const timeConvert = (time: any) => {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) { // If time format correct
        time = time.slice(1);  // Remove full string match value
        time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
        if (time[0] < 10) {
            time[0] = "0" + time[0];
        }
    }
    return time.join(''); // return adjusted time or original string
}
export const isLogin = (cookieName: string) => {
    try {
        // const str: any = localStorage.getItem('login:Patient');
        const str: any = Cookies.getJSON(cookieName);
        // console.log(str);
        if (str === undefined || str === null || str === '') {
            return false;
        }
        // if (JSON.parse(str).uniqueId) {
        if (str.xpr_user_id) {
            return true;
        }
        return false;
    } catch (err) {
        return false;
    }
}
export const weekDay = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const setCookie = (response: any, cookieType: string) => {
    console.log(response, cookieType);
    const key1 = 'xprUser.' + cookieType + '-' + response.id;
    const key2 = 'xpr_user_id';
    const key3 = 'xprAuthUser';
    const key4 = 'xprUserName';
    const key5 = 'xprEmail';
    const key6 = 'xprIsGuarantor';
    const key7 = 'jwtToken';
    var json: any = {};
    json[key1] = true;
    json[key2] = response.uniqueId;
    json[key3] = response.authParam;
    json[key4] = response.firstName + " " + response.lastName;
    json[key5] = response.email;
    json[key6] = response.isGuarantor;
    json[key7] = response.jwtToken;;
    Cookies.set(cookieType + "_cookie", json);
    Cookies.set(cookieType + "_cookie", json,{domain:apiConfigs.COOKIE_DOMAIN});
    Cookies.set(cookieType + "_cookie",json, { domain: apiConfigs.PATIENT_COOKIE_DOMAIN });
    // Cookies.set(cookieType + "_cookie", json, { domain: apiConfigs.COOKIE_DOMAIN, path: '/', expires: 30 });
}

export const removeCookie = (cookieName: string) => {
    Cookies.remove(cookieName);
    Cookies.remove(cookieName, { domain: apiConfigs.COOKIE_DOMAIN, path: '/' });
    Cookies.remove(cookieName, { domain: apiConfigs.PATIENT_COOKIE_DOMAIN });
}
export const getCookie = () => {
    // const str: any = localStorage.getItem('login');
    // return JSON.parse(str);
    return Cookies.getJSON('patient_cookie');
}
export const prepareCookie = () => {
    const json = Cookies.getJSON('patient_cookie');
    console.log('JsonFile',json);
    let cookieString: string = '';
    for (let key in json) {
        if (json.hasOwnProperty(key)) {
            let val = json[key];
            cookieString = cookieString + key + '=' + val + ';';
        }
    }
    console.log('CookieString',cookieString)
    return cookieString;
}


export function getAge(dateString: string) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}