import React from 'react';
import './PopUpStyle.css';
import { allowDigit, allowAlphabetsAndSpace, emailValidation } from '../../utils/validators';
import { takeOnlyDigit, takeOnlyDigitAndPlus } from '../../utils/global';
import { registerUser, verifyCode } from '../../services/dentist';
import InputMask from 'react-input-mask';
import PinInput from 'react-pin-input';
import Axios from 'axios';
import apiConfigs from '../../configs/apiConfigs';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { signInWithCustomToken } from '../../utils/firebaseUtils';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { CONTACTMETHODS } from '../../constants/contactMethods'
interface Props {
    closePopUp: (doRedirect: boolean) => void;
    isNewPatient: boolean;
    data: any;
    doctor: any;
    potentialAppointment: any;
    emailAddress: any;
    noteForOffice: any;
    userRegistrationResponse: any;
    isExistingClinic: boolean;
}
interface State {
    modalNumber: number;
    phone: string;
    fname: string;
    lname: string;
    phoneValidation: { isInValid: boolean, msg: string },
    fnameValidation: { isInValid: boolean, msg: string },
    lnameValidation: { isInValid: boolean, msg: string },
    verificationCode: string;
    verificationCodeFalse: boolean;
    successResponse: any;
    password: string;
    cpassword: string;
    passwordValidation: { isInValid: boolean, msg: string };
    btnChange: { isShow: boolean, msg: string };
    intervalTime: number;
    popUpOneLoader: boolean;
    popUpTwoLoader: boolean;
    popUpThreeLoader: boolean;
    verificationCodeValidation: { isInValid: boolean, msg: string };
    appointmentFor: any;
    dependantName: string;
    dependantRelation: string;
    isaddDependant: boolean;
    //Input
    firstName: any;
    lastName: any;
    relation: any;
    emailId: any;
    dob: any;
    mobile: any;
    gender: any;
    contactMethod: any;
    reminder: any;
    dependants: any[];
}
class PopUpFour extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalNumber: props.isNewPatient === true ? 1 : 3,
            phone: '', fname: '', lname: '',
            verificationCode: '',
            verificationCodeFalse: false,
            successResponse: '',
            password: '',
            cpassword: '',
            passwordValidation: { isInValid: false, msg: '' },
            btnChange: { isShow: false, msg: '' },
            phoneValidation: { isInValid: false, msg: '' },
            fnameValidation: { isInValid: false, msg: '' },
            lnameValidation: { isInValid: false, msg: '' },
            intervalTime: 30,
            popUpOneLoader: false,
            popUpTwoLoader: false,
            popUpThreeLoader: false,
            verificationCodeValidation: { isInValid: false, msg: '' },
            appointmentFor: props.userRegistrationResponse && props.userRegistrationResponse.dependant.length > 0 ? 0 : '', dependantName: '', dependantRelation: '',
            isaddDependant: false,
            firstName: '', lastName: '', relation: "Son", emailId: '', dob: moment().subtract(1, 'days'),
            mobile: '', gender: 'Male', contactMethod: CONTACTMETHODS.EMAIL_TEXT, reminder: "", dependants: props.userRegistrationResponse.dependant
        }
    }
    closePopUp = (doRedirect: boolean) => {
        this.setState({
            phone: '', fname: '', lname: '',
            phoneValidation: { isInValid: false, msg: '' },
            fnameValidation: { isInValid: false, msg: '' },
            lnameValidation: { isInValid: false, msg: '' },
            verificationCode: '',
            verificationCodeFalse: false,
            verificationCodeValidation: { isInValid: false, msg: '' },
            successResponse: '',
            password: '',
            cpassword: '',
            passwordValidation: { isInValid: false, msg: '' },
            btnChange: { isShow: false, msg: '' }
        })
        this.props.closePopUp(doRedirect);
    }
    registerUser() {
        this.setState({ popUpOneLoader: true });
        if (this.state.fname === "" || this.state.fname.trim() === "") {
            this.setState({
                fnameValidation: { isInValid: true, msg: 'First name required.' },
                lnameValidation: { isInValid: false, msg: '' },
                phoneValidation: { isInValid: false, msg: '' }
            });
            this.setState({ popUpOneLoader: false });
        } else if (this.state.lname === "" || this.state.lname.trim() === "") {
            this.setState({
                lnameValidation: { isInValid: true, msg: 'Last name required.' },
                fnameValidation: { isInValid: false, msg: '' },
                phoneValidation: { isInValid: false, msg: '' }
            });
            this.setState({ popUpOneLoader: false });
        } else if (this.state.phone === "") {
            this.setState({
                phoneValidation: { isInValid: true, msg: 'Phone required.' },
                lnameValidation: { isInValid: false, msg: '' },
                fnameValidation: { isInValid: false, msg: '' }
            });
            this.setState({ popUpOneLoader: false });
        } else if (takeOnlyDigit(this.state.phone).length !== 11) {
            this.setState({
                lnameValidation: { isInValid: false, msg: '' },
                fnameValidation: { isInValid: false, msg: '' },
                phoneValidation: { isInValid: true, msg: 'Phone invalid' }
            });
            this.setState({ popUpOneLoader: false });
        } else {
            this.setState({
                lnameValidation: { isInValid: false, msg: '' },
                fnameValidation: { isInValid: false, msg: '' },
                phoneValidation: { isInValid: false, msg: '' }
            });
            registerUser({ email: this.props.emailAddress, phone: takeOnlyDigitAndPlus(this.state.phone), fname: this.state.fname, lname: this.state.lname }).then((success: any) => {
                if (success.response && success.response.status === 200 && success.response.data.status) {
                    console.log(success);
                    if (success.response.data && success.response.data.isNewPatient === false) {
                        this.setState({ modalNumber: 3 });
                    } else if (success.response.data && success.response.data.isNewPatient === true
                        && success.response.data.patient && success.response.data.patient.id) {
                        this.setState({ modalNumber: 2 });
                        const interval = setInterval(() => {
                            if (this.state.intervalTime === 0) {
                                clearInterval(interval);
                            } else {
                                this.setState({ intervalTime: this.state.intervalTime - 1 });
                            }
                        }, 1000);
                    } else if (success.response.data && success.response.data.isNewPatient === true) {
                        this.setState({ modalNumber: 1 });
                    }
                    this.setState({ successResponse: success.response.data, appointmentFor: success.response.data.dependant.length > 0 ? 0 : '' });
                    this.setState({ popUpOneLoader: false });
                } else {
                    this.setState({
                        phoneValidation: { isInValid: true, msg: success.response.data.status.msg }
                    });
                    this.setState({ popUpOneLoader: false });
                }
            }).catch((error) => {
                this.setState({ popUpOneLoader: false });
                console.log(error);
            });
        }
    }
    verifyCode() {
        this.setState({ popUpTwoLoader: true });
        if (this.state.verificationCode.length < 4) {
            this.setState({ verificationCodeValidation: { isInValid: true, msg: 'Code should be of four digit' }, popUpTwoLoader: false });
            return;
        }
        this.setState({ verificationCodeValidation: { isInValid: false, msg: '' }, verificationCodeFalse: false });
        verifyCode({
            email: this.props.emailAddress,
            id: this.state.successResponse.patient.id,
            phone: this.state.successResponse.patient.phone,
            fname: this.state.successResponse.patient.fname,
            lname: this.state.successResponse.patient.lname,
            code: this.state.verificationCode
        }).then((success: any) => {
            if (success.response.status === 200) {
                if (success.response.data.status.error === false) {
                    this.setState({ modalNumber: 3 });
                    this.setState({ verificationCodeFalse: false });
                    this.setState({ successResponse: success.response.data });
                } else {
                    this.setState({ verificationCodeFalse: true });
                }
            }
            this.setState({ popUpTwoLoader: false });
        }).catch((error) => {
            this.setState({ popUpTwoLoader: false });
        });
    }
    sendVerificationCode() {
        this.setState({ popUpTwoLoader: true, verificationCodeFalse: false, verificationCodeValidation: { isInValid: false, msg: '' } });
        Axios.post(apiConfigs.API_URL + 'patient/register/' + this.state.successResponse.patient.id + '/', {
            email: this.props.emailAddress,
            phone: takeOnlyDigitAndPlus(this.state.phone),
            fname: this.state.fname,
            lname: this.state.lname,
            resend: true
        }
            , {
                headers: {
                    xprApp: 'front'
                },
                withCredentials: true
            }).then((success) => {
                this.setState({ popUpTwoLoader: false });
                if (success.status === 200) {
                    if (success.data.status.error === false) {
                        toast.success(success.data.status.msg);
                        /* const interval = setInterval(() => {
                            if (this.state.intervalTime === 0) {
                                clearInterval(interval);
                            } else {
                                this.setState({ intervalTime: this.state.intervalTime - 1 });
                            }
                        }, 1000) */
                    } else {
                        toast.error(success.data.status.msg);
                    }
                }
            }, (error) => {
                this.setState({ popUpTwoLoader: false });
                toast.error('Something went wrong');
            });
    }
    registerUserConfirm() {
        try {
            this.setState({ popUpThreeLoader: true, passwordValidation: { isInValid: false, msg: '' } });
            if (this.state.password === "" || this.state.password.trim() === "") {
                this.setState({ popUpThreeLoader: false });
                this.setState({ passwordValidation: { isInValid: true, msg: 'Password required' } });
                return;
            } else if (this.state.cpassword === "" || this.state.cpassword.trim() === "") {
                this.setState({ popUpThreeLoader: false });
                this.setState({ passwordValidation: { isInValid: true, msg: 'Confirm Password required' } });
                return;
            }
            console.log(this.state.password !== this.state.cpassword);
            if (this.state.password !== this.state.cpassword) {
                this.setState({ popUpThreeLoader: false });
                this.setState({ passwordValidation: { isInValid: true, msg: 'Password do not match' } });
                return;
            } else if (this.state.appointmentFor === "") {
                this.setState({ popUpThreeLoader: false });
                this.setState({ passwordValidation: { isInValid: true, msg: 'Please select appointment for.' } });
                return;
            } /* else if (this.state.appointmentFor === "2" && this.state.dependantName === "") {
                this.setState({ popUpThreeLoader: false });
                this.setState({ passwordValidation: { isInValid: true, msg: 'Please enter dependant name.' } });
                return;
            } else if (this.state.appointmentFor === "2" && this.state.dependantRelation === "") {
                this.setState({ popUpThreeLoader: false });
                this.setState({ passwordValidation: { isInValid: true, msg: 'Please enter dependance relation.' } });
                return;
            } */ else if (this.state.password === this.state.cpassword) {
                this.setState({ passwordValidation: { isInValid: false, msg: '' } });
                if (this.props.data) {
                    const clinic = {
                        id: this.props.data.id,
                        doctor: {
                            id: this.props.doctor ? this.props.doctor.id : ''
                        }
                    };
                    const appointmentType = {
                        id: this.props.potentialAppointment ? this.props.potentialAppointment.id : ''
                    }
                    if (this.props.isNewPatient === false) {
                        console.log(this.props.userRegistrationResponse);
                        const request = {
                            isNewPatient: this.props.isNewPatient,
                            isExistingClinic: this.props.isExistingClinic,
                            patient: {
                                email: this.props.emailAddress,
                                password: this.state.password
                            },
                            clinic: clinic,
                            appointmenttype: appointmentType,
                            request: {
                                isNewPatient: this.props.isNewPatient,
                                additionalNote: this.props.noteForOffice,
                                dependantName: this.state.dependantName,
                                dependantRelation: this.state.dependantRelation
                            },
                        }
                        const appointmentFor = this.state.dependants[this.state.appointmentFor];
                        if (appointmentFor.isNew) {
                            Object.assign(request, {
                                appointmentFor: "new",
                                dependant: {
                                    appointmentFor: "new",
                                    ...appointmentFor
                                }
                            });
                        } else {
                            Object.assign(request, {
                                appointmentFor: "existing",
                                dependant: {
                                    appointmentFor: "existing",
                                    id: appointmentFor.id,
                                    ...appointmentFor
                                }
                            });
                        }
                        console.log(request);
                        this.appointmentRequest(request);
                    } else if (this.state.successResponse.isNewPatient) {
                        Object.assign(this.state.successResponse.patient, { password: this.state.password });
                        const referrer = localStorage.getItem('referrer');
                        const request = {
                            isNewPatient: this.state.successResponse.isNewPatient,
                            isExistingClinic: this.props.isExistingClinic,
                            patient: this.state.successResponse.patient,
                            clinic: clinic,
                            appointmenttype: appointmentType,
                            request: {
                                isNewPatient: this.state.successResponse.isNewPatient,
                                additionalNote: this.props.noteForOffice,
                                dependantName: this.state.dependantName,
                                dependantRelation: this.state.dependantRelation
                            },
                        }
                        if (referrer && referrer !== "") {
                            Object.assign(request, { referral: referrer });
                        }
                        const appointmentFor = this.state.dependants[this.state.appointmentFor];
                        if (appointmentFor.isNew) {
                            Object.assign(request, {
                                appointmentFor: "new",
                                dependant: {
                                    appointmentFor: "new",
                                    ...appointmentFor
                                }
                            });
                        } else {
                            Object.assign(request, {
                                appointmentFor: "existing",
                                dependant: {
                                    appointmentFor: "existing",
                                    id: appointmentFor.id,
                                    ...appointmentFor
                                }
                            });
                        }
                        console.log(request);
                        Axios.post(apiConfigs.API_URL + 'patient/' + this.state.successResponse.patient.id + '/appointment/', request, {
                            headers: {
                                xprApp: 'front'
                            }
                        }).then((success: any) => {
                            if (success.status === 200) {
                                if (success.data.status.error === false) {
                                    this.setState({
                                        btnChange: { isShow: true, msg: success.data.status.msg },
                                    }, () => {
                                        setTimeout(() => {
                                            localStorage.removeItem('referrer');
                                            this.patientLogin(this.props.emailAddress, this.state.password, (token: any) => {
                                                signInWithCustomToken(token).then((signInSuccess) => {
                                                    console.log('Firebase:', signInSuccess);
                                                    this.addStatusToFirebase(success.data.appopintment, success.data.patient, this.props.data.uniqueId, success.data.dependantUniqueId);
                                                }).catch((error) => {
                                                    console.log(error);
                                                })
                                            });
                                        }, 300);
                                    });
                                } else {
                                    this.setState({ popUpThreeLoader: false });
                                    this.setState({ passwordValidation: { isInValid: true, msg: success.data.status.msg } });
                                }
                            }
                        }).catch((error) => {
                            this.setState({ popUpThreeLoader: false });
                            console.log(error);
                        });
                    } else if (this.state.successResponse.isNewPatient === false) {
                        const request = {
                            isNewPatient: this.state.successResponse.isNewPatient,
                            isExistingClinic: this.props.isExistingClinic,
                            patient: {
                                phone: takeOnlyDigitAndPlus(this.state.phone),
                                fname: this.state.fname,
                                lname: this.state.lname,
                                password: this.state.password
                            },
                            clinic: clinic,
                            appointmenttype: appointmentType,
                            request: {
                                isNewPatient: this.state.successResponse.isNewPatient,
                                additionalNote: this.props.noteForOffice,
                                dependantName: this.state.dependantName,
                                dependantRelation: this.state.dependantRelation
                            },
                        }
                        const appointmentFor = this.state.dependants[this.state.appointmentFor];
                        if (appointmentFor.isNew) {
                            Object.assign(request, {
                                appointmentFor: "new",
                                dependant: {
                                    appointmentFor: "new",
                                    ...appointmentFor
                                }
                            });
                        } else {
                            Object.assign(request, {
                                appointmentFor: "existing",
                                dependant: {
                                    appointmentFor: "existing",
                                    id: appointmentFor.id,
                                    ...appointmentFor
                                }
                            });
                        }
                        console.log(request);
                        this.appointmentRequest(request);
                    }
                }
            }
        } catch (err) {
            console.log(err);
        }
    }
    appointmentRequest(request: any) {
        Axios.post(apiConfigs.API_URL + 'patient/appointment/', request, {
            headers: {
                xprApp: 'front'
            }
        }).then((success: any) => {
            if (success.status === 200) {
                if (success.data.status.error === false) {
                    /*  setTimeout(() => {
                         this.setState({ popUpThreeLoader: false });
                         this.props.closePopUp(true);
                     }, 3000); */

                    this.setState({
                        btnChange: { isShow: true, msg: success.data.status.msg },
                    }, () => {
                        setTimeout(() => {
                            this.patientLogin(this.props.emailAddress, this.state.password, (token: any) => {
                                signInWithCustomToken(token).then((signInSuccess) => {
                                    console.log('Firebase:', signInSuccess);
                                    this.addStatusToFirebase(success.data.appopintment, success.data.patient, this.props.data.uniqueId, success.data.dependantUniqueId);
                                }).catch((error) => {
                                    console.log(error);
                                })
                            });
                        }, 300);
                    });
                } else {
                    this.setState({ popUpThreeLoader: false });
                    this.setState({ passwordValidation: { isInValid: true, msg: success.data.status.msg } });
                }
            }
        }).catch((error) => {
            this.setState({ popUpThreeLoader: false });
            console.log(error);
        });
    }
    addStatusToFirebase(appointmentId: any, patientId: any, clinicId: any, dependantId: any) {
        var db = firebase.firestore();
        const obj = {
            "appointmentId": Number(appointmentId),
            "senderId": Number(patientId),
            "sender": 'patient',
            "action": 'Changed status from CREATED to REQUESTED.',
            "actionType": 'STATUS',
            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
            "fromStatus": 'CREATED',
            "toStatus": 'REQUESTED',
            "sendToCId": clinicId.toString(),
            "dependantId": Number(dependantId)
        };
        db.collection(apiConfigs.CHAT_COLLECTION_NAME).doc().set(obj)
            .then(() => {
                console.log("Document successfully written!");
                this.setState({
                    popUpThreeLoader: false, btnChange: {
                        ...this.state.btnChange,
                        isShow: true
                    }
                });
                this.props.closePopUp(true);
            })
            .catch((error: any) => {
                console.error("Error writing document: ", error);
            });
    }
    patientLogin(email: string, password: string, callback: any) {
        Axios.post(apiConfigs.API_URL + "patientlogin/", { email: email, password: password }, {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true
        }).then((success: any) => {
            if (success.status === 200) {
                if (success.data.status.error === false) {
                    this.setCookie(success.data);
                    setTimeout(() => {
                        this.setState({
                            btnChange: { isShow: false, msg: success.data.status.msg },
                            password: '',
                            cpassword: '',
                        });
                    }, 3000);
                    callback(success.data.jwtToken);
                } else {
                    this.setState({ popUpThreeLoader: false });
                }
            } else {
                this.setState({ popUpThreeLoader: false });
            }
        }, (error: any) => {
            this.setState({ popUpThreeLoader: false });
        });
    }
    setCookie(response: any) {
        const key1 = 'xprUser.patient-' + response.id;
        const key2 = 'xpr_user_id';
        const key3 = 'xprAuthUser';
        const key4 = 'xprUserName';
        const key5 = 'xprEmail';
        const key6 = 'jwtToken';
        var json: any = {};
        json[key1] = true;
        json[key2] = response.uniqueId;
        json[key3] = response.authParam;
        json[key4] = response.firstName + " " + response.lastName;
        json[key5] = response.email;
        json[key6] = response.jwtToken;
        // Cookies.set("patient_cookie", json, { expires: 30 });
        // Cookies.set("patient_cookie", json, { domain: 'patient.staging.xcarepro.com', path: '/', expires: 30 });
        Cookies.set("patient_cookie", json, { domain: apiConfigs.COOKIE_DOMAIN, path: '/', expires: 30 });
    }
    handleChangeDate = (date: any) => {
        this.setState({ dob: date })
    }
    handleAddDependant = () => {

        if (this.state.firstName === '' || this.state.firstName.trim() === '') {
            toast.warn('Please enter valid FirstName.');
            return;
        }
        if (this.state.lastName === '' || this.state.lastName.trim() === '') {
            toast.warn('Please enter valid LastName.');
            return;
        }
        if (!emailValidation(this.state.emailId)) {
            toast.warn('Please enter valid email.');
            return
        }
        if (takeOnlyDigit(this.state.mobile).length !== 11) {
            toast.warn('Please enter valid mobile.');
            return
        }
        const body = {
            fname: this.state.firstName,
            firstName: this.state.firstName,
            lname: this.state.lastName,
            lastName: this.state.lastName,
            relation: this.state.relation,
            email: this.state.emailId,
            phone: takeOnlyDigitAndPlus(this.state.phone),
            gender: this.state.gender,
            dob: this.state.dob,
            contactMethod: this.state.contactMethod,
            reminder: this.state.reminder,
            isNew: true
        }
        this.setState({
            isaddDependant: false,
            dependants: [...this.state.dependants, body]
        });
        // Object.assign({},this.state.successResponse.dependant,body)
        // console.log('Check',this.state.successResponse)

    }
    render() {
        console.log(this.props.userRegistrationResponse);
        return (
            <>
                {
                    this.state.modalNumber === 1 &&
                    <div className="book-appointment-popup master_popup">

                        <div className="modal-header">
                            <button className="btn book-close-btn" onClick={(e) => { this.closePopUp(false); }}>
                                <img src={process.env.PUBLIC_URL + "/images/close-icon-white.svg"} className="img-fluid" />
                            </button>
                            <div className="steps clearfix">
                                <h6 className="step_tite">Patient information</h6>
                                <ul>
                                    <li className={"" + (this.state.modalNumber.toString() === "1" ? "active" : '')}><a><span className="number">Patient</span></a></li>
                                    <li className={"" + (this.state.modalNumber.toString() === "2" ? "active" : '')}><a><span className="number">Verify</span> </a></li>
                                    <li className={"" + (this.state.modalNumber.toString() === "3" ? "active" : '')}><a><span className="number">Confirm</span></a></li>
                                </ul>
                            </div>
                        </div>


                        <div className="modal-body book-popup-sec">

                            <div className="step-1">
                                <div className="book-popup-form">
                                    <div className="form-row">
                                        <div className="form-group col-6">
                                            <label>First Name *</label>
                                            <input type="text" value={this.state.fname}
                                                onChange={(e) => {
                                                    if (allowAlphabetsAndSpace(e.target.value)) {
                                                        this.setState({ fname: e.target.value });
                                                    }
                                                }}
                                                onInput={(e: any) => {
                                                    if (allowAlphabetsAndSpace(e.target.value)) {
                                                        this.setState({ fname: e.target.value });
                                                    }
                                                }}
                                                className="form-control" />
                                            {
                                                this.state.fnameValidation.isInValid === true &&
                                                <div className="text-danger error">{this.state.fnameValidation.msg}</div>
                                            }
                                        </div>

                                        <div className="form-group col-6">
                                            <label>Last Name *</label>
                                            <input type="text" value={this.state.lname}
                                                onChange={(e) => {
                                                    if (allowAlphabetsAndSpace(e.target.value)) {
                                                        this.setState({ lname: e.target.value });
                                                    }
                                                }}
                                                onInput={(e: any) => {
                                                    if (allowAlphabetsAndSpace(e.target.value)) {
                                                        this.setState({ lname: e.target.value });
                                                    }
                                                }} className="form-control" />
                                            {
                                                this.state.lnameValidation.isInValid === true &&
                                                <div className="text-danger error">{this.state.lnameValidation.msg}</div>
                                            }
                                        </div>

                                    </div>

                                    <div className="form-group">
                                        <label>Phone * </label>
                                        <InputMask mask="+1(999) 999-9999"
                                            value={this.state.phone}
                                            onChange={(e) => {
                                                this.setState({ phone: e.target.value });
                                            }}>
                                            {(inputProps: any) => <input {...inputProps} className="form-control"></input>}
                                        </InputMask>
                                        {
                                            this.state.phoneValidation.isInValid === true &&
                                            <div className="text-danger error">{this.state.phoneValidation.msg}</div>
                                        }
                                    </div>
                                    <div className="form-group pop_btnsec">
                                        <button className="btn btn-custom w-100"
                                            disabled={this.state.popUpOneLoader}
                                            onClick={(e) => { this.registerUser() }}>Continue</button>
                                        {
                                            this.state.popUpOneLoader &&
                                            <div className="text-center cs_popload"><i className="fas fa-circle-notch fa-spin"></i></div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                }
                {
                    this.state.modalNumber === 2 &&
                    <div className="book-appointment-popup master_popup">

                        <div className="modal-header">
                            <button className="btn book-close-btn" onClick={(e) => { this.closePopUp(false); }}>
                                <img src={process.env.PUBLIC_URL + "/images/close-icon-white.svg"} className="img-fluid" />
                            </button>
                            <div className="steps clearfix">
                                <h6 className="step_tite">Patient information</h6>
                                <ul>
                                    <li className={"" + (this.state.modalNumber.toString() === "1" ? "active" : '')}><a><span className="number">Patient</span></a></li>
                                    <li className={"" + (this.state.modalNumber.toString() === "2" ? "active" : '')}><a><span className="number">Verify</span> </a></li>
                                    <li className={"" + (this.state.modalNumber.toString() === "3" ? "active" : '')}><a><span className="number">Confirm</span></a></li>
                                </ul>
                            </div>
                        </div>


                        <div className="modal-body book-popup-sec">

                            <div className="step-2 phonevfy">
                                <div className="book-popup-form">
                                    <p className="step_info text-center">We have sent you an access code via SMS for <span>{takeOnlyDigitAndPlus(this.state.phone)}</span></p>
                                    <div className="form-group vinput">
                                        <label className="text-center">Enter code</label>
                                        <PinInput
                                            length={4}
                                            initialValue={""}
                                            onChange={(value, index) => {
                                                this.setState({ verificationCode: value });
                                            }}
                                            type="numeric"
                                            style={{ padding: '10px' }}
                                            inputStyle={{}}
                                            inputFocusStyle={{ borderColor: 'blue' }}
                                            onComplete={(value, index) => {
                                                this.setState({ verificationCode: value });
                                            }}
                                        />
                                        {
                                            this.state.verificationCodeFalse &&
                                            <div className="text-danger error">Verification code entered is incorrect.</div>
                                        }
                                        {
                                            this.state.verificationCodeValidation.isInValid === true &&
                                            <div className="text-danger error">{this.state.verificationCodeValidation.msg}</div>
                                        }
                                    </div>
                                    <button className="btn btn-custom w-100" onClick={(e) => { this.verifyCode() }} disabled={this.state.popUpTwoLoader}>Continue</button>
                                    {
                                        this.state.popUpTwoLoader &&
                                        <div className="text-center cs_popload"><i className="fas fa-circle-notch fa-spin"></i></div>
                                    }
                                    {
                                        this.state.intervalTime !== 0 ?
                                            <div className="resend-txt">Didn't received code? Resend after {this.state.intervalTime} seconds.</div>
                                            :
                                            <>
                                                {
                                                    (this.state.popUpTwoLoader === false) &&
                                                    <div className={"resend-txt"} onClick={(e) => { this.sendVerificationCode() }}>Resend Code</div>
                                                }
                                            </>
                                    }
                                </div>
                            </div>



                        </div>

                    </div>
                }
                {
                    this.state.modalNumber === 3 &&
                    <div className="book-appointment-popup master_popup">
                        <div className="modal-header">
                            <button className="btn book-close-btn" onClick={(e) => { this.closePopUp(false); }}>
                                <img src={process.env.PUBLIC_URL + "/images/close-icon-white.svg"} className="img-fluid" />
                            </button>
                            <div className="steps clearfix">
                                <h6 className="step_tite">Patient information</h6>
                                <ul>
                                    <li className={"" + (this.state.modalNumber.toString() === "1" ? "active" : '')}><a><span className="number">Patient</span></a></li>
                                    <li className={"" + (this.state.modalNumber.toString() === "2" ? "active" : '')}><a><span className="number">Verify</span> </a></li>
                                    <li className={"" + (this.state.modalNumber.toString() === "3" ? "active" : '')}><a><span className="number">Confirm</span></a></li>
                                </ul>
                            </div>
                        </div>


                        <div className="modal-body book-popup-sec">

                            <div className="step-3">
                                <div className="book-popup-form">
                                    {
                                        this.state.btnChange.isShow === true ?
                                            <label>{this.state.btnChange.msg}</label>
                                            :
                                            <>

                                                <div className="form-group">
                                                    <label>Password</label>
                                                    <input type="password" value={this.state.password} onChange={(e) => {
                                                        this.setState({ password: e.target.value });
                                                    }} onInput={(e: any) => {
                                                        this.setState({ password: e.target.value });
                                                    }} className="form-control" />
                                                </div>
                                                <div className="form-group">
                                                    <label>Confirm password</label>
                                                    <input type="password"
                                                        value={this.state.cpassword} onChange={(e) => {
                                                            this.setState({ cpassword: e.target.value });
                                                        }} onInput={(e: any) => {
                                                            this.setState({ cpassword: e.target.value });
                                                        }} className="form-control" />
                                                </div>
                                                {/* <div className="form-group">
                                                    <label>Appointment For</label>
                                                    <select className="form-control" value={this.state.appointmentFor} onChange={(e) => {
                                                        this.setState({ appointmentFor: e.target.value });
                                                    }}>
                                                        <option value="0">Select appointment for</option>
                                                        <option value="1">Me</option>
                                                        <option value="2">Other</option>
                                                    </select>
                                                </div> */}
                                                {/* {
                                                    this.state.appointmentFor === "2" &&
                                                    <div>
                                                        <div className="form-group">
                                                            <label>Dependent Name</label>
                                                            <input type="text" className="form-control" value={this.state.dependantName} onChange={(e) => {
                                                                if (allowAlphabetsAndSpace(e.target.value)) {
                                                                    this.setState({ dependantName: e.target.value });
                                                                }
                                                            }} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Dependent Relation</label>
                                                            <input type="text" className="form-control" value={this.state.dependantRelation} onChange={(e) => {
                                                                if (allowAlphabetsAndSpace(e.target.value)) {
                                                                    this.setState({ dependantRelation: e.target.value });
                                                                }
                                                            }} />
                                                        </div>
                                                    </div>
                                                } */}
                                                {
                                                    this.state.passwordValidation.isInValid === true &&
                                                    <div className="text-danger error">{this.state.passwordValidation.msg}</div>
                                                }
                                                <div className="form-group">
                                                    <label>Appointment For</label>
                                                    {console.log('Dats', this.state.successResponse)}

                                                    {this.props.userRegistrationResponse.isGuarantor &&
                                                        <button className='btn btn-primary right' onClick={() => { this.setState({ isaddDependant: !this.state.isaddDependant }) }}>Add Dependant</button>
                                                    }
                                                    {!this.state.isaddDependant && <select className="form-control" value={this.state.appointmentFor} onChange={(e) => {
                                                        this.setState({ appointmentFor: e.target.value });
                                                    }}>
                                                        {this.state.dependants && this.state.dependants.length > 0 && this.state.dependants.map((item: any, index: number) => {
                                                            return <option key={item.id} value={index}>{item.firstName + " " + item.lastName}</option>
                                                        })}
                                                    </select>}
                                                </div>
                                                {
                                                    this.state.isaddDependant &&
                                                    <div>
                                                        <div className="form-group">
                                                            <input placeholder="First Name" type="text" className="form-control" value={this.state.firstName} onChange={(e) => {
                                                                if (allowAlphabetsAndSpace(e.target.value)) {
                                                                    this.setState({ firstName: e.target.value });
                                                                }
                                                            }} />
                                                        </div>
                                                        <div className="form-group">
                                                            <input placeholder="Last Name" type="text" className="form-control" value={this.state.lastName} onChange={(e) => {
                                                                if (allowAlphabetsAndSpace(e.target.value)) {
                                                                    this.setState({ lastName: e.target.value });
                                                                }
                                                            }} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Dependent Relation</label>
                                                            <select className="form-control" value={this.state.relation} onChange={(e) => {
                                                                this.setState({ relation: e.target.value });
                                                            }}>
                                                                <option value="Son">Son</option>
                                                                <option value="Daughter">Daughter</option>
                                                                <option value="Mother">Mother</option>
                                                                <option value="Father">Father</option>
                                                                <option value="Spouse">Spouse</option>
                                                            </select>
                                                        </div>
                                                        <div className="form-group">
                                                            <input placeholder="Email" type="text" className="form-control" value={this.state.emailId} onChange={(e) => {
                                                                this.setState({ emailId: e.target.value });
                                                            }} />
                                                        </div>
                                                        <div className="form-group">
                                                            {/* <input placeholder="Contact No" type="text" className="form-control" value={this.state.phone} onChange={(e) => {
                                                                            if (allowOnlyDigit(e.target.value) && e.target.value.length <= 10) {
                                                                                this.setState({ phone: e.target.value });
                                                                            }else if(e.target.value === ""){
                                                                                this.setState({phone:""})
                                                                            }
                                                                        }} /> */}
                                                            <label>Phone</label>
                                                            <InputMask mask="+1(999) 999-9999"
                                                                value={this.state.mobile}
                                                                onChange={(e) => {
                                                                    this.setState({ mobile: e.target.value });
                                                                }}>
                                                                {(inputProps: any) => <input {...inputProps} className="form-control"></input>}
                                                            </InputMask>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Gender</label>
                                                            <select className="form-control" value={this.state.gender} onChange={(e) => {
                                                                this.setState({ gender: e.target.value });
                                                            }}>
                                                                <option value="Male">Male</option>
                                                                <option value="Female">Female</option>
                                                            </select>
                                                        </div>
                                                        {console.log('Date', this.state.dob)}
                                                        <div className="form-group">
                                                            <KeyboardDatePicker
                                                                margin="normal"
                                                                id={"date-picker-dialog"}
                                                                name={"date-picker-dialog"}
                                                                label="Date of Birth"
                                                                format="YYYY/MM/DD"
                                                                maxDate={moment().subtract(1, 'days')}
                                                                value={moment(this.state.dob)}
                                                                onChange={(date) => { this.handleChangeDate(date) }}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Contact Method</label>
                                                            {/* <select className="form-control" value={this.state.contactMethod} onChange={(e) => {
                                                                this.setState({ contactMethod: e.target.value });
                                                            }}>
                                                                <option value="Call">Call</option>
                                                                <option value="Text">Text</option>
                                                                <option value="Text & Email">Text & Email</option>
                                                            </select> */}
                                                            <div className="form-group">
                                                                <div className="custom-control custom-radio mr-3">
                                                                    <input type="radio" id="call" name="customRadio" className="custom-control-input" onClick={() => { this.setState({ contactMethod: CONTACTMETHODS.EMAIL, reminder: "" }) }} checked={this.state.contactMethod === CONTACTMETHODS.EMAIL ? true : false} />
                                                                    <label className="custom-control-label" htmlFor="call">Email Only</label>
                                                                </div>

                                                                <div className="custom-control custom-radio">
                                                                    <input type="radio" id="text" name="customRadio" className="custom-control-input" onClick={() => { this.setState({ contactMethod: CONTACTMETHODS.TEXT, reminder: '' }) }} checked={this.state.contactMethod === CONTACTMETHODS.TEXT ? true : false} />
                                                                    <label className="custom-control-label" htmlFor="text">Text Only</label>
                                                                </div>
                                                                <div className="custom-control custom-radio">
                                                                    <input type="radio" id="no" name="customRadio" className="custom-control-input" onClick={() => { this.setState({ contactMethod: CONTACTMETHODS.EMAIL_TEXT, reminder: "" }) }} checked={this.state.contactMethod === CONTACTMETHODS.EMAIL_TEXT ? true : false} />
                                                                    <label className="custom-control-label" htmlFor="no">Email & Text</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className="form-group">
                                                            <label>Reminder</label>
                                                            <select className="form-control" value={this.state.reminder} onChange={(e) => {
                                                                this.setState({ reminder: e.target.value });
                                                            }}>
                                                                <option value="yes">Yes</option>
                                                                <option value="no">No</option>
                                                            </select>
                                                        </div> */}
                                                        <div className="form-group">
                                                            <button className="btn btn-primary" onClick={() => { this.handleAddDependant() }}>Submit</button>
                                                        </div>

                                                    </div>

                                                }
                                                {
                                                    this.state.isaddDependant === false &&
                                                    <button className="btn btn-custom w-100" onClick={(e) => { this.registerUserConfirm() }}
                                                        disabled={this.state.popUpThreeLoader}>Confirm</button>
                                                }
                                                {
                                                    this.state.popUpThreeLoader &&
                                                    <div className="text-center cs_popload"><i className="fas fa-circle-notch fa-spin"></i></div>
                                                }


                                            </>
                                    }
                                </div>
                            </div>



                        </div>

                    </div>
                }
            </>
        );
    }
}
export default PopUpFour;