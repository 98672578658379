import React, { SyntheticEvent } from 'react';
import { Dropdown, Modal, Button, Form, Spinner } from 'react-bootstrap';
import { emailValidation, allowOnlyDigit } from '../../utils/validators';
import { doLogin, doForgotPassword } from '../../services/results';
import { setCookie, removeCookie } from '../../utils/global';
import apiConfigs from '../../configs/apiConfigs';
import { signInWithCustomToken } from '../../utils/firebaseUtils';
import { patientLogin, patientForgotPassword, patientCheckLogin } from '../../services/auth';
import { toast } from 'react-toastify';
import { useDispatch, connect } from 'react-redux'
import { addPrimaryInsurance, addSecondaryInsurance } from '../../store/landingPageSearch/actions';

interface Props {
    showModal: boolean;
    hideModal(hide: boolean): void;
    userNotVerified(id: any, phone: any): void;
    addPrimaryInsurance: typeof addPrimaryInsurance;
    addSecondaryInsurance: typeof addSecondaryInsurance;
}
interface State {
    loginForm: {
        isLoading: boolean;
        email: string;
        password: string;
        emailOrPhone: string;
        phone: string;
        emailValidation: { emailInvalid: boolean, msg: string };
        passwordValidation: { passwordInvalid: boolean, msg: string };
        emailOrPhoneValidation: { emailOrPhoneInvalid: boolean, msg: string };
        phoneValidation: { phoneInvalid: boolean, msg: string };
    },
    forgotPasswordForm: {
        isLoading: boolean;
        email: string;
        emailValidation: { emailInvalid: boolean, msg: string };
    },
    error: {
        isError: boolean, msg: string
    },
    activeCard: number;
    isShowEmail: boolean;
    isShowPhone: boolean;
    isShowPassword: boolean;
    isShowEmailOrPhone: boolean;
    msg: any;
}
class LoginModal extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            loginForm: {
                isLoading: false,
                email: '',
                password: '', emailOrPhone: '', phone: '',
                emailValidation: { emailInvalid: false, msg: '' },
                passwordValidation: { passwordInvalid: false, msg: '' },
                emailOrPhoneValidation: { emailOrPhoneInvalid: false, msg: '' },
                phoneValidation: { phoneInvalid: false, msg: '' }
            },
            forgotPasswordForm: {
                isLoading: false,
                email: '',
                emailValidation: { emailInvalid: false, msg: '' },
            },
            error: {
                isError: false, msg: ''
            },
            activeCard: 0,
            isShowEmail: false, isShowPhone: false, isShowPassword: false, isShowEmailOrPhone: true,
            msg: ''
        }

    }
    hide() {
        this.setState({
            loginForm: {
                isLoading: false,
                email: '',
                password: '',
                emailOrPhone: '',
                phone: '',
                emailValidation: { emailInvalid: false, msg: '' },
                passwordValidation: { passwordInvalid: false, msg: '' },
                emailOrPhoneValidation: { emailOrPhoneInvalid: false, msg: '' },
                phoneValidation: { phoneInvalid: false, msg: '' }
            },
            forgotPasswordForm: {
                isLoading: false,
                email: '',
                emailValidation: { emailInvalid: false, msg: '' },
            },
            error: {
                isError: false, msg: ''
            },
            activeCard: 0, isShowEmail: false, isShowPhone: false, isShowPassword: false, isShowEmailOrPhone: true
        });
        this.props.hideModal(false);
    }
    loginPhoneChangeHandler(e: any) {
        if (allowOnlyDigit(e.target.value)) {
            this.setState({
                loginForm: {
                    ...this.state.loginForm,
                    phone: e.target.value,
                    phoneValidation: { phoneInvalid: false, msg: '' }
                }
            });
        } else if (e.target.value === "") {
            this.setState({
                loginForm: {
                    ...this.state.loginForm,
                    phone: "",
                    phoneValidation: { phoneInvalid: false, msg: '' }
                }
            });
        }
    }
    loginEmailOrPhoneChangeHandler(e: any) {
        this.setState({
            loginForm: {
                ...this.state.loginForm,
                emailOrPhone: e.target.value,
                emailOrPhoneValidation: { emailOrPhoneInvalid: false, msg: '' }
            }
        });
    }
    loginEmailChangeHandler(e: any) {
        this.setState({
            loginForm: {
                ...this.state.loginForm,
                email: e.target.value,
                emailValidation: { emailInvalid: false, msg: '' }
            }
        });
    }
    forgotPasswordEmailChangeHandler(e: any) {
        this.setState({
            forgotPasswordForm: {
                ...this.state.forgotPasswordForm,
                email: e.target.value,
                emailValidation: { emailInvalid: false, msg: '' }
            }
        });
    }
    loginPasswordChange(e: any) {
        this.setState({
            loginForm: {
                ...this.state.loginForm,
                password: e.target.value,
                passwordValidation: { passwordInvalid: false, msg: '' }
            }
        });
    }
    onLoginCheckClick = () => {
        var mailFormat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;
        //Email
        if (this.state.loginForm.emailOrPhone === "") {
            this.setState({
                loginForm: {
                    ...this.state.loginForm,
                    emailOrPhoneValidation: { emailOrPhoneInvalid: true, msg: 'Please enter email address' }
                }
            });
            return;
        } else if (!mailFormat.test(this.state.loginForm.emailOrPhone)) {
            this.setState({

                loginForm: {
                    ...this.state.loginForm,
                    emailOrPhoneValidation: { emailOrPhoneInvalid: true, msg: 'Please enter valid email address or Mobile number' }
                }
            });
            return;
        }
        this.setState({

            loginForm: {
                ...this.state.loginForm,
                isLoading: true,
            }
        });
        let request = {
            login: this.state.loginForm.emailOrPhone
        }
        patientCheckLogin(request).then((success: any) => {
            try {
                if (success && success.response && success.response.data && success.response.status === 200) {
                    if (success.response.data && success.response.data.status.error === false) {
                        this.setState({
                            loginForm: {
                                ...this.state.loginForm,
                                isLoading: false,
                            },
                        });
                        if (success.response.data.askFor === "password") {
                            this.setState({ isShowPassword: true, isShowPhone: false, isShowEmail: false, isShowEmailOrPhone: false });
                        } else if (success.response.data.askFor === "email") {
                            this.setState({
                                isShowEmail: true, isShowPassword: true, isShowPhone: false, isShowEmailOrPhone: false,
                                msg: 'We have detected multiple accounts with this mobile number, please enter email associated with this account to sign in. In case if you do not have email associated with this account please enter password directly to sign in.'

                            });
                        } else if (success.response.data.askFor === "phone") {
                            this.setState({
                                isShowPhone: true, isShowPassword: true, isShowEmail: false, isShowEmailOrPhone: false,
                                msg: 'We have detected multiple accounts with this email, please enter mobile number associated with this account to sign in. In case if you do not have mobile number associated with this account please enter password directly to sign in.'

                            });
                        }

                    } else {
                        if (success.response.data && success.response.data.status.error === true) {
                            toast.error(success.response.data.status.msg);
                        }
                        this.setState({
                            loginForm: {
                                ...this.state.loginForm,
                                isLoading: false,
                            },
                        });
                    }
                }
            } catch (error) {
                console.log('error', error);
                this.setState({
                    loginForm: {
                        ...this.state.loginForm,
                        isLoading: false,
                    },
                });
            }
        });
    }
    onLoginClick() {
        let emailValid = false;
        let passwordValid = false;
        let onlyDigit = /^\d+$/;
        /* Email validation */
        // if(this.state.isShowEmail){
        //     if (this.state.loginForm.email === "") {
        //         this.setState({
        //             loginForm: {
        //                 ...this.state.loginForm,
        //                 emailValidation: { emailInvalid: true, msg: 'Please enter email address' }
        //             }
        //         });
        //         return;
        //     } else if (!emailValidation(this.state.loginForm.email)) {
        //         this.setState({
        //             loginForm: {
        //                 ...this.state.loginForm,
        //                 emailValidation: { emailInvalid: true, msg: 'Please enter valid email address' }
        //             }
        //         });
        //         return;
        //     } else {
        //         emailValid = true;
        //         this.setState({
        //             loginForm: {
        //                 ...this.state.loginForm,
        //                 emailValidation: { emailInvalid: false, msg: 'false' }
        //             }
        //         });
        //     }
        // }
        // if(this.state.isShowPhone){
        //     if (this.state.loginForm.phone === "") {
        //     this.setState({
        //         loginForm: {
        //             ...this.state.loginForm,
        //             phoneValidation: { phoneInvalid: true, msg: 'Please enter Mobile number' }
        //         }
        //     });
        //     return;
        //     } else if (this.state.loginForm.phone.length !== 10) {
        //         this.setState({
        //             loginForm: {
        //                 ...this.state.loginForm,
        //                 phoneValidation: { phoneInvalid: true, msg: 'Please enter valid Mobile number' }
        //             }
        //         });
        //         return;
        //     } else {
        //         this.setState({
        //             loginForm: {
        //                 ...this.state.loginForm,
        //                 phoneValidation: { phoneInvalid: false, msg: 'false' }
        //             }
        //         });
        //     }
        // }
        /* Password Validation */
        if (this.state.loginForm.password === "") {
            this.setState({
                loginForm: {
                    ...this.state.loginForm,
                    passwordValidation: { passwordInvalid: true, msg: 'Please enter password' }
                }
            });
            return;
        } else {
            passwordValid = true;
            this.setState({
                loginForm: {
                    ...this.state.loginForm,
                    passwordValidation: { passwordInvalid: false, msg: 'false' }
                }
            });
        }

        /* All valid */
        if (passwordValid) {
            this.setState({
                loginForm: {
                    ...this.state.loginForm,
                    isLoading: true,
                }
            });
            let request: any = {}
            if (this.state.isShowEmail) {
                request = { phone: this.state.loginForm.emailOrPhone, password: this.state.loginForm.password, email: this.state.loginForm.email }
            } else if (this.state.isShowPhone) {
                request = { email: this.state.loginForm.emailOrPhone, password: this.state.loginForm.password, phone: this.state.loginForm.phone }
            } else {
                if (onlyDigit.test(this.state.loginForm.emailOrPhone)) {
                    request = { phone: this.state.loginForm.emailOrPhone, password: this.state.loginForm.password }
                } else {
                    request = { email: this.state.loginForm.emailOrPhone, password: this.state.loginForm.password }
                }
            }
            // doLogin({ email: this.state.loginForm.email, password: this.state.loginForm.password })
            patientLogin(request)
                .then((success: any) => {
                    try {
                        if (success && success.response && success.response.data && success.response.status === 200) {
                            if (success.response.data && success.response.data.status.error === false) {
                                if ((success.response.data.phone === null && success.response.data.phoneVerified === null) || (success.response.data.phone !== null && success.response.data.phoneVerified !== null)) {
                                    signInWithCustomToken(success.response.data.jwtToken).then(async (signInSuccess) => {
                                        removeCookie('clinic_cookie');
                                        removeCookie('patient_cookie');
                                        setCookie(success.response.data, 'patient');
                                        this.setState({
                                            loginForm: {
                                                ...this.state.loginForm,
                                                isLoading: false,
                                            },
                                            isShowEmail: false,
                                            isShowPhone: false,
                                            isShowEmailOrPhone: true,
                                            isShowPassword: false
                                        });
                                        this.props.hideModal(false);
                                        if (success.response.data.insurance && success.response.data.iPlan) {
                                            this.props.addPrimaryInsurance({
                                                insurancePlan: success.response.data.insurance.name,
                                                insurencePlanName: success.response.data.iPlan.name
                                            });
                                            this.props.addSecondaryInsurance({
                                                insurancePlan: success.response.data.insurance.name,
                                                nsurencePlanName: success.response.data.iPlan.name
                                            });
                                        }
                                        console.log('LoginSuccess', success);
                                        // handleLoginSuccess(success.response.data.uniqueId);
                                    })
                                        .catch((error) => {
                                            console.log('error', error);
                                            this.setState({
                                                loginForm: {
                                                    ...this.state.loginForm,
                                                    isLoading: false,
                                                },
                                            });
                                            return;
                                        });

                                } else if (success.response.data.phone !== null && success.response.data.phoneVerified === null) {
                                    this.props.userNotVerified(success.response.data.id, success.response.data.phone);
                                    this.props.hideModal(false);

                                }


                            } else {
                                if (success.response.data && success.response.data.status.error === true) {
                                    toast.error(success.response.data.status.msg);
                                }
                                this.setState({
                                    loginForm: {
                                        ...this.state.loginForm,
                                        isLoading: false,
                                    },
                                });
                            }
                        }

                    } catch (error) {
                        console.log('error', error);
                        this.setState({
                            loginForm: {
                                ...this.state.loginForm,
                                isLoading: false,
                            },
                        });
                    }
                    // signInWithCustomToken(success.jwtToken).then((signInSuccess) => {
                    //     this.setState({
                    //         loginForm: {
                    //             ...this.state.loginForm,
                    //             isLoading: false,
                    //         }
                    //     });
                    //     console.log(success);
                    //     if (success.loginas) {
                    //         let cookieType = '';

                    //         if (success.loginas === "user") cookieType = 'clinic';
                    //         else cookieType = success.loginas;

                    //         if (cookieType === "clinic") {
                    //             removeCookie('clinic_cookie');
                    //             removeCookie('patient_cookie');
                    //             setCookie(success, cookieType);
                    //             window.location.href = apiConfigs.CLINIC_URL;
                    //         } else if (cookieType === "patient") {
                    //             removeCookie('clinic_cookie');
                    //             removeCookie('patient_cookie');
                    //             setCookie(success, cookieType);
                    //             window.location.href = apiConfigs.PATIENT_APPOINTMENT_URL;
                    //         }
                    //     }
                    // }).catch((error) => {
                    //     this.setState({
                    //         error: {
                    //             isError: true,
                    //             msg: 'Something went wrong'
                    //         },
                    //         loginForm: {
                    //             ...this.state.loginForm,
                    //             email: '', password: '',
                    //             isLoading: false,
                    //         }
                    //     });
                    // });
                }, (error) => {
                    if (error.status && error.status.error && error.status.msg) {
                        this.setState({
                            error: {
                                isError: true,
                                msg: error.status.msg,
                            },
                            loginForm: {
                                ...this.state.loginForm,
                                isLoading: false,
                            }
                        });
                    } else {
                        this.setState({
                            error: {
                                isError: true,
                                msg: 'Something went wrong'
                            },
                            loginForm: {
                                ...this.state.loginForm,
                                email: '', password: '',
                                isLoading: false,
                            }
                        })
                    }
                });
        }
    }
    onForgotPasswordClick() {
        let emailValid = false;
        /* Email validation */
        if (this.state.forgotPasswordForm.email === "") {
            this.setState({
                forgotPasswordForm: {
                    ...this.state.forgotPasswordForm,
                    emailValidation: { emailInvalid: true, msg: 'Please enter email address' }
                }
            });
        } else if (!emailValidation(this.state.forgotPasswordForm.email)) {
            this.setState({
                forgotPasswordForm: {
                    ...this.state.forgotPasswordForm,
                    emailValidation: { emailInvalid: true, msg: 'Please enter valid email address' }
                }
            });
        } else {
            emailValid = true;
            this.setState({
                forgotPasswordForm: {
                    ...this.state.forgotPasswordForm,
                    emailValidation: { emailInvalid: false, msg: 'false' }
                }
            });
        }

        /* All valid */
        if (emailValid) {
            this.setState({
                forgotPasswordForm: {
                    ...this.state.forgotPasswordForm,
                    isLoading: true,
                }
            });
            doForgotPassword({ email: this.state.forgotPasswordForm.email })
                .then((success) => {
                    this.setState({
                        error: {
                            isError: false,
                            msg: success.status.msg
                        },
                        forgotPasswordForm: {
                            ...this.state.forgotPasswordForm,
                            email: '',
                            isLoading: false,
                        }
                    })
                }, (error) => {
                    if (error.status && error.status.error && error.status.msg) {
                        this.setState({
                            error: {
                                isError: true,
                                msg: error.status.msg
                            },
                            forgotPasswordForm: {
                                ...this.state.forgotPasswordForm,
                                isLoading: false,
                            }
                        });
                    } else {
                        this.setState({
                            error: {
                                isError: true,
                                msg: 'Something went wrong'
                            },
                            forgotPasswordForm: {
                                ...this.state.forgotPasswordForm,
                                isLoading: false,
                            }
                        })
                    }
                });
        }
    }
    handleSubmit(e: SyntheticEvent) {
        e.preventDefault();
    }
    render() {
        return (
            <Modal className="login_popup" show={this.props.showModal} onHide={this.hide.bind(this)} backdrop={true} keyboard={true} centered>

                <Modal.Header closeButton>
                    {
                        this.state.activeCard === 0 &&
                        <Modal.Title>Sign in</Modal.Title>
                    }
                    {
                        this.state.activeCard === 1 &&
                        <Modal.Title>Forgot Password</Modal.Title>
                    }
                </Modal.Header>

                <Modal.Body>

                    <Form onSubmit={this.handleSubmit.bind(this)}>

                        {
                            (this.state.error.isError === true && this.state.activeCard === 0) &&
                            <Form.Text className="text-danger mb-2">
                                {this.state.error.msg}
                            </Form.Text>
                        }
                        {
                            (this.state.error.isError === true && this.state.activeCard === 1) &&
                            <Form.Text className="text-danger mb-2">
                                {this.state.error.msg}
                            </Form.Text>
                        }
                        {
                            (this.state.error.isError === false && this.state.activeCard === 1) &&
                            <Form.Text className="text-danger mb-2">
                                {this.state.error.msg}
                            </Form.Text>
                        }
                        {
                            this.state.activeCard === 0 &&
                            <>
                                {
                                    (this.state.isShowEmail || this.state.isShowPhone) &&
                                    <p>{this.state.msg}</p>
                                }
                                {
                                    (this.state.isShowEmailOrPhone) &&
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Email or Mobile</Form.Label>
                                        <Form.Control type="emailorphone" placeholder="Enter email or mobile" value={this.state.loginForm.emailOrPhone}
                                            onChange={this.loginEmailOrPhoneChangeHandler.bind(this)} />
                                        {
                                            this.state.loginForm.emailOrPhoneValidation.emailOrPhoneInvalid === true &&
                                            <Form.Text className="text-danger">
                                                {this.state.loginForm.emailOrPhoneValidation.msg}
                                            </Form.Text>
                                        }
                                    </Form.Group>
                                }
                                {
                                    (this.state.isShowEmail) &&
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control type="email" placeholder="Enter email" value={this.state.loginForm.email}
                                            onChange={this.loginEmailChangeHandler.bind(this)} />
                                        {
                                            this.state.loginForm.emailValidation.emailInvalid === true &&
                                            <Form.Text className="text-danger">
                                                {this.state.loginForm.emailValidation.msg}
                                            </Form.Text>
                                        }
                                    </Form.Group>
                                }
                                {
                                    (this.state.isShowPhone) &&
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Mobile</Form.Label>
                                        <Form.Control maxLength={10} type="mobile" placeholder="Enter mobile" value={this.state.loginForm.phone}
                                            onChange={this.loginPhoneChangeHandler.bind(this)} />
                                        {
                                            this.state.loginForm.phoneValidation.phoneInvalid === true &&
                                            <Form.Text className="text-danger">
                                                {this.state.loginForm.phoneValidation.msg}
                                            </Form.Text>
                                        }
                                    </Form.Group>
                                }
                                {
                                    (this.state.isShowPassword) &&
                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" placeholder="Password" value={this.state.loginForm.password} onChange={this.loginPasswordChange.bind(this)} />
                                        {
                                            this.state.loginForm.passwordValidation.passwordInvalid === true &&
                                            <Form.Text className="text-danger">
                                                {this.state.loginForm.passwordValidation.msg}
                                            </Form.Text>
                                        }
                                    </Form.Group>
                                }
                                <div className="d-flex justify-content-between align-items-center">
                                    {
                                        (this.state.isShowPassword) &&
                                        <button className="btn btn-custom d-flex" disabled={this.state.loginForm.isLoading}
                                            onClick={this.onLoginClick.bind(this)}>
                                            {
                                                this.state.loginForm.isLoading === true &&
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            }
                                            Sign in
                                        </button>
                                    }
                                    {
                                        (!this.state.isShowPassword) &&
                                        <button className="btn btn-custom d-flex" disabled={this.state.loginForm.isLoading}
                                            onClick={this.onLoginCheckClick.bind(this)}>
                                            {
                                                this.state.loginForm.isLoading === true &&
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            }
                                            Sign in
                                        </button>

                                    }

                                    <a className="fgpass" href="javascript:void(0)" onClick={(e) => {
                                        // this.setState({
                                        //     // activeCard: 1,
                                        //     loginForm: {
                                        //         email: '',
                                        //         emailOrPhone:'',
                                        //         phone:'',
                                        //         emailValidation: { emailInvalid: false, msg: '' },
                                        //         passwordValidation: { passwordInvalid: false, msg: '' },
                                        //         emailOrPhoneValidation: { emailOrPhoneInvalid: false, msg: '' },
                                        //         phoneValidation:{phoneInvalid:false,msg:''},
                                        //         isLoading: false, password: '',
                                        //     },
                                        //     error: {
                                        //         isError: false,
                                        //         msg: ''
                                        //     }
                                        // });
                                        // window.open('http://patient.dev.xcarepro.com/forgot-password?redirect_url=https://dev.xcarepro.com')
                                        window.open(apiConfigs.PATIENT_URL + 'forgot-password?redirect_url=https://' + apiConfigs.COOKIE_DOMAIN)
                                    }}>Forgot password</a>
                                </div>
                            </>
                        }
                        {
                            this.state.activeCard === 1 &&
                            <>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email" value={this.state.forgotPasswordForm.email}
                                        onChange={this.forgotPasswordEmailChangeHandler.bind(this)} />
                                    {
                                        this.state.forgotPasswordForm.emailValidation.emailInvalid === true &&
                                        <Form.Text className="text-danger">
                                            {this.state.forgotPasswordForm.emailValidation.msg}
                                        </Form.Text>
                                    }
                                </Form.Group>
                                <div className="d-flex justify-content-between align-items-center">
                                    <button className="btn btn-custom d-flex" disabled={this.state.forgotPasswordForm.isLoading}
                                        onClick={this.onForgotPasswordClick.bind(this)}>
                                        {
                                            this.state.forgotPasswordForm.isLoading === true &&
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        }
                                        Send Link
                                    </button>
                                    <a className="fgpass" href="javascript:void(0)" onClick={(e) => {
                                        this.setState({
                                            activeCard: 0,
                                            forgotPasswordForm: {
                                                email: '',
                                                emailValidation: { emailInvalid: false, msg: '' },
                                                isLoading: false,
                                            },
                                            error: {
                                                isError: false,
                                                msg: ''
                                            }
                                        });
                                    }}>Back to Login</a>
                                </div>
                            </>
                        }
                    </Form>

                </Modal.Body>


            </Modal>
        );
    }
}
const mapDispatchToProps = (dispatch: any) => ({
    addPrimaryInsurance: (insurance: any) => {
        dispatch(addPrimaryInsurance(insurance));
    },
    addSecondaryInsurance: (insurance: any) => {
        dispatch(addSecondaryInsurance(insurance));
    },
});
export default connect(null, mapDispatchToProps)(LoginModal);