import React,{useState,useEffect} from 'react';
import HeaderWhite from '../HeaderWhite/HeaderWhite';
import Filter from '../Filter/Filter';
import Tab from '../ClinicAndProviderList/Tab';
import ProviderList from '../ClinicAndProviderList/ProviderList';
import ClinicList from '../ClinicAndProviderList/ClinicList';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import { clinicAndProviderList } from '../../../../services/landingPageSearch';
import Map from '../../components/Map/Map';
import * as _ from 'lodash';
import { useHistory } from 'react-router-dom';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
function List() {
    const [selectedTab,setSelectedTab]=useState('provider');
    // const [isLoading,setIsLoading]=useState(false);
    const [clinicList,setClinicList]=useState([] as any);
    const [providerList,setProviderList]=useState([] as any);
    const [selectedClinic,setSelectedClinic]=useState('');
    const [selectedLatitude,setSelectedLatitude]=useState('');
    const [selectedLongitude, setSelectedLongitude] = useState('');
    const [iFram, setiFram] = useState(false);
    const history: any = useHistory();
    const [week, setWeek] = useState([]);
    const isLoading = useSelector((state: IAppState) => state.loading.pending);
    useEffect(() => {
        if (new URLSearchParams(history.location.search).get('iframe') === "yes") {
            setiFram(true);
        }
        // from clinic or provider profile
        if (history && history.location && history.location.state && history.location.state.iFrame) {
            setiFram(true);
        }
    },[]);

    const handleSelectedTab=(tabName:any)=>{
        setSelectedTab(tabName);
    }

    const clinicBookAppointment=()=>{
        setSelectedTab('provider')
    }
    return (
        <>
            {
                !iFram &&
                 <HeaderWhite></HeaderWhite>
            }
            <section className={iFram ? "inPatientPortal provider-clinic-list d-flex" : "provider-clinic-list d-flex"}>
                {
                    isLoading &&
                    // <LoadingIndicator></LoadingIndicator>
                    <></>
                }
                <div className="listing-left-sec">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 p-0">
                                <Tab
                                changeTab={selectedTab}
                                selectedTab={handleSelectedTab}
                                ></Tab>
                                <div className="tab-content" id="myTabContent">
                                    {
                                        <Filter
                                        // clinicList={}
                                        // providerList={}
                                        // filterApply={handleFilterApply}
                                        selectedClinic={selectedClinic}
                                            week={week}
                                            selectedTab={selectedTab}
                                     />
                                    }
                                    {
                                        selectedTab === 'provider' &&
                                        <ProviderList
                                            selectedWeek={(selectedWeek: any) => {
                                                if (selectedWeek[0] !== week[0]) {
                                                    setWeek(selectedWeek)
                                                }
                                            }}
                                        />
                                    }
                                    {
                                        selectedTab === 'clinic' &&
                                            <ClinicList
                                                bookAppointmentClick={clinicBookAppointment}
                                             />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Map
                    selectedTab={selectedTab}
                />
            </section>
        </>
    )
}
export default List
