import React from 'react';
import { IAppState } from '../../store';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { addInfo, setPercentage } from '../../store/formwizard/actions';
import { FormWizardState } from '../../store/formwizard/types';
import { allowDigit, emailValidation } from '../../utils/validators';
import { toast } from 'react-toastify';
import { takeOnlyDigit, maskUSPhone } from '../../utils/global';
interface Props {
    formwizardState: FormWizardState;
    addInfo: typeof addInfo;
    history: any;
    setPercentage: typeof setPercentage;
}
interface State {
    email: string;
    phone: string;
}
class Email extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            email: '', phone: ''
        }
    }
    componentDidMount() {
        this.props.setPercentage(98);
        try {
            const value: any = localStorage.getItem('front_key_store');
            if (value === undefined || value === null || value === "") {
                this.props.history.push('/');
            } else {
                const valueObj = JSON.parse(value);
                if (valueObj.formwizard.formwizard.location === "") {
                    this.props.history.push('/');
                } else {
                    this.setState({ email: valueObj.formwizard.formwizard.email, phone: maskUSPhone(takeOnlyDigit(valueObj.formwizard.formwizard.phone)) });
                }
            }
        } catch (err) {
            console.log(err);
        }
    }
    shouldComponentUpdate(nextProps: Props, nextState: any) {
        return true;
    }
    goNext(next: number) {
        console.log(this.state.phone);
        if (next === 0) {
            this.props.history.push('/quiz/process');
            // console.log(preapreSubmitForm(this.props.formwizardState.formwizard));
        } else if (next === 1) {
            if (this.state.email === "" || this.state.phone === "") {
                toast.warn('Field required');
                return;
            } else if ((this.state.phone).length !== 12) {
                toast.warn('Invalid phone');
                return;
            } else if (!emailValidation(this.state.email)) {
                toast.warn('Invalid email');
                return;
            }
            this.props.addInfo({ payload: { email: this.state.email, phone: this.state.phone } });
            this.props.history.push('/quiz/process');
            // console.log(preapreSubmitForm(this.props.formwizardState.formwizard));
        }
    }
    render() {
        return (
            <div className="col-12 col-md-10 wizard">
                <div className="row justify-content-center gutter-5">
                    <div className="col-12">
                        <div className="col-12">
                            
                            <div className="txt-box text-center">
                                <h4 className="title">What is your phone number?</h4>
                                <div className="sub-title">
                                    Enable secure booking by providing your phone number. We respect your privacy and time.
                    </div>
                            </div>
                        </div> 
                    </div>
                    <div className="col-lg-7 col-12">
                   

                    <InputMask mask="+1(999) 999-9999" placeholder="Enter your phone number" value={this.state.phone}
                         onChange={(e) => {
                            

                            if (allowDigit(takeOnlyDigit(e.target.value))) {
                                this.setState({
                                    phone: "+"+takeOnlyDigit(e.target.value)
                                },()=>{
                                    console.log(this.state.phone)
                                });
                            }
                        }}
                        onInput={(e: any) => {
                               
                            if (allowDigit(takeOnlyDigit(e.target.value))) {
                                this.setState({
                                    phone: "+"+takeOnlyDigit(e.target.value)
                                });
                            }
                        }}
                    >

                    </InputMask>
                        {/* <input type="text" placeholder="Enter your phone number" value={this.state.phone}
                            onChange={(e) => {
                                if (allowDigit(takeOnlyDigit(e.target.value))) {
                                    this.setState({
                                        phone: maskUSPhone(takeOnlyDigit(e.target.value))
                                    });
                                }
                            }}
                            onInput={(e: any) => {
                                if (allowDigit(takeOnlyDigit(e.target.value))) {
                                    this.setState({
                                        phone: maskUSPhone(takeOnlyDigit(e.target.value))
                                    });
                                }
                            }}
                        /> */}
                        
                    </div>
                    <div className="col-lg-7 col-12 mt-3">
                        <input type="text" placeholder="Enter your email address" value={this.state.email}
                            onChange={(e) => {
                                this.setState({ email: e.target.value });
                            }}
                            onInput={(e: any) => {
                                this.setState({ email: e.target.value });
                            }} />
                    </div>
                    <div className="col-lg-7 col-12 info-sec">
                        <div className="agree media">
                            <i className="fa fa-lock"></i>
                            <div className="media-body">
                                <p>
                                    By continuing, you agree to receive text messages from Xcarepro, and agree to our <Link className="" to="/" target="_blank">Terms</Link> and <Link className="" to="/" target="_blank">Privacy Policy</Link>. Msg frequency varies. Msg &amp; data rates may apply.
                                </p>
                            </div>
                        </div>
                        <div className="stop-help media">
                            <i className="fa fa-bell-slash"></i>
                            <div className="media-body">
                                <p>
                                    Text <strong>STOP</strong> to opt out or <strong>HELP</strong> for help.
                                </p>
                            </div>
                        </div>

                    </div>
                    <div className="col-12 text-center btn-sec">
                        <div className="col-12 text-center mt-5 btn-sec">
                            <button className="btn btn-toggle btn-skip" onClick={(e) => { this.goNext(0) }}>Skip</button>
                            <button className="btn btn-toggle activeBtn" onClick={(e) => { this.goNext(1) }}>Contitue</button>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
const mapStateToProps = (state: IAppState) => ({
    formwizardState: state.formwizard
});
const mapDispatchToProps = (dispatch: any) => ({
    addInfo: (payload: any) => {
        dispatch(addInfo(payload));
    },
    setPercentage: (percent: number) => {
        dispatch(setPercentage(percent));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(Email);