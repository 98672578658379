import React,{useState,useEffect} from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { getSpeciality } from '../../../../services/landingPageSearch';
import { toast } from 'react-toastify';
import { connect,useSelector,useDispatch, shallowEqual } from 'react-redux';
import { IAppState } from '../../../../store';
import MoreFilterModal from '../../components/Filter/MoreFilterModal';
import { addSpeciality, addDate } from '../../../../store/filters/actions';
import moment from 'moment';
import { clinicAndProviderList,existingClinicProvider } from '../../../../services/landingPageSearch';
import { addClinicProviderList } from '../../../../store/clinicAndProviderList/actions';
import { addFilterItems, addLanguage } from '../../../../store/filters/actions';
import { useHistory } from 'react-router-dom';
import * as _ from 'lodash'
import { patientblocksearch, appointmentSlot } from '../../../../services/landingPageSearch';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../../store/loadingIndicator/actions';
import { addSlotData } from '../../../../store/slot/actions';
import Location from '../../components/Filter/LocationBox';
import { searchFilter } from '../../../../store/v2/searchFilter/actions';
import { fetchClinicList} from '../../services/AppointmentBook';
function Filter(props: any) {
    const searchFilterData = useSelector((state:IAppState)=>state.searchFilter.searchFilterData,shallowEqual);
    const [selectedDate,setSelectedDate]=useState(searchFilterData.date);
    const [specialities,setSpecialities]=useState([]);
    const [selectedSpeciality,setselectedSpeciality]=useState(searchFilterData.newSpeciality);
    const [showMoreFilter,setShowMoreFilter]=useState(false);
    const [selectedClinic,setSelectedClinic]=useState('');
    const [selectedprovider,setSelectedProvider]=useState('');
    const [location, setLocation] = useState('' as any);
    const clinicProviderList: any = useSelector((state: IAppState | any) => state.clinicProviderList.clinicProviderList);
    const slot: any = useSelector((state: IAppState) => state.slot.slot);
    const history: any = useHistory();
    const dispatch = useDispatch();
    const [uniqueId, setUniqueId] = useState('');
    const [week, setWeek] = useState([]);
    const [doctor, setDoctor] = useState([]);
    const [isApiCall, setIsApiCall] = useState(true);
    const [apiClinicProviderRequest, setApiClinicProviderRequest]: any = useState();
    const [apiAppointmentSlotRequest, setAppointmentSlotRequest]: any = useState();
    const [totalCount, setTotalCount] = useState(0);
    const [isClearFilter, setIsClearFilter] = useState(false);
    const [apiClinicListRequest, setApiClinicListRequest]: any = useState();
    
    useEffect(() => {
        if (searchFilterData.newSpeciality) {
            setselectedSpeciality(searchFilterData.newSpeciality);
        }
    }, [searchFilterData.newSpeciality]);
    useEffect(() => {
        if (searchFilterData.date) {
            setSelectedDate(searchFilterData.date);
        }
    },[searchFilterData.date]);
    
    useEffect(() => {
        getSpecialityApi();
        let id: any = '';
        id = new URLSearchParams(history.location.search).get('q');
        if (id !== null) {
            setUniqueId(id);
            dispatch(searchFilter({
                ...searchFilterData,
                patientUniqueId: id
            }));
        } 
    }, []);
   
    useEffect(() => {
        if (moment(selectedDate).format('YYYY-MM-DD') !== searchFilterData.date && selectedDate) {
            dispatch(searchFilter({
                ...searchFilterData,
                date: moment(selectedDate).format('YYYY-MM-DD')
            }));
            if (props.selectedTab === "provider") {
                getClinicProviderList();
            } else {
                fetchClinicListApi();
            }
        }
    }, [selectedDate]);
    useEffect(() => {
        if (selectedSpeciality !== searchFilterData.newSpeciality && selectedSpeciality) {
            dispatch(searchFilter({
                ...searchFilterData,
                newSpeciality: selectedSpeciality
            }));
            if (props.selectedTab === "provider") {
                getClinicProviderList();
            } else {
                fetchClinicListApi();
            }
        }
    }, [selectedSpeciality]);

    const fetchClinicListApi = () => {
        const { oldSpeciality, newSpeciality, location, date, howToPay, gender,distance,languages,clinicId, patientUniqueId, clinicPage, results } = searchFilterData;
        let latitude: any = "", longitude: any = "";
        if (location !== "") {
            latitude=location.latitude;
            longitude=location.longitude;
        }
        let langIds: any = [];
        if (languages && languages.length > 0) {
            langIds = languages.map((item: any) => {
                return item.value
            });
        }
        let request: any={};
        if (isClearFilter) {
            request = {
                location: {
                    latitude: '',
                    longitude: '',
                    specialityId: searchFilterData.oldSpeciality,
                    searchdate: '',
                    radiuskm: '',
                    insuranceProviderId: howToPay.type === 'insurance' ? howToPay.value : '',
                    acceptAssignment: howToPay.type === 'cash' ? 'no' : '',
                    socialAssistTypeID: howToPay.type === 'socialAssistance' ? howToPay.value : '',
                    clinicId: '',
                    patientUniqueId: patientUniqueId,
                    page: 1,
                    results: 25
                }
            }
        } else {
            request = {
                location: {
                    latitude: latitude,
                    longitude: longitude,
                    specialityId: selectedSpeciality,
                    searchdate: (selectedDate !== null) ? moment(selectedDate).format('YYYY-MM-DD') : '',
                    radiuskm: distance,
                    insuranceProviderId: howToPay.type === 'insurance' ? howToPay.value : '',
                    acceptAssignment: howToPay.type === 'cash' ? 'no' : '',
                    socialAssistTypeID: howToPay.type === 'socialAssistance' ? howToPay.value : '',
                    clinicId: clinicId,
                    patientUniqueId: patientUniqueId,
                    page: 1,
                    results: 25
                }
            }
        }
        let isApiCall: boolean = false;
        if ( JSON.stringify(apiClinicListRequest) !== JSON.stringify(request)) {
            setApiClinicListRequest(request);
            isApiCall = true;
        }
        if (isApiCall) {
            dispatch(fetchLoadingPending());
            fetchClinicList(request).then((success) => {
                dispatch(fetchLoadingSuccess(false));
                if (success && success.status && success.status.error === false) {
                    setIsClearFilter(false);
                    // setClinicList(success.clinic);
                    if (success && success.total) {
                        setTotalCount(success.total);
                        // setEnd((25) > success.total ?success.total:(25))
                    }
                    dispatch(addClinicProviderList({
                        ...clinicProviderList,
                        clinicList: _.uniqBy(success.clinic, 'id'),
                        clinicTotalCount: success.total ? success.total : totalCount
                    }));
                }
            }).catch((err) => {
                dispatch(fetchLoadingSuccess(false));
                dispatch(addClinicProviderList({
                    ...clinicProviderList,
                    clinicList: [],
                    clinicTotalCount: 0,
                }));
                dispatch(searchFilter({
                    ...searchFilterData,
                    clinicPage: 1
                }));
                console.log(err);
            })
        }
    }
    
    const getClinicProviderList = () => {
        const { oldSpeciality,newSpeciality, location, date, howToPay, gender, distance, languages, clinicId, providerId, patientUniqueId, page, results } = searchFilterData;
        let latitude: any = "", longitude: any = "", selectedLanguage: any = '';
        if(location !== ""){
            latitude=location.latitude;
            longitude=location.longitude;
        }
        let langIds: any = [];
        if (languages && languages.length > 0) {
            langIds = languages.map((item: any) => {
                return item.value
            });
        }
        let request: any={};
        if (isClearFilter) {
            request = {
                location: {
                    latitude: '',
                    longitude: '',
                    specialityId: searchFilterData.oldSpeciality,
                    searchdate:'',
                    radiuskm: '',
                    gender: '',
                    languages: '',
                    insuranceProviderId: howToPay.type === 'insurance' ? howToPay.value : '',
                    acceptAssignment: howToPay.type === 'cash' ? 'no' : '',
                    socialAssistTypeID: howToPay.type === 'socialAssistance' ? howToPay.value : '',
                    clinicId: '',
                    providerId:'',
                    patientUniqueId: patientUniqueId,
                    page: 1,
                    results: 25
                }
            }
        } else {
            request = {
                location: {
                    latitude: latitude,
                    longitude: longitude,
                    specialityId:selectedSpeciality,
                    searchdate: (selectedDate !== null) ? moment(selectedDate).format('YYYY-MM-DD') : '',
                    radiuskm: distance,
                    gender: gender ? gender.toString() : '',
                    languages: langIds.toString(),
                    insuranceProviderId: howToPay.type === 'insurance' ? howToPay.value : '',
                    acceptAssignment: howToPay.type === 'cash' ? 'no' : '',
                    socialAssistTypeID: howToPay.type === 'socialAssistance' ? howToPay.value : '',
                    clinicId: clinicId,
                    providerId:providerId,
                    patientUniqueId: patientUniqueId,
                    page: 1,
                    results: 25
                }
            }
        }
        let isApiCall: boolean=false;
        if ( JSON.stringify(apiClinicProviderRequest) !== JSON.stringify(request)) {
            setApiClinicProviderRequest(request);
            isApiCall = true;
        }
        if (isApiCall) {
            dispatch(fetchLoadingPending());
            try {
                clinicAndProviderList(request).then((success: any) => {
                    if (success && success.response && success.response.data && success.response.status === 200) {
                        if (success.response.data && success.response.data.status.error === false) {
                            setIsClearFilter(false);
                            dispatch(fetchLoadingSuccess(false));
                            if (success.response.data && success.response.data.total) {
                                setTotalCount(success.response.data.total);
                            }
                            dispatch(addClinicProviderList({
                                ...clinicProviderList,
                                clinicList:_.uniqBy(success.response.data.clinic, 'id'),
                                providerList: success.response.data.doctors,
                                providerTotalCount:success.response.data.total?success.response.data.total:totalCount
                                
                            }));
                            // appointmentSlotApi(success.response.data.doctors);
                        }else if(success.response.data && success.response.data.status.error === true){
                            dispatch(addClinicProviderList({
                                ...clinicProviderList,
                                clinicList:[],
                                providerList: [],
                                providerTotalCount: 0,
                                page:1
                            }));
                            console.log('error');
                            dispatch(fetchLoadingSuccess(false));
                        }
                    }
                });
            } catch (error) {
                toast.error('Something went wrong.');
                console.log('error', error);
                dispatch(fetchLoadingSuccess(false));
            }
        } 
    }

    const appointmentSlotApi = (providerList?: any, currentWeek?: any) => {
        var request: any = [];
        let startDate: any = selectedDate ?moment(selectedDate).format('YYYY-MM-DD'):moment().format('YYYY-MM-DD');
        let endDate: any = selectedDate ? moment(selectedDate).add('days', 4).format('YYYY-MM-DD'):moment().add('days',4).format('YYYY-MM-DD');
        if (providerList.length > 0 ) {
            providerList.map((item: any) => {
                if (item.sharecalendar && item.sharecalendar.calender) {
                     request.push({
                        "doctorId": item.id,
                        "clinicId": item.clinic_id,
                        "start_date": startDate,
                        "end_date": endDate
                    });
                }
            });
            if (request.length > 0) {
                let data = { 'appointmentData': request };
                let isApiCall: boolean = false;
                if ( JSON.stringify(apiAppointmentSlotRequest) !== JSON.stringify(data)) {
                    setAppointmentSlotRequest(data);
                    isApiCall = true;
                }
                if (isApiCall) {
                    dispatch(fetchLoadingPending());
                    appointmentSlot(data).then((success: any) => {
                        try {
                            if (success && success.response && success.response.data && success.response.status === 200) {
                                if (success.response.data && success.response.data.status.error === false) {
                                    dispatch(fetchLoadingSuccess(false));
                                    var result = groupBy(success.response.data.appointments, function (item: any) {
                                        return [item.doctorid, item.clinicid];
                                    });
                                    let data: any = [];
                                    result.map((item: any) => {
                                        let groupData = _.groupBy(item, 'startdate');
                                        data.push(groupData);
                                    })
                                    // setGroupByDate(data);
                                    dispatch(addSlotData({ slot: data }));
                                    // this.setState({ appointmentSlotData: result,isAppointmentBook:false,isLoading:false });
                                } else {
                                    if (success.response.data && success.response.data.status.error === true) {
                                        toast.error(success.response.data.status.msg);
                                    }
                                    dispatch(fetchLoadingSuccess(false));
                                }
                            }
                        } catch (error) {
                            toast.error('Something went wrong.');
                            console.log('error', error);
                            dispatch(fetchLoadingSuccess(false));
                        }
                    });
                } else {
                    dispatch(fetchLoadingSuccess(false));
                }
            }
        }
    }
    const groupBy = (array: any, f: any) => {
        var groups: any = {};
        array.forEach(function (o: any) {
            var group = JSON.stringify(f(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
        });
        return Object.keys(groups).map(function (group) {
            return groups[group];
        })
    }

    const getSpecialityApi = () => {
        getSpeciality().then((success: any) => {
            try {
                if (success && success.response && success.response.data && success.response.status === 200) {
                    if (success.response.data && success.response.data.status.error === false) {
                        setSpecialities(success.response.data.specialities);
                    } else {
                        if (success.response.data && success.response.data.status.error === true) {
                            toast.error(success.response.data.status.msg);
                        }
                    }
                }
            } catch (error) {
                toast.error('Something went wrong.');
                console.log('error', error);
            }
        });
    }
    const handleSpeciality = (value: any) => {
        if (value !== searchFilterData.newSpeciality) {
            setselectedSpeciality(value);
        }
    }

    const handleChange = (date: any) => {
        if (moment(date).format('YYYY-MM-DD') !== searchFilterData.date) {
            setSelectedDate(date);
        }
    };

    const handleClearFilter = () => {
        setApiClinicProviderRequest('');
        setApiClinicListRequest('');
        dispatch(searchFilter({
            ...searchFilterData,
            newSpeciality: searchFilterData.patientUniqueId ?'':searchFilterData.oldSpeciality,
            location: '',
            date: null,
            howToPay:  searchFilterData.patientUniqueId?'':searchFilterData.howToPay,
            gender: '',
            distance: '',
            languages: '',
            clinicId: '',
            providerId: '',
            page: 1,
            results: 25
        }));
        setIsClearFilter(true);
        setselectedSpeciality(searchFilterData.oldSpeciality);
        setSelectedDate(null);
    }

    useEffect(() => {
        if (isClearFilter) {
            if (props.selectedTab === "provider") {
                getClinicProviderList();
            } else {
                fetchClinicListApi();
            }
        }
    },[isClearFilter]);
    return (
        <>
            <div className="d-flex my-4 fitlter-box flex-wrap">
                <div className="form-check mb-3 mb-md-0 filter-ops">
                    <KeyboardDatePicker
                        className="select-date"
                        placeholder={'Date'}
                        // margin="normal"
                        id="date-picker-dialog"
                        format="YYYY-MM-DD"
                        minDate={new Date()}
                        value={selectedDate}
                        onChange={(date:any)=>{handleChange(date)}}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </div>
                <div className="form-check mb-3 mb-md-0 filter-ops">
                    <select className="select-spec form-control" id="exampleFormControlSelect2" value={selectedSpeciality}
                        onChange={(e) => { handleSpeciality(e.target.value) }}>
                        <option value="">Select Speciality</option>
                        {
                            specialities && specialities.length > 0 &&
                            specialities.map((item: any,index:number) => {
                                return (
                                    <option key={index+Math.random()+'speciality'} value={item.id}>{item.name}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div className="form-check mb-3 mb-md-0 filter-ops d-md-none locFilter">
                    <Location
                        selectedTab={props.selectedTab}
                    />
                </div>
                <div onClick={() => { setShowMoreFilter(true) }} className="form-check form-btns">
                    <label className="form-check-label btn-filter" htmlFor="inlineCheckbox2">More Filters</label>
                </div>
                <div onClick={() => { handleClearFilter() }} className="form-check form-btns">
                    <div className="btn btn-clear-filter">Clear Filters</div>
                </div>
            </div>
            {
                (showMoreFilter) &&
                <MoreFilterModal
                    isShow={showMoreFilter}
                    onHide={()=>{setShowMoreFilter(false)}}
                    filterApply={()=>{
                        setShowMoreFilter(false);
                    }}
                    selectedTab={props.selectedTab}
                 />
            }
        </>
    )
}

export default Filter
