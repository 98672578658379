import React from 'react';
import Footer from '../Footer/Footer';
import HeaderWhite from '../LandingPageSearchNew/pages/HeaderWhite/HeaderWhite';
class NewPatients extends React.Component {
    constructor(props: any) {
        super(props);
    }
    render() {
        return (
            <>
                <HeaderWhite></HeaderWhite>
                {/* <section className="banner-section master-banner sub-banner">
                    <div className="bg-overlay"></div>
                    <div className="container h-100">
                        <div className="row h-100">
                            <div className="col-12 align-self-center text-center">
                                <h1>
                                    New Patients
                                </h1>
                            </div>
                        </div>
                    </div>
                </section> */}
                <div className="no_banner"></div>
                <section className="how-it-work-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="like-h1">How We Generate New Patients</h2>
                                <p>
                                    XCarePro is the risk-free patient acquisition tool for both dental practices and end users looking for a great healthcare provider.
                                </p>
                                <p>
                                    Our digital platform works by connecting patients searching for services, with a quality healthcare provider in their area. The platform connects the patient’s booking request to the dental practice’s current office management software, providing clinics with a revolutionary patient acquisition tool that seamlessly integrates right into the practice.
                                </p>
                                <hr />
                                <h2 className="like-h1">How We Do It</h2>
                                <p>
                                    XCarePro targets thousands of in-market patients daily that are looking for services. We reach these patients through a variety of channels and match them to dental practices based on their needs and requirements. We’ve mastered patient acquisition ensuring all patients are qualified and well suited to the dental practice, at no risk to either the patient or the healthcare provider. Healthcare provider can worry less about marketing their practices and can focus on providing quality dental care, while patients can rest assured that they are getting the best oral health care possible.
                                </p>
                                <hr />
                                <h2 className="like-h1">What’s The Catch?</h2>
                                <p>
                                    There is none. We operate with a results driven philosophy, which means that dental practices only pay when a patient receives care. Unlike other marketing options, we have no recurring fees, hidden fees or commitments.
                                </p>
                                <p>
                                    After each appointment, the patient is rewarded with a gift card of their choice as a thank you for trusting our platform to help them find and book their dental appointment. Patients can easily claim and choose their gift card within their XCarePro patient portal.
                                </p>
                                <p>
                                    Pricing for patients that receive care is based on a variety of factors, including practice location and your participation in insurance networks.
                                </p>
                                <p>
                                    If you are looking to find a quality healthcare provider in your area, sign up today.
                                </p>
                                <p>
                                    For dental practices looking to be a part of the XCarePro platform, contact our team to get started today.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="dentist_dashboard">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="bg_design">
                                    <img src={process.env.PUBLIC_URL + "/images/dentist_dashboard.svg"} />
                                </div>
                                <div className="dentist_pt">
                                    <h2>Are you a <strong>healthcare provider?</strong></h2>
                                    <p className="intro_desc">Do patients love your practice? <br />You could qualify for XCarePro.</p>
                                    <ul className="big_list">
                                        <li>
                                            <div><strong>You're in good company</strong></div>
                                            <div>We work exclusively with incredible healthcare provider who always put the patient first.</div>
                                        </li>
                                        <li>
                                            <div><strong>Be where the patients are</strong></div>
                                            <div>Showcase your practice to people who are searching for a new healthcare provider.</div>
                                        </li>
                                        <li>
                                            <div><strong>Help create more smiles</strong></div>
                                            <div>See new patients every month and help even more people with their oral health.</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <img src={process.env.PUBLIC_URL + "/images/dashboard-img.png"} className="img-fluid" />
                                <div className="dashboard">
                                    <p>Are you a top doctor or healthcare provider? <br /> List your practice on XCarePro</p>
                                    <a href="/clinic-registration/#gotodentist-form" className="btn btn-custom">Sign Up For Free</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <Footer></Footer> */}
            </>
        );
    }
}
export default NewPatients;