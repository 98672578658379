import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { fetchClinicProfile } from '../../services/dentist';
import { weekDay, timeConvert } from '../../utils/global';
import apiConfigs from '../../configs/apiConfigs';
import BookPopUp from '../BookPopUp/BookPopUp';
import { RatingComp } from '../RatingComponent';
import { findDistanceBetweenTwolatLng } from '../../utils/googleFunction';
import * as _ from 'lodash';
import moment from 'moment';
import './Result.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { fetchReviews, getHygieneFeatures } from '../../services/results';
import InfiniteScroll from "react-infinite-scroll-component";
import { HygieneRating } from '../hygieneRating';
import { Avatar } from '@material-ui/core';
interface Props {
    match: any;
    location: any;
}
interface State {
    profile: any;
    viewAllServices: boolean;
    quizResults: any;
    currentShowDoctor: string;
    leftServicePane: string;
    selectedService: any[];
    allService: any[];
    showModal: boolean;
    place: any;
    redirectToHomePage: boolean;
    distance: string;
    showMostHelpfulReview: boolean;
    allReviews: any[];
    counter: any;
    page: number;
    records: number;
    star: number;
    isReviewFetched: boolean;
    photoIndex: number;
    isOpen: boolean;
    rating: any;
    allReviewCompleted: boolean;
    reviewCompletedMsg: string;
    viewMoreBtnText: string;
    hygieneList: { status: boolean, list: any[] };
}
/* function RatingComp(props: { rating: number }) {
    console.log(props);
    const elements = [];
    if (props.rating) {
        for (let start = 0; start < Math.ceil(props.rating); start++) {
            console.log(props);
            if (props.rating % 1 !== 0 && start      Math.ceil(props.rating) - 1) {
                elements.push(<i className="fas fa-star-half-alt"></i>);
            } else {
                elements.push(<i className="fas fa-star"></i>);
            }
        }
        return (<>{elements}</>);
    }
    return null;
} */
class Results extends React.Component<Props, State> {
    map: any;
    autocomplete: any;
    autcompleteRef: any;
    currentDirectionRenderer: any;
    ratingReviewRef: any;
    constructor(props: any) {
        super(props);
        this.state = {
            profile: '',
            viewAllServices: false,
            quizResults: '',
            currentShowDoctor: '', leftServicePane: '',
            selectedService: [], allService: [], showModal: false,
            place: '',
            redirectToHomePage: false,
            distance: '0',
            showMostHelpfulReview: false, allReviews: [], counter: {},
            page: 1, records: 10, star: -1, isReviewFetched: false,
            photoIndex: 0, isOpen: false, rating: {}, allReviewCompleted: false,
            reviewCompletedMsg: '', viewMoreBtnText: 'View More', hygieneList: { status: false, list: [] },
        };
        this.autcompleteRef = React.createRef();
        this.ratingReviewRef = React.createRef();
        this.changeHandler = this.changeHandler.bind(this);
    }
    componentDidMount() {
        /* Fetch Hygiene list */
        this.fetchHygieneList();

        const result: any = localStorage.getItem('ca.xp.wizard.quiz.request');
        if (result === undefined || result === null || result === "") {
            window.location.href = '/';
        }
        if (!document.getElementById('googlePlaceScript')) {
            const googleScript = document.createElement('script');
            googleScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDIDMklRxHmFYWU8Vvo1P-dVkB_nbHARj8&libraries=places`
            googleScript.id = "googlePlaceScript";
            googleScript.async = true;
            window.document.body.appendChild(googleScript);
            googleScript.addEventListener('load', () => {
                try {
                    //@ts-ignore
                    this.autocomplete = new window.google.maps.places.Autocomplete(
                        this.autcompleteRef.current);
                    this.autocomplete.addListener('place_changed', () => {
                        const place = this.autocomplete.getPlace();
                        if (place) {
                            this.setState({ place: place });
                        }
                        if (place && place.geometry && place.geometry.location && place.geometry.location.lat() && place.geometry.location.lng()) {
                            this.drawRoute(place.geometry.location.lat(), place.geometry.location.lng());
                        }
                    });
                    const result: any = localStorage.getItem('ca.xp.wizard.quiz.request');
                    if (result !== undefined && result !== null && result !== "") {
                        const resultObj = JSON.parse(result);
                        this.setMap(resultObj);
                        if (resultObj && resultObj.location && resultObj.location.latitude && resultObj.location.longitude) {
                            this.drawRoute(resultObj.location.latitude, resultObj.location.longitude);
                        }
                    } else {

                    }
                } catch (err) {

                }
            });
        } else {
            try {
                //@ts-ignore
                this.autocomplete = new window.google.maps.places.Autocomplete(
                    this.autcompleteRef.current);
                this.autocomplete.addListener('place_changed', () => {
                    const place = this.autocomplete.getPlace();
                    if (place) {
                        this.setState({ place: place });
                    }
                    if (place && place.geometry && place.geometry.location && place.geometry.location.lat() && place.geometry.location.lng()) {
                        this.drawRoute(place.geometry.location.lat(), place.geometry.location.lng());
                    }
                });
                const result: any = localStorage.getItem('ca.xp.wizard.quiz.request');
                if (result !== undefined && result !== null && result !== "") {
                    const resultObj = JSON.parse(result);
                    this.setMap(resultObj);
                    this.drawRoute(resultObj.location.latitude, resultObj.location.longitude);
                } else {

                }
            } catch (err) {

            }
        }
        const { uniqueId } = this.props.match.params;
        fetchClinicProfile(uniqueId).then((success: any) => {
            if (success.response && success.response.status === 200) {
                if (success.response.data && success.response.data.status.error === false) {
                    this.setState({ profile: success.response.data }, () => {
                        try {
                            if (this.map) {
                                this.placeCenterMarker({
                                    latitude: this.state.profile.address.latitude ? this.state.profile.address.latitude : '',
                                    longitude: this.state.profile.address.latitude ? this.state.profile.address.longitude : ''
                                });
                            }
                            const result: any = localStorage.getItem('ca.xp.wizard.quiz.request');
                            if (result !== undefined && result !== null && result !== "") {
                                const resultObj = JSON.parse(result);
                                this.setMap(resultObj);
                                this.drawRoute(resultObj.location.latitude, resultObj.location.longitude);
                                let servicesArray: any[] = [];
                                if (this.state.profile && this.state.profile.doctors) {
                                    this.state.profile.doctors.forEach((docValue: any) => {
                                        if (docValue.services) {
                                            docValue.services.forEach((service: any) => {
                                                servicesArray.push(service);
                                            });
                                        }
                                    });
                                }
                                servicesArray = _.uniqBy(servicesArray, 'id');
                                const finded = servicesArray.findIndex((val) => {
                                    return val.name.toLowerCase() === "Cleaning".toLowerCase();
                                });
                                if (finded == -1) {
                                    servicesArray.push({ id: 4, name: 'Cleaning' });
                                }
                                if (resultObj.reason.title.value.toLowerCase() === "Checkup & Cleaning".toLowerCase()) {
                                    this.setState({
                                        leftServicePane: 'Treatment',
                                        selectedService: [{ id: 4, name: 'Cleaning' }],
                                        allService: servicesArray
                                    });
                                } else if (resultObj.reason.title.value.toLowerCase() === "Specific Treatment".toLowerCase()) {
                                    this.setState({
                                        leftServicePane: resultObj.reason.title.value,
                                        selectedService: resultObj.reason.reasonids,
                                        allService: servicesArray
                                    });
                                } else if (resultObj.reason.title.value.toLowerCase() === "Urgent Issue".toLowerCase()) {
                                    this.setState({
                                        leftServicePane: resultObj.reason.title.value,
                                        selectedService: [resultObj.reason.reasonUrgent.id.charAt(0).toUpperCase() + resultObj.reason.reasonUrgent.id.slice(1)],
                                        allService: servicesArray
                                    });
                                }
                            }
                        } catch (err) {
                        }
                    });
                }
            }
        }).catch((err) => {

        });
        /* Fetch the default review with page 1 and  */
        fetchReviews(uniqueId, this.state.page, this.state.records, this.state.star).then((success: any) => {
            if (success.response && success.response.status === 200) {
                const { data } = success.response;
                if (data.status && data.status.error === false) {
                    if (!data.counter['1']) {
                        Object.assign(data.counter, { "1": "0" });
                    } else if (!data.counter['2']) {
                        Object.assign(data.counter, { "2": "0" });
                    } else if (!data.counter['3']) {
                        Object.assign(data.counter, { "3": "0" });
                    } else if (!data.counter['4']) {
                        Object.assign(data.counter, { "4": "0" });
                    } else if (!data.counter['5']) {
                        Object.assign(data.counter, { "5": "0" });
                    }
                    this.setState({ allReviews: data.reviews, counter: data.counter, rating: data.rating });
                }
            }
            this.setState({ isReviewFetched: true });
        }).catch((error) => {
            this.setState({ isReviewFetched: true });
        });
        try {
            const result: any = localStorage.getItem('ca.xp.wizard.quiz.request');
            if (result !== undefined && result !== null && result !== "") {
                const resultObj = JSON.parse(result);
                this.setState({ quizResults: resultObj });
            }
        } catch (err) {
        }
        window.scrollTo(0, 0);
    }
    async fetchHygieneList() {
        try {
            const results = await getHygieneFeatures();
            if (results.hygieneFeatures) {
                this.setState({ hygieneList: { status: true, list: results.hygieneFeatures } });
            }
        } catch (error) {
            this.setState({ hygieneList: { status: false, list: [] } });
        }
    }
    drawRoute(latitude: any, longitude: any) {
        try {
            findDistanceBetweenTwolatLng({ lat: latitude, lng: longitude }, { lat: this.state.profile.address.latitude, lng: this.state.profile.address.longitude }).then((success: any) => {
                this.setState({ distance: success });
            }, (error) => {
                console.log('Km finding error');
            });
        } catch (err) {
            console.log('Km finding error');
        }
        try {
            if (this.currentDirectionRenderer) {
                this.currentDirectionRenderer.setMap(null);
            }
            const originLatLng = latitude + ',' + longitude;
            const destinationLatLng = this.state.profile.address.latitude + ',' + this.state.profile.address.longitude;
            //@ts-ignore
            const directionsService = new google.maps.DirectionsService();
            //@ts-ignore
            var directionsDisplay = new google.maps.DirectionsRenderer();
            this.currentDirectionRenderer = directionsDisplay;
            directionsDisplay.setMap(this.map);

            var request = {
                origin: originLatLng,
                destination: destinationLatLng,
                //@ts-ignore
                travelMode: google.maps.DirectionsTravelMode.DRIVING
            };
            directionsService.route(request, function (response: any, status: any) {
                //@ts-ignore
                if (status == google.maps.DirectionsStatus.OK) {
                    directionsDisplay.setDirections(response);
                }
            });
        } catch (err) {

        }
    }
    setMap(resultObj: any) {
        try {
            if (!this.map) {
                //@ts-ignore
                this.map = new google.maps.Map(document.getElementById('map'), {
                    center: { lat: resultObj.location.latitude, lng: resultObj.location.longitude },
                    streetViewControl: !1,
                    zoom: 16,
                    scrollwheel: !1,
                    draggable: !1,
                    disableDoubleClickZoom: !0,
                    disableDefaultUI: !0,
                    gestureHandling: 'cooperative',
                    zoomControl: false,
                    maxZoom: 16,
                    styles: [{
                        featureType: "poi",
                        elementType: "labels",
                        stylers: [{
                            visibility: "off"
                        }]
                    }, {
                        featureType: "transit.station",
                        elementType: "labels",
                        stylers: [{
                            visibility: "off"
                        }]
                    }],
                    //@ts-ignore
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                });
                //@ts-ignore
                google.maps.event.addListenerOnce(this.map, 'idle', () => {
                    // do something only the first time the map is loaded
                    //@ts-ignore
                    google.maps.event.trigger(this.map, 'resize');
                    this.map.setZoom(this.map.getZoom());
                });
            }
        } catch (err) {
            console.log(err);
        }
    }
    placeCenterMarker(centerLocation: any) {
        //@ts-ignore
        var myLatlng = new google.maps.LatLng(centerLocation.latitude, centerLocation.longitude);
        var image = process.env.PUBLIC_URL + "/images/map-location-pin-for-gmap.svg";
        //@ts-ignore
        var marker = new google.maps.Marker({
            position: myLatlng,
            icon: image,
            //@ts-ignore
            animation: google.maps.Animation.DROP
        });
        this.map.setCenter(myLatlng);
        //marker.setMap(this.map);
    }
    changeHandler(status: any, redirect: any) {
        this.setState({
            showModal: status,
            redirectToHomePage: redirect
        });
    }
    navigateToRatingReviewSection(event: any) {
        this.setState({ showMostHelpfulReview: true }, () => {
            this.scrollToMyRef(this.ratingReviewRef.current.offsetTop);
        });
    }
    scrollToMyRef(ref: any) {
        window.scrollTo(0, ref);
    }
    fetchRatingOnStar(starGiven: number) {
        const page = 1;
        const records = this.state.records;
        this.setState({ page: page, records: records, star: starGiven, isReviewFetched: false, allReviewCompleted: false, reviewCompletedMsg: '' });
        const { uniqueId } = this.props.match.params;
        /* Fetch the review with page 1 and star  */
        fetchReviews(uniqueId, page, records, starGiven).then((success: any) => {
            if (success.response && success.response.status === 200) {
                const { data } = success.response;
                console.log(data);
                if (data.status && data.status.error === false) {
                    this.setState({ allReviews: data.reviews });
                }
            }
            this.setState({ isReviewFetched: true });
        }).catch((error) => {
            this.setState({ isReviewFetched: true });
        });
    }
    viewMore() {
        const page = this.state.page + 1;
        const records = this.state.records;
        const star = this.state.star;
        this.setState({ page: page, records: records, star: star, viewMoreBtnText: 'Loading More...', allReviewCompleted: false, reviewCompletedMsg: '' });
        const { uniqueId } = this.props.match.params;
        const yOffset = window.pageYOffset;
        console.log(yOffset);
        /* Fetch the default review with page 1 and  */
        fetchReviews(uniqueId, page, records, star).then((success: any) => {
            if (success.response && success.response.status === 200) {
                const { data } = success.response;
                if (data.status && data.status.error === false) {
                    this.setState({ allReviews: [...this.state.allReviews, ...data.reviews], viewMoreBtnText: 'View More' }, () => {
                        window.scrollTo(0, yOffset);
                    });
                } else {
                    this.setState({ allReviewCompleted: true, reviewCompletedMsg: data.status.msg, viewMoreBtnText: 'View More' });
                }
            }
        }).catch((err) => {
        });
    }
    render() {
        if (this.state.redirectToHomePage) {
            return (<Redirect to="/"></Redirect>)
        }
        return (
            <>
                <header className="inner-header cd-header">
                    <div className="container-fluid">
                        <div className="main-heder-sec d-flex justify-content-between">
                            <a className="navbar-brand" href="/">
                                <img src={process.env.PUBLIC_URL + "/images/logo-black.svg"} alt="logo" />
                            </a>
                            <Link className="btn" to="/quiz/results"><img src={process.env.PUBLIC_URL + "/images/ic_wrap_text_24px.svg"} className="mr-3" /><span className="d-none d-md-inline-block">Choose another clinic</span></Link>
                        </div>
                    </div>
                </header>
                <section className="cd-map-sec">
                    <div id="map"></div>
                </section>
                <div className="cd-page">
                    <section className="client-detail-sec p-0">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="cd-inner-section">
                                        <div className="chk-distance" id="distanceInput">
                                            <div className="input-group">
                                                <input
                                                    ref={this.autcompleteRef}
                                                    type="text" className="form-control" placeholder="Select other location to check distance" />
                                                <div className="input-group-append">
                                                    <img src={process.env.PUBLIC_URL + "/images/distance-arrow.svg"} className="mr-2" />
                                                    <div className="txt-sec align-self-center">
                                                        Distance
                                                        <span>
                                                            <strong>
                                                                {this.state.distance.split(" ")[0] ? this.state.distance.split(" ")[0] : this.state.distance}
                                                            </strong>
                                                            &nbsp;{this.state.distance.split(" ")[1] ? this.state.distance.split(" ")[1][0].toUpperCase() + this.state.distance.split(" ")[1].slice(1) : ''}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="practician-name media" id="doctorDetail">
                                            <div className="media-body mr-5">
                                                <div className="trusted-txt"><img src={process.env.PUBLIC_URL + "/images/trusted-icon.svg"} className="mr-2" />Trusted by XCarePro</div>
                                                <div className="p-name">{(this.state.profile && this.state.profile !== '' && this.state.profile.name) ? this.state.profile.name : ''}</div>
                                                <div className="p-address">{
                                                    (this.state.profile && this.state.profile !== '' && this.state.profile.address) &&
                                                    <>
                                                        {this.state.profile.address.route}
                                                        ,&nbsp;
                                                                        {
                                                            (this.state.profile.address.sublocality && this.state.profile.address.sublocality !== "") ?
                                                                <>{this.state.profile.address.sublocality},&nbsp;</>
                                                                : (this.state.profile.address.neighborhood && this.state.profile.address.neighborhood !== "") ?
                                                                    <>{this.state.profile.address.neighborhood},&nbsp;</>
                                                                    : (this.state.profile.address.locality && this.state.profile.address.locality !== "") ?
                                                                        <>{this.state.profile.address.locality},&nbsp;</> : null
                                                        }

                                                        {
                                                            this.state.profile.address.address_level_1_short
                                                        }
                                                        ,&nbsp;
                                                                        {
                                                            this.state.profile.address.postal
                                                        }
                                                    </>
                                                }</div>
                                            </div>
                                            <div className="media-img align-self-center mr-5">
                                                {
                                                    (this.state.rating && this.state.rating.avg && this.state.rating.total) &&
                                                    <div className="media-img" onClick={this.navigateToRatingReviewSection.bind(this)}>
                                                        <div className="ratting-value"><strong>{this.state.rating.avg}</strong>/5 <span>Avg. Rating</span></div>
                                                        {

                                                        }
                                                        <div className="ratting-star">
                                                            {
                                                                <RatingComp rating={this.state.rating.avg}></RatingComp>
                                                            }
                                                            <span>{this.state.rating.total} Reviews</span>
                                                        </div>



                                                    </div>
                                                }

                                                {
                                                    (this.state.hygieneList.status && this.state.profile.HygieneFeatures) &&
                                                    <HygieneRating key={'hrr1'} HygieneFeatures={this.state.profile.HygieneFeatures} hygieneList={this.state.hygieneList}></HygieneRating>
                                                }
                                            </div>
                                        </div>
                                        <hr />
                                        {
                                            this.state.showMostHelpfulReview === true &&
                                            <>
                                                <div className={"helpful-review"} ref={this.ratingReviewRef}>
                                                    <div className="title-txt">Most Helpful Review</div>
                                                    <p>Great service. Warm & thorough staff. All my questions were answered in detail and I was kept informed throughout the process. Haven't been to a dentist in a while but they made it easy.</p>
                                                </div>
                                                <hr />
                                            </>
                                        }
                                        <div className="highlights">
                                            <div className="section-title">Preferences & Highlights</div>
                                            <ul className="row highlights-listing">
                                                <li className="col-sm-4">
                                                    <div className="media">
                                                        <div className="media-img mr-3 align-self-center">
                                                            <img src={process.env.PUBLIC_URL + "/images/new-patients.svg"} className="img-fluid" />
                                                        </div>
                                                        <div className="media-body align-self-center">
                                                            Accepting new patients
                                                        </div>
                                                    </div>
                                                </li>
                                                {
                                                    (this.state.profile
                                                        && this.state.profile !== '' && this.state.profile.hours
                                                        && (this.state.profile.hours[6] || this.state.profile.hours[7])) &&
                                                    <li className="col-sm-4">
                                                        <div className="media">
                                                            <div className="media-img mr-3 align-self-center">
                                                                <img src={process.env.PUBLIC_URL + "/images/weekends-d.svg"} className="img-fluid" />
                                                            </div>
                                                            <div className="media-body align-self-center">
                                                                Available for <span>weekend</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                        <hr />
                                        <div className="services-offered">
                                            <div className="section-title">Services</div>
                                            <div className="row">
                                                <div className="col-md-4 align-self-center">
                                                    <div className="service-selected-img media">
                                                        <div className="media-img mr-3 align-self-center">
                                                            <img src={process.env.PUBLIC_URL + "/images/guy-in-dentist-chair-d.svg"} className="img-fluid" />
                                                        </div>
                                                        <div className="media-body align-self-center">
                                                            {this.state.leftServicePane}<span>You have selected</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 align-self-center">
                                                    <ul className="service-selected-list">
                                                        {
                                                            this.state.selectedService.map((value: any) => {
                                                                return (
                                                                    <li key={Math.random()}>{value.name ? value.name : value}</li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                                <div className="col-md-4 align-self-center text-center">
                                                    <button className="btn btn-custom w-100" onClick={(e) => {
                                                        this.setState({ viewAllServices: true });
                                                    }}>View all services</button>
                                                    <span className="label-txt">by clinic</span>
                                                </div>
                                            </div>
                                            <hr />
                                            {
                                                this.state.viewAllServices === true &&
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="all-services">
                                                            <button className="close-list btn" onClick={(e) => {
                                                                this.setState({ viewAllServices: false });
                                                            }}>
                                                                <img src={process.env.PUBLIC_URL + "/images/close-list-btn.svg"} />
                                                            </button>
                                                            <ul className="all-service-list">
                                                                {
                                                                    this.state.allService.map((service) => {
                                                                        const selectedService = this.state.selectedService.findIndex((selService) => {
                                                                            return service.name.toLowerCase() === selService.name.toLowerCase();
                                                                        })
                                                                        return (
                                                                            <li key={Math.random()} className={"" + (selectedService !== -1 ? 'selected' : '')}>{service.name}</li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                        <hr />
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        <div className="insurance-details">
                                            <div className="section-title">Insurance</div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="media">
                                                        <div className="media-img mr-4">
                                                            <img src={process.env.PUBLIC_URL + "/images/insurance-icon.svg"} />
                                                        </div>
                                                        <div className="media-body align-seft-center">
                                                            <div className="title-txt">Accepts most insurance plans</div>
                                                            <p>You will only pay for costs not covered by your plan</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 text-md-right text-center">
                                                    <div className="label-txt">You have selected</div>
                                                    {
                                                        <>
                                                            {
                                                                (this.state.quizResults !== '' && this.state.quizResults.insurance) &&
                                                                <>{this.state.quizResults.insurance['name']}</>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            {/* <div className="row">
                                                <div className="col-md-6">
                                                    <div className="media">
                                                        <div className="media-img mr-4">
                                                            <img src={process.env.PUBLIC_URL + "/images/insurance-icon.svg"} />
                                                        </div>
                                                        <div className="media-body align-seft-center">
                                                            <div className="title-txt">Bills your insurance directly</div>
                                                            <p>You will only pay for costs not covered by your plan</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                        <hr className="mt-0" />
                                        {
                                            (this.state.profile && this.state.profile.features && this.state.profile.features.findIndex((val: any) => { return val.id.toString() === "21" }) !== -1) &&
                                            <>
                                                {
                                                    <>
                                                        <div className="financing-plans">
                                                            <div className="section-title">Financing plans</div>
                                                            <div className="media">
                                                                <div className="media-img mr-3 align-seft-center">
                                                                    <img src={process.env.PUBLIC_URL + "/images/finance-icon.svg"} />
                                                                </div>
                                                                <div className="media-body align-seft-center">
                                                                    <p>This office offers flexible low-interest financing options to ensure all patients can get the dental care they need</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr />
                                                    </>
                                                }
                                            </>
                                        }
                                        <div className="img-gallery">
                                            <div className="section-title">Practice Photos</div>
                                            <div className="row mx-0">
                                                {
                                                    (this.state.profile && this.state.profile.photos) &&
                                                    <>
                                                        {
                                                            this.state.profile.photos.map((value: any, index: number) => {
                                                                return (
                                                                    <div className="p-0 col-6 col-md-3" key={Math.random()} onClick={() => { this.setState({ isOpen: true, photoIndex: index }) }}>
                                                                        <div className="img-box">
                                                                            <img src={apiConfigs.IMAGE_URL + 'clinic/' + value.image.name + '-500x500.' + value.image.type} />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="practice-features">
                                            <div className="section-title">Practice Features</div>
                                            <ul>
                                                {
                                                    (this.state.profile && this.state.profile.features) &&
                                                    <>
                                                        {
                                                            this.state.profile.features.map((val: any) => {
                                                                return (
                                                                    <li key={Math.random()}>{val.name}</li>
                                                                );
                                                            })
                                                        }
                                                    </>
                                                }
                                            </ul>
                                        </div>
                                        <hr />
                                        <div className="dr-profiles">
                                            {
                                                (this.state.profile && this.state.profile.doctors) &&
                                                <>
                                                    {
                                                        this.state.profile.doctors.map((doc: any) => {
                                                            let url = '';
                                                            let doctorName = doc.fullname.replace("Dr.", "");
                                                            doctorName = doctorName.trim();
                                                            doctorName = doctorName.split(" ");
                                                            const letterOne = doctorName[0] ? doctorName[0].charAt(0) : '';
                                                            const letterTwo = doctorName[1] ? doctorName[1].charAt(0) : '';
                                                            if (doc.photo && doc.photo.name && doc.photo.type) {
                                                                url = apiConfigs.IMAGE_URL + 'doctor/' + (doc.photo ? doc.photo.name ? doc.photo.name : '' : '') + '-200x200.' +
                                                                    (doc.photo ? doc.photo.type ? doc.photo.type : '' : '');
                                                            } else {
                                                                url = apiConfigs.DEFAULT_IMAGE_URL_DOCTOR;
                                                            }
                                                            return (
                                                                <div className="profile-box" key={Math.random()}>
                                                                    <div className="media">
                                                                        <div className="media-img mr-4">
                                                                            {
                                                                                url === '' ?
                                                                                    <Avatar>{letterOne}{letterTwo}</Avatar>
                                                                                    : <img src={url} className="img-fluid" />
                                                                            }
                                                                        </div>
                                                                        <div className="media-body">
                                                                            <div className="dr-profile-visible">
                                                                                <div className="dr-name">{doc.fullname ? doc.fullname : ''}</div>
                                                                                <div className="trusted-txt"><img src={process.env.PUBLIC_URL + "/images/trusted-icon.svg"} className="mr-2" />Trusted by Xcarepro</div>
                                                                                <button className="btn view-btn" onClick={(e) => { this.setState({ currentShowDoctor: doc.uniqueid }) }}>View Profile</button>
                                                                            </div>
                                                                            {
                                                                                this.state.currentShowDoctor === doc.uniqueid &&
                                                                                <div className="dr-profile-hidden">
                                                                                    <div className="profile-desc">
                                                                                        <p>{doc.description ? doc.description : ''}</p>
                                                                                    </div>
                                                                                    <div className="profile-qualifications row">
                                                                                        {
                                                                                            (doc.educations && doc.educations.length > 0) ?
                                                                                                <div className="col-6">
                                                                                                    <strong>Education</strong>
                                                                                                    {
                                                                                                        doc.educations.map((education: any, index: number) => {
                                                                                                            return <span className="label-txt" key={index + Math.random()}>{education}</span>
                                                                                                        })
                                                                                                    }
                                                                                                </div> :
                                                                                                <div className="col-6">
                                                                                                    {/* <strong>Education</strong> */}
                                                                                                </div>
                                                                                        }
                                                                                        {
                                                                                            (doc.certifications && doc.certifications.length > 0) ?
                                                                                                <div className="col-6">
                                                                                                    <strong>Association</strong>
                                                                                                    {
                                                                                                        doc.certificates.map((certificate: any, index: number) => {
                                                                                                            return <span className="label-txt" key={index + Math.random()}>{certificate}</span>
                                                                                                        })
                                                                                                    }
                                                                                                </div> :
                                                                                                <div className="col-6">
                                                                                                    {/* <strong>Association</strong> */}
                                                                                                </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </>
                                            }
                                        </div>
                                        <div className="patient-reviews">
                                            <div className="title-sec media">
                                                <div className="media-body">
                                                    <div className="section-title">Patient reviews</div>
                                                    <p>Read About Other Patients' Experiences</p>
                                                </div>
                                                {
                                                    (this.state.rating && this.state.rating.avg && this.state.rating.total) &&
                                                    <div className="media-img align-self-center mr-5" onClick={this.navigateToRatingReviewSection.bind(this)}>
                                                        <div className="ratting-value"><strong>{this.state.rating.avg}</strong>/5 <span>Avg. Rating</span></div>
                                                        <div className="ratting-star">
                                                            {
                                                                <RatingComp rating={this.state.rating.avg}></RatingComp>
                                                            }
                                                            <span>{this.state.rating.total} Reviews</span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <hr />
                                            {
                                                (this.state.allReviews.length === 0 && this.state.isReviewFetched) &&
                                                <div className="rwnofound_badge">
                                                    <i className="fas fa-exclamation-triangle"></i> There are no review for this clinic
                                                </div>
                                            }

                                            {/* please hide this if no review */}
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    {
                                                        Object.keys(this.state.counter).reverse().map((value, index) => {
                                                            const percentage = (this.state.counter[value] / this.state.profile.reviewCount) * 100;
                                                            return (
                                                                <>
                                                                    <div className="side">
                                                                        <div>{value} <i className="fas fa-star"></i></div>
                                                                    </div>
                                                                    <div className="middle" onClick={(e) => {
                                                                        if (this.state.counter[value] !== "0") {
                                                                            this.fetchRatingOnStar(Number(value));
                                                                        }
                                                                    }}>
                                                                        <div className="bar-container">
                                                                            <div className={"bar-" + value} style={{ width: percentage + '%' }}></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="side right">
                                                                        <div>{this.state.counter[value]}</div>
                                                                    </div>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            {
                                                (this.state.isReviewFetched === false) &&
                                                <>Loading reviews...</>
                                            }
                                            {
                                                (this.state.allReviews.length !== 0 && this.state.isReviewFetched === true) &&
                                                <div className="cd-review-sec text-center">
                                                    {/* <InfiniteScroll
                                                        dataLength={this.state.allReviews.length}
                                                        next={this.viewMore.bind(this)}
                                                        hasMore={this.state.counter[this.state.star] !== this.state.allReviews.length}
                                                        loader={<h4>Loading...</h4>}
                                                    > */}
                                                    {
                                                        this.state.allReviews.map((value, index) => {
                                                            return (
                                                                <div className="media" key={index + Math.random()}>
                                                                    <div className="media-body text-md-left text-lg-left">
                                                                        {/* {
                                                                                this.state.star === -1 && */}
                                                                        <div className="ratting-star">
                                                                            <RatingComp rating={Number(value.rating)}></RatingComp></div>
                                                                        {/* } */}
                                                                        <div className="review-content">
                                                                            {value.reviewText ? value.reviewText : ''}{/* ❤️ */}
                                                                        </div>
                                                                        <div className="review-date">
                                                                            {
                                                                                moment(value.date).format('MMM. DD, YYYY')
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {/* </InfiniteScroll> */}
                                                    {/* {
                                                        (this.state.counter[this.state.star] !== this.state.allReviews.length && this.state.allReviewCompleted === false) &&
                                                        <button className="btn btn-custom" onClick={this.viewMore.bind(this)}>{this.state.viewMoreBtnText}</button>
                                                    } */}
                                                    {
                                                        (this.state.allReviewCompleted === false) &&
                                                        <button className="btn btn-custom" onClick={this.viewMore.bind(this)}>{this.state.viewMoreBtnText}</button>
                                                    }
                                                    {
                                                        this.state.allReviewCompleted &&
                                                        <label>{this.state.reviewCompletedMsg}</label>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 d-none d-md-block">
                                    <div className="cd-sidebar" id="sidebarClient">
                                        <div className="cd-dr-img text-md-right" id="doctorsImg">
                                            {
                                                (this.state.profile && this.state.profile.doctors) &&
                                                <>
                                                    {
                                                        this.state.profile.doctors.map((doc: any, index: number) => {
                                                            if (index < 3) {
                                                                let url = '';
                                                                let doctorName = doc.fullname.replace("Dr.", "");
                                                                doctorName = doctorName.trim();
                                                                doctorName = doctorName.split(" ");
                                                                const letterOne = doctorName[0] ? doctorName[0].charAt(0) : '';
                                                                const letterTwo = doctorName[1] ? doctorName[1].charAt(0) : '';
                                                                if (doc.photo && doc.photo.name && doc.photo.type) {
                                                                    url = apiConfigs.IMAGE_URL + 'doctor/' + (doc.photo ? doc.photo.name ? doc.photo.name : '' : '') + '-200x200.' +
                                                                        (doc.photo ? doc.photo.type ? doc.photo.type : '' : '');
                                                                } else {
                                                                    url = apiConfigs.DEFAULT_IMAGE_URL_DOCTOR;
                                                                }
                                                                return (
                                                                    <div className="img-box" key={Math.random()}>
                                                                        {
                                                                            url === '' ?
                                                                                <Avatar>{letterOne}{letterTwo}</Avatar>
                                                                                : <img src={url} className="img-fluid" />
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                            return null;
                                                        })
                                                    }
                                                    {
                                                        (this.state.profile.doctors.length - 3 > 0) &&
                                                        <div className="img-box lmo">
                                                            +{this.state.profile.doctors.length - 3}
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </div>
                                        {
                                            (this.state.profile && this.state.profile !== '' && this.state.profile.totalPatient) &&
                                            <div className="patients-no text-md-right"><img src={process.env.PUBLIC_URL + "/images/ic_trending_up_24px.svg"} className="mr-2" />
                                                {this.state.profile.totalPatient ? parseInt(this.state.profile.totalPatient, 10) !== 0 ? this.state.profile.totalPatient + '+' : '0' : '0'} new patients last 30 days
                                                </div>
                                        }{
                                            (this.state.profile && this.state.profile !== '' && !this.state.profile.totalPatient) &&
                                            <div className="patients-no text-md-right"><img src={process.env.PUBLIC_URL + "/images/ic_trending_up_24px.svg"} className="mr-2" />
                                                    0 new patients last 30 days
                                                </div>
                                        }
                                        <button className="btn btn-custom w-100" id="footerBtn" onClick={(e) => { this.setState({ showModal: true }) }}>
                                            <img src={process.env.PUBLIC_URL + "/images/ic_today_24px.svg"} className="mr-2" /> Book an Appointment
                                </button>
                                        <div className="open-hours" id="openHours">
                                            <div className="title-sec">
                                                Practice Open Hours
                                    </div>
                                            <div className="desc-sec">
                                                <ul>
                                                    {
                                                        (this.state.profile && this.state.profile !== '' && this.state.profile.hours) &&
                                                        <>
                                                            {
                                                                (Object.keys(this.state.profile.hours).map((key: any, index) => {
                                                                    return (
                                                                        <li key={Math.random()} className={"" + ((new Date().getDay().toString() === key) ? "active" : '')}>
                                                                            <span>{weekDay[key - 1]}</span>
                                                                            <span>
                                                                                {
                                                                                    timeConvert(this.state.profile.hours[key][0])
                                                                                }
                                                                                &nbsp; - &nbsp;
                                                                                {
                                                                                    timeConvert(this.state.profile.hours[key][1])
                                                                                }
                                                                            </span>
                                                                        </li>
                                                                    );
                                                                }))
                                                            }
                                                        </>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {
                    this.state.showModal === true &&
                    <BookPopUp modalNumber={0} data={this.state.profile} change={this.changeHandler}></BookPopUp>
                }
                {(this.state.isOpen && this.state.profile && this.state.profile !== '' && this.state.profile.photos && this.state.profile.photos.length > 0) && (
                    <div style={{ zIndex: 2000 }}>
                        <Lightbox
                            reactModalStyle={{ zIndex: 2000 }}
                            mainSrc={apiConfigs.IMAGE_URL + 'clinic/' + this.state.profile.photos[this.state.photoIndex].image.name + '-original.' + this.state.profile.photos[this.state.photoIndex].image.type}
                            nextSrc={apiConfigs.IMAGE_URL + 'clinic/' + this.state.profile.photos[(this.state.photoIndex + 1) % this.state.profile.photos.length].image.name + '-original.' + this.state.profile.photos[this.state.photoIndex].image.type}
                            prevSrc={apiConfigs.IMAGE_URL + 'clinic/' + this.state.profile.photos[(this.state.photoIndex + this.state.profile.photos.length - 1) % this.state.profile.photos.length].image.name + '-original.' + this.state.profile.photos[this.state.photoIndex].image.type}
                            onCloseRequest={() => this.setState({ isOpen: false })}
                            onMovePrevRequest={() =>
                                this.setState({
                                    photoIndex: (this.state.photoIndex + this.state.profile.photos.length - 1) % this.state.profile.photos.length,
                                })
                            }
                            onMoveNextRequest={() =>
                                this.setState({
                                    photoIndex: (this.state.photoIndex + 1) % this.state.profile.photos.length,
                                })
                            }
                        />
                    </div>
                )}
            </>
        );
    }
}
export default Results;