import React from 'react';
import { CONSTANT } from '../../constants';
import { IAppState } from '../../store';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { addGoal, removeGoal } from '../../store/formwizard/actions';
import { FormWizardState } from '../../store/formwizard/types';
interface Props {
    addGoal: typeof addGoal;
    removeGoal: typeof removeGoal;
    formwizardState: FormWizardState;
    history: any;
}
class Goals extends React.Component<Props> {
    constructor(props: any) {
        super(props);
    }
    selectGoal(goal: any) {
        const goalFound = this.props.formwizardState.formwizard.goals.findIndex((value) => {
            return value.value === goal.value;
        });
        if (goalFound !== -1) {
            this.props.removeGoal({ goal: goal });
        } else {
            this.props.addGoal({ goal: goal });
        }
    }
    render() {
        return (
            <div className="col-12 col-md-10 goals wizard">
                <div className="row justify-content-center gutter-5">
                    <div className="col-12 text-center">
                        <div className="img-box">
                            <img src={process.env.PUBLIC_URL + "/images/goals.svg"} className="img-fluid" />
                        </div>
                        <div className="txt-box">
                            <h4 className="title">The goals I have for my mouth are...</h4>
                            <div className="sub-title">Select all that apply</div>
                        </div>
                    </div>
                    {
                        CONSTANT.QUIZ.goals.map((goal, index) => {
                            return (
                                <div className="col-lg-2 list-box" key={index + Math.random()}>
                                    <a href="javascript:void(0)" className={"custom-click btn " + (this.props.formwizardState.formwizard.goals.findIndex((value) => { return value.value === goal.value }) !== -1 ? 'active' : '')} onClick={(e) => { this.selectGoal(goal) }}>
                                        <div className="check_corner">
                                            <i className={"far " + (this.props.formwizardState.formwizard.goals.findIndex((value) => { return value.value === goal.value }) !== -1 ? 'fa-check-circle' : 'fa-circle')}></i>
                                        </div>

                                        <div className="img-container">
                                            <img src={process.env.PUBLIC_URL + '/' + goal.img} />
                                        </div>
                                        <div className="content-container">
                                            <h5 className="main-text">{goal.value}</h5>
                                        </div>
                                    </a>
                                </div>
                            )
                        })
                    }
                    <div className="col-12 text-center">
                        <div className="bottom_bar">
                            <button className="btn isvg" onClick={(e) => {
                                this.props.history.goBack();
                            }}><img src={process.env.PUBLIC_URL + "/images/next.svg"} alt="" />back</button>
                            <Link to="/quiz/criteria" className={"btn btn-toggle selected-none " + (this.props.formwizardState.formwizard.goals.length !== 0 ? 'activeBtn' : 'changeTxt')}>{
                                (this.props.formwizardState.formwizard.goals.length === 0 ? "None of the above" : "Continue")
                            }</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: IAppState) => ({
    formwizardState: state.formwizard
});
const mapDispatchToProps = (dispatch: any) => ({
    addGoal: (goal: any) => {
        dispatch(addGoal(goal));
    },
    removeGoal: (goal: any) => {
        dispatch(removeGoal(goal));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(Goals);