import axios from 'axios';
import apiConfigs from '../configs/apiConfigs';
import { attachHeader } from '../utils/apiHandler'
import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";

export const patientLogin = (request:any) => {
    return axios.post(apiConfigs.API_URL + 'patientlogin1/',request ,{
        headers: {
            'xprApp': 'front'
        }
    }).then(response => ({ response }), error => ({ error }));
}

export const patientForgotPassword = (request:any) => {
    return axios.post(apiConfigs.API_URL + 'forgot/',request ,{
        headers: {
            'xprApp': 'front'
        }
    }).then(response => ({ response }), error => ({ error }));
}

export const register = (request:any) => {
    return axios.post(apiConfigs.API_URL + 'register1/',request ,{
        headers: {
            'xprApp': 'front'
        }
    }).then(response => ({ response }), error => ({ error }));
}

//New Api
export const patientCheckLogin = (request:any) => {
    return axios.post(apiConfigs.API_URL + 'checklogin/',request ,{
        headers: {
            'xprApp': 'front'
        }
    }).then(response => ({ response }), error => ({ error }));
}
 // latest -----------
export const getMasterInsuranceList = () => {
    const request: CommonApiOptions = {
        url: apiConfigs.API_URL + 'master/insurance/',
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: {
                xprApp: 'patient'
            },
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const registerUser = (data: any) => {
    const request: CommonApiOptions = {
        url: apiConfigs.API_URL + 'register1/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: {
                xprApp: 'patient'
            },
            withCredentials: true
        }
    };
    return commonApi(request);
}
export const verifyCode = (data: any, patientId: any) => {
    const request: CommonApiOptions = {
        url: apiConfigs.API_URL + "register1/" + patientId + "/",
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: {
                xprApp: 'patient'
            },
            withCredentials: true
        }
    }
    return commonApi(request);
}

export const checkLogin = (data: any) => {
    const request: CommonApiOptions = {
        url: apiConfigs.API_URL + "checklogin/",
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: {
                xprApp: 'patient'
            },
            withCredentials: true
        }
    }
    return commonApi(request);
}

export const patientLoginNew = (data: any) => {
    const request: CommonApiOptions = {
        url: apiConfigs.API_URL + "patientlogin1/",
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: {
                xprApp: 'patient'
            },
            withCredentials: true
        }
    }
    return commonApi(request);
}
export const registerUserGuest = (data: any) => {
    const request: CommonApiOptions = {
        url: apiConfigs.API_URL + 'register1/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: {
                xprApp: 'patient'
            },
            withCredentials: true
        }
    };
    return commonApi(request);
}