import React from 'react';
import { Link } from 'react-router-dom';
import { CONSTANT } from '../../constants';
import Cookies from 'js-cookie';
import apiConfigs from '../../configs/apiConfigs';
import { Dropdown, Modal, Button, Form, Spinner } from 'react-bootstrap';
import LoginModal from '../LoginModal/LoginModal';
import { logoutUser } from '../../utils/firebaseUtils';
import $ from 'jquery';
import OTPModal from '../OTPModal/OTPModal';
interface Props {

}
interface State {
    showModal: boolean;
    userId:any;
	phone:any;
	showOTPModal:boolean;
}
export default class Header extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            showModal: false,userId:'',phone:'',showOTPModal:false
        }
        this.hideModal = this.hideModal.bind(this);
    }
    componentDidMount() {
        // responsive menu
        $('a.target-burger').click(function (e) {
            $('nav.mobile-nav, a.target-burger, body').toggleClass('toggled');
            e.preventDefault();
            $('.mobile-nav .menu li:has(ul)').append('<span class="dsarrow"></span>');

            $('.mobile-nav .sub-menu').slideUp();
            $(".mobile-nav .menu li:has(ul)").find(".dsarrow").click(function () {
                // if(false == $(this).parent().children(".mobile-nav .menu li ul.sub-menu").is(':visible')) {
                //     // $('.mobile-nav .menu li ul.sub-menu').slideUp();
                // }
                $(this).parent().children(".mobile-nav .menu li ul.sub-menu").slideToggle();
            });
        });
    }
    hideModal(hide: boolean) {
        this.setState({ showModal: hide });
    }
    render() {
        let isLogin: any = Cookies.getJSON('patient_cookie');
        console.log('Login', isLogin);
        return (
            <header className="master-header screen bg-transperent">
                <div className="container">
                    <nav className="navbar navbar-expand-sm main-header-menu">
                        <Link className="navbar-brand" to="/">
                            <img src={process.env.PUBLIC_URL + '/images/logo-white.svg'} alt="logo" className="logo-w" />
                            <img src={process.env.PUBLIC_URL + '/images/logo-black.svg'} alt="logo" className="logo-b" />
                        </Link>
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <a className="nav-link" href="/clinic-registration">For Dentists</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/about">About Us</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/how-it-works">How It Works</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/new-patients">New Patients</a>
                            </li>
                        </ul>
                        <ul className="nav navbar-nav ml-auto">
                            <li className="nav-item">
                                <a className="nav-link refer" href="/referral">
                                    Referrals
                                <div className="tooltip right">
                                        <div className="tooltip-arrow"></div>
                                        <div className="tooltip-inner">Get ${CONSTANT.REWARD}</div>
                                    </div>
                                </a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link" href="#">
                                    <img src={process.env.PUBLIC_URL + '/images/promo-code.svg'} />
                                    Promo code?
                                </a>
                            </li> */}
                            <li className="nav-item">
                                {
                                    (isLogin) ?
                                        <Dropdown>
                                            <Dropdown.Toggle className="yes_login" id="dropdown-basic">
                                                {isLogin.xprUserName}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href={apiConfigs.PATIENT_URL + 'appointments'}>Appointments</Dropdown.Item>
                                                <Dropdown.Item href={apiConfigs.PATIENT_URL + 'referrals'}>Referrals</Dropdown.Item>
                                                <Dropdown.Item href={apiConfigs.PATIENT_URL + 'claim-reward'}>Claim your reward</Dropdown.Item>
                                                <Dropdown.Item href={apiConfigs.PATIENT_URL + 'account'}>Account</Dropdown.Item>
                                                <Dropdown.Item href={apiConfigs.PATIENT_URL}>My Coverage</Dropdown.Item>
                                                <Dropdown.Item href="" onClick={(e: any) => {
                                                    Cookies.remove('patient_cookie');
                                                    Cookies.remove('patient_cookie', { domain: apiConfigs.COOKIE_DOMAIN });
                                                    logoutUser();
                                                    this.forceUpdate();
                                                }}>Logout</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        :
                                        <a className="btn btn-custom" href="javascript:void(0);" onClick={(e) => {
                                            this.setState({ showModal: true });
                                        }}>log In</a>
                                }
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="mobile-navigation">
                    <div className="logo-sec">
                        <Link to="/">
                            <img src={process.env.PUBLIC_URL + "/images/logo-black.svg"} alt="logo" />
                        </Link>
                    </div>
                    <a className="target-burger">
                        <ul className="buns">
                            <li className="bun"></li>
                            <li className="bun"></li>
                        </ul>
                    </a>
                    <nav className="mobile-nav">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <a className="nav-link" href="/clinic-registration">For Dentist</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/about">About Us</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/how-it-works">How It Works</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/new-patients">New Patients</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link refer" href="/refferal">
                                    Referrals
                                <div className="tooltip right">
                                        <div className="tooltip-arrow"></div>
                                        <div className="tooltip-inner">Get $99</div>
                                    </div>
                                </a>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link" href="#">
                                    <img src={process.env.PUBLIC_URL + "/images/promo-code.svg"} />
                                    Promo code?
                                </a>
                            </li> */}
                            <li className="nav-item">
                                {
                                    (isLogin) ?
                                        <Dropdown>
                                            <Dropdown.Toggle className="yes_login" id="dropdown-basic">
                                                {isLogin.xprUserName}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href={apiConfigs.PATIENT_URL + 'appointments'}>Appointments</Dropdown.Item>
                                                <Dropdown.Item href={apiConfigs.PATIENT_URL + 'referrals'}>Referrals</Dropdown.Item>
                                                <Dropdown.Item href={apiConfigs.PATIENT_URL + 'claim-reward'}>Claim your reward</Dropdown.Item>
                                                <Dropdown.Item href={apiConfigs.PATIENT_URL + 'account'}>Account</Dropdown.Item>
                                                <Dropdown.Item href={apiConfigs.PATIENT_URL}>My Coverage</Dropdown.Item>
                                                <Dropdown.Item href="" onClick={(e: any) => {
                                                    Cookies.remove('patient_cookie');
                                                    Cookies.remove('patient_cookie', { domain: apiConfigs.COOKIE_DOMAIN });
                                                    logoutUser();
                                                    this.forceUpdate();
                                                }}>Logout</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        :
                                        <a className="btn btn-custom" href="javascript:void(0);" onClick={(e) => {
                                            this.setState({ showModal: true });
                                        }}>log In</a>
                                }
                            </li>
                        </ul>
                    </nav>
                </div>
                <LoginModal 
                    showModal={this.state.showModal} 
                    hideModal={this.hideModal.bind(this)}
                    userNotVerified={(id:any,phone:any)=>{
                        this.setState({userId:id,phone:phone,showModal:false,showOTPModal:true});
                    }}
                ></LoginModal>
                <OTPModal 
					isOTP={this.state.showOTPModal}
					hideModal={()=>{this.setState({showOTPModal:false})}}
					patientMobile={this.state.phone}
                    userId={this.state.userId}
                    otpSuccess={(uniqueId: any) => {
                        this.setState({ showOTPModal: false })
                    }}
				/>
            </header>
        );
    }
}