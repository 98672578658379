import React from 'react';
import { preapreSubmitForm } from '../../utils/global';
import { FormWizardState } from '../../store/formwizard/types';
import { IAppState } from '../../store';
import { connect } from 'react-redux';
import { fetchResult } from '../../services/dentist';
import { Redirect, Link } from 'react-router-dom';
import Main from '../Main/Main';
interface Props {
    formwizardState: FormWizardState;
    history: any;
}
interface State {
    redirectToList: boolean;
    noResults: boolean;
}
class Process extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            redirectToList: false,
            noResults: false
        }
    }
    componentDidMount() {
        console.log(this.props);
        const request = preapreSubmitForm(this.props.formwizardState.formwizard);
        fetchResult(request).then((success: any) => {
            try {
                if (success.response.status === 200) {
                    if (success.response.data.status && success.response.data.status.error === false) {
                        console.log(success.response.data);
                        const matchId = success.response.data.matchId;
                        const matchToken = success.response.data.matchToken;
                        const match = success.response.data.match;
                        const prev = localStorage.getItem('ca.xp.wizard.quiz.request');
                        if (prev !== undefined && prev !== null && prev !== "") {
                            localStorage.setItem('ca.xp.wizard.pre.quiz.request', prev);
                        } else {
                            localStorage.setItem('ca.xp.wizard.pre.quiz.request', "");
                        }
                        localStorage.setItem('ca.xp.wizard.match.pre.id', matchId);
                        localStorage.setItem('ca.xp.wizard.match.results', JSON.stringify(match));
                        localStorage.setItem('ca.xp.wizard.quiz.request', JSON.stringify(request));
                        localStorage.setItem('ca.xp.wizard.results.restore', "false");
                        this.setState({ redirectToList: true, noResults: false });
                    } else {
                        this.setState({ redirectToList: false, noResults: true });
                        const prev = localStorage.getItem('ca.xp.wizard.quiz.request');
                        if (prev !== undefined && prev !== null && prev !== "") {
                            localStorage.setItem('ca.xp.wizard.pre.quiz.request', prev);
                        } else {
                            localStorage.setItem('ca.xp.wizard.pre.quiz.request', "");
                        }
                        localStorage.setItem('ca.xp.wizard.match.pre.id', "");
                        localStorage.setItem('ca.xp.wizard.match.results', "");
                        localStorage.setItem('ca.xp.wizard.quiz.request', JSON.stringify(request));
                        localStorage.setItem('ca.xp.wizard.results.restore', "false");
                    }
                }
            } catch (err) {
                console.log(err);
            }
        }, (err) => {
            console.log(err);
        });
    }
    shouldComponentUpdate(nextProps: any, nextState: any) {
        return true;
    }
    render() {
        if (this.state.redirectToList) {
            return (<Redirect to="/quiz/results"></Redirect>);
        }
        return (
            <>
                <div className="col-sm-8 align-self-center text-center">
                    {
                        this.state.noResults === false ?
                            <>  
                                <div className="frontloader">
                                    <img src={process.env.PUBLIC_URL + "/images/loader-img.gif"} />
                                    {/* <p>We are fetching best matches for you...</p> */}
                                </div>
                            </> :
                            <div className="no-match">
                                <div className="section-title">No results found</div>
                                <p>Unfortunately we are unable to find matches as per your preferences or by location. We recommend you to try with other location.</p>
                                <Link to="/" className="btn btn-custom">Select Location</Link>
                            </div>
                    }
                </div>
            </>
        );
    }
}
const mapStateToProps = (state: IAppState) => ({
    formwizardState: state.formwizard
});
const mapDispatchToProps = (dispatch: any) => ({

});
export default connect(mapStateToProps, mapDispatchToProps)(Process);