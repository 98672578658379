import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { LoginForm } from "../../components/AppointmentSchedulingXcare/AppointmentSchedulingXcare";
import HeaderWhite from "../HeaderWhite/HeaderWhite";
import LeftImg from "../../assets/images/image.png";
import { isLogin } from "../../../../utils/global";

export const LoginPage = () => {
    const location = useLocation();
    const history = useHistory();
    useEffect(() => {
        if(isLogin('patient_cookie')) {
            history.replace('/');
        }
    }, []);
    return (
        <>
            <HeaderWhite location={location}></HeaderWhite>
            <section>
                <div className="main-grid-sec" style={{
                    marginTop: 80
                }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7 order-md-1 order-2">
                                <img width={'100%'} src={LeftImg}></img>
                            </div>
                            <div className="col-md-5 order-md-2 order-1">
                                <div className="practice-sidebar">
                                    <LoginForm
                                        noBackToHome
                                        onLoginSuccess={() => {
                                            window.location.href = '/';
                                        }}
                                        noGuestLogin
                                    ></LoginForm>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};