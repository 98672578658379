import React from 'react';
import { CONSTANT } from '../../constants';
import { IAppState } from '../../store';
import { connect } from 'react-redux';
import { addReason, setPercentage } from '../../store/formwizard/actions';
import { FormWizardState } from '../../store/formwizard/types';
interface Props {
    formwizardState: FormWizardState;
    addReason: typeof addReason;
    history: any;
    setPercentage: typeof setPercentage;
}
class Reason extends React.Component<Props> {
    constructor(props: any) {
        super(props);
    }
    selectReason(reason: any) {
        this.props.addReason({ reason: reason });
        this.props.setPercentage(30);
        this.props.history.push(reason.link);
    }
    render() {
        const selectedReason: any = this.props.formwizardState.formwizard.reason;
        return (
            <div className="col-12 col-md-10 wizard">
                <div className="row justify-content-center gutter-5">
                    <div className="col-12">
                        <div className="txt-box">
                            <h4 className="title mb-15">Why are you visiting the dentist?</h4>
                            <div className="sub-title">Your choice helps us get you the best care - even for checkups!</div>
                        </div>
                    </div>
                    {
                        CONSTANT.QUIZ.dentistReasons.map((reason, index) => {
                            return (
                                <div className="col-lg-4 list-box" key={index + Math.random()}>
                                    <button className={"custom-click btn " + ((selectedReason && selectedReason !== "" && selectedReason.value === reason.value) ? 'active' : '')} onClick={(e) => { this.selectReason(reason) }}>
                                        <div className="img-container">
                                            <img src={process.env.PUBLIC_URL + '/' + reason.img} />
                                        </div>
                                        <div className="content-container">
                                            <h5 className="main-text">{reason.value}</h5>
                                            <div className="sub-text">{reason.label}</div>
                                        </div>
                                    </button>
                                </div>
                            );
                        })
                    }
                </div>
            </div>

        );
    }
}
const mapStateToProps = (state: IAppState) => ({
    formwizardState: state.formwizard
});
const mapDispatchToProps = (dispatch: any) => ({
    addReason: (reason: any) => {
        dispatch(addReason(reason));
    },
    setPercentage: (percent: number) => {
        dispatch(setPercentage(percent));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(Reason);