import axios from 'axios';
import React, { useState ,useEffect} from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import PinInput from 'react-pin-input';
import { toast } from 'react-toastify';
import configs from '../../../../configs/apiConfigs';
import { verifyCode } from '../../services/auth';
export const OTPModal = ({ hideModal, sentPhone, isLoading, setIsLoading, patientId, codeVerifySuccessLogin }: any) => {
    const [verificationCode, setVerificationCode] = useState('');
    const [verificationCodeFalse, setVerificationCodeFalse] = useState(false);
    const [seconds, setSeconds] = React.useState(30);
    const verifyCodeFunc = () => {
        if (sentPhone && patientId) {
            setIsLoading(true);
            verifyCode({ code: verificationCode }, patientId).then((success) => {
                setIsLoading(false);
                if (success && success.status && success.status.error === false) {
                    codeVerifySuccessLogin(success);
                    hideModal();
                    return;
                }
                setVerificationCodeFalse(true);
            }).catch((err) => {
                setIsLoading(false);
                setVerificationCodeFalse(true);
                console.log(err);
            })
        }
    }
    const sendVerificationCode = () => {
        if (sentPhone && patientId) {
            setIsLoading(true);
            axios.post(configs.API_URL + 'signup/' + patientId + '/resend/', {
                phone: sentPhone
            }).then((success) => {
                setIsLoading(false);
                if (success.status === 200) {
                    if (success.data.status.error === false) {
                        toast.success(success.data.status.msg);
                        setVerificationCodeFalse(false);
                    } else {
                        toast.error(success.data.status.msg);
                    }
                }
            }, (error) => {
                setIsLoading(false);
                toast.error('Something went wrong');
            });
        }
    }
      useEffect(() => {
        // intervalChange();
        if (seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1), 1000);
          } else {
            setSeconds(0);
          }
    });
    return (
        <Modal centered className="master_popup public_popup loginPopup" show={true} onHide={() => {
            hideModal();
        }}>
            <Modal.Header closeButton>
                <Modal.Title>OTP</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="popup-form text-center">
                <div className="popup-form text-center">
                    <p>We have sent a verification code to <span>{sentPhone}</span></p>
                    <div className="form-txt">Enter code here</div>
                        <PinInput
                            length={4}
                            initialValue={""}
                            onChange={(value, index) => {
                                setVerificationCode(value);
                            }}
                            type="numeric"
                            style={{ padding: '10px' }}
                            inputStyle={{}}
                            inputFocusStyle={{ borderColor: 'blue' }}
                            onComplete={(value, index) => {
                                setVerificationCode(value);
                            }}
                        />
                        {
                            verificationCodeFalse &&
                            <div className="text-danger error">Verification code entered is incorrect.</div>
                        }
                    <button className="btn btn-custom send-btn"
                        onClick={(e) => { verifyCodeFunc() }}
                        disabled={verificationCode.length !== 4 || isLoading}>
                        <img src={require('../../assets/images/ic_arrow_upward_24px.svg')} />
                        </button>
                        {
                            seconds !== 0 &&
                            <div className="resend-txt">Didn't received code? Resend after {seconds} seconds.</div>
                        }
                    { seconds === 0 &&
                        <button className="btn resend-btn" onClick={(e) => { sendVerificationCode() }}>
                            Resend Code
                            {
                                isLoading &&
                                <span className="aft_load right">
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </span>
                            }
                        </button>
                    }
                </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}