import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { NavLink } from 'react-router-dom';
import { CONSTANT } from '../../constants';
import { FormWizardState } from '../../store/formwizard/types';
import { addHowSoon, setPercentage } from '../../store/formwizard/actions';
interface Props {
    formwizardState: FormWizardState;
    addHowSoon: typeof addHowSoon;
    history: any;
    setPercentage: typeof setPercentage;
}
class HowSoon extends React.Component<Props> {
    constructor(props: any) {
        super(props);
    }
    selectPreference(howsoon: any) {
        this.props.addHowSoon({ howsoon: howsoon });
        this.props.setPercentage(50);
        this.props.history.push(howsoon.link);
    }
    render() {
        const selectedPreference: any = this.props.formwizardState.formwizard.howSoon;
        return (
            <div className="col-12 col-md-10 wizard preferance">
                <div className="row  gutter-5">
                    <div className="col-12">                        
                        <div className="txt-box">
                            <h4 className="title mb-15">How soon would you like to visit the dentist?</h4>
                            <div className="sub-title">We'll try to connect you with a dentist who has appointments in your time range.</div>
                        </div>
                    </div>
                    {
                        CONSTANT.QUIZ.howSoon.map((preferance) => {
                            return (
                                <div className="col-lg-3 list-box" key={Math.random()}>
                                    <button
                                        className={"custom-click btn " + ((selectedPreference && selectedPreference !== "" && selectedPreference.value === preferance.value) ? 'active' : '')}
                                        onClick={(e) => { this.selectPreference(preferance) }}>
                                        <div className="content-container">
                                            <h5 className="main-text align-self-center">{preferance.value}</h5>
                                        </div>
                                    </button>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

        );
    }
}
const mapStateToProps = (state: IAppState) => ({
    formwizardState: state.formwizard
});
const mapDispatchToProps = (dispatch: any) => ({
    addHowSoon: (howsoon: any) => {
        dispatch(addHowSoon(howsoon));
    },
    setPercentage: (percent: number) => {
        dispatch(setPercentage(percent));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(HowSoon);