import React, { useEffect, useState, useRef } from 'react';
import '../../assets/css/clinic-search.scss';
import '../../assets/css/provider-clinic-list.scss';
import HeaderWhite from '../../pages/HeaderWhite/HeaderWhite';
import { getSpeciality } from '../../../../services/landingPageSearch';
import { toast } from 'react-toastify';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { PaymentMethodModal } from '../../components/LandingPage/PaymentMethodModal';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { addFilterData } from '../../../../store/landingPageSearch/actions';
import { prepareAddress } from '../../../../utils/googleFunction';
import { addFilterItems, addLanguage } from '../../../../store/filters/actions';
import { IAppState } from '../../../../store';
import { addSlotData } from '../../../../store/slot/actions';
import { addClinicProviderList } from '../../../../store/clinicAndProviderList/actions';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../../store/loadingIndicator/actions';
import { useHistory } from 'react-router-dom';
import { searchFilter } from '../../../../store/v2/searchFilter/actions';
export const LandingPage = (props: any) => {
    let autcompleteRef: any = useRef();
    let autocomplete: any;
    const [date, setDate] = useState(null as any);
    const [specialities, setSpecialities] = useState([]);
    const [selectedSpeciality, setselectedSpeciality] = useState('');
    const [location, setLocation]: any = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [userLocation, setUserLocation] = useState({ latitude: '', longitude: '' });
    const dispatch = useDispatch();
    const [iFram, setiFram] = useState(false);
    const history: any = useHistory();
    const clinicProviderList: any = useSelector((state: IAppState | any) => state.clinicProviderList.clinicProviderList);

    useEffect(() => {
        getSpecialityApi();
        onLoadGetPlace();
        getMyLocation(false);
        if (new URLSearchParams(history.location.search).get('iframe') === "yes") {
            setiFram(true);
        }
        // from clinic or provider profile
        if (history && history.location && history.location.state && history.location.state.iFrame) {
            setiFram(true);
        }

    }, []);
    const onLoadGetPlace = () => {
        if (!document.getElementById('googlePlaceScript')) {
            const googleScript = document.createElement('script');
            googleScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDIDMklRxHmFYWU8Vvo1P-dVkB_nbHARj8&libraries=places`
            googleScript.id = "googlePlaceScript";
            window.document.body.appendChild(googleScript);
            googleScript.addEventListener('load', () => {
                try {
                    //@ts-ignore
                    autocomplete = new window.google.maps.places.Autocomplete(
                        autcompleteRef.current,
                        {
                            types: ['(regions)'],
                        }
                    );
                    autocomplete.addListener('place_changed', () => {
                        const place = autocomplete.getPlace();
                        setLocation(place);
                    });
                } catch (err) {
                    console.log(err);
                }
            });
        } else {
            try {
                //@ts-ignore
                autocomplete = new window.google.maps.places.Autocomplete(
                    autcompleteRef.current,
                    {
                        types: ['(regions)'],
                    }
                );
                autocomplete.addListener('place_changed', () => {
                    const place = autocomplete.getPlace();
                    setLocation(place);
                });
            } catch (err) {
                console.log(err);
            }
        }
    }
    const getSpecialityApi = () => {
        getSpeciality().then((success: any) => {
            try {
                if (success && success.response && success.response.data && success.response.status === 200) {
                    if (success.response.data && success.response.data.status.error === false) {
                        dispatch(fetchLoadingSuccess(false));
                        setSpecialities(success.response.data.specialities);
                    } else {
                        if (success.response.data && success.response.data.status.error === true) {
                            toast.error(success.response.data.status.msg);
                        }
                        dispatch(fetchLoadingSuccess(false));
                    }
                }
            } catch (error) {
                console.log('error', error);
                dispatch(fetchLoadingSuccess(false));
            }
        });
    }
    const handleSpeciality = (value: any) => {
        if (value) {
            setselectedSpeciality(value);
        }
    }
    const handleChange = (date: any) => {
        setDate(date);
    };
    const clickSearch = () => {
        if (selectedSpeciality === '' || selectedSpeciality === 'Select Speciality') {
            toast.warn('Please select speciality.');
            return;
        }
        setShowModal(true);
    }
    const getMyLocation = (isNearMe: boolean) => {
        const location = window.navigator && window.navigator.geolocation
        if (location) {
            location.getCurrentPosition((position: any) => {
                setUserLocation(
                    {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    }
                );
                if (isNearMe) {
                    setShowModal(true);
                } else {
                    setUserLocation({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    }
                    );
                }
            }, (error) => {

            });
        }
    }
    return (
        <>
            {
                !iFram &&
                <HeaderWhite></HeaderWhite>
            }
            <section className="clinic-search-sec">
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-12 align-self-center mx-auto text-center">
                            <div className="txt-section">
                                <div className="subtitle mb-3 d-flex justify-content-center">
                                    <div className="mr-2"><img src={require("../../assets/images/top-rated-group.svg")} /></div>
                                    Delivering top rated healthcare providers
                                </div>
                                <h1>Find Trusted <strong>Providers</strong> With Just A Few Clicks</h1>
                                <p>Instant appointments with top rated healthcare provider in your area.</p>
                                <div className="address-section d-flex">
                                    <select className="select-spec form-control" id="exampleFormControlSelect2" value={selectedSpeciality}
                                        onChange={(e) => { handleSpeciality(e.target.value) }}>
                                        <option key={'Select Speciality'} value="Select Speciality">Select Speciality</option>
                                        {
                                            specialities && specialities.length > 0 &&
                                            specialities.map((item: any, index: number) => {
                                                return (
                                                    <option key={index + Math.random() + 'speciality'} value={item.id}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <input type="text" id="autocomplete"
                                        value={location ? location.formatted_address : location}
                                        onChange={(e) => {
                                            setLocation(e.target.value);
                                        }}
                                        ref={autcompleteRef} placeholder="City or postal code" className="select-loc" />
                                    <KeyboardDatePicker
                                        className="select-date"
                                        placeholder={'Select date'}
                                        id="date-picker-dialog"
                                        format="YYYY-MM-DD"
                                        minDate={new Date()}
                                        value={date}
                                        onChange={(date) => { handleChange(date) }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                    <button disabled={(!location && !selectedSpeciality && !date)} onClick={() => { clickSearch() }} className="location-btn" data-toggle="modal" data-target="#exampleModal"><img src={require("../../assets/images/search-btn-home.svg")} /></button>
                                </div>
                                {/* <button className="location-title btn mx-auto p-0" onClick={() => { getMyLocation(true) }}>
                                    <img src={require("../../assets/images/ic_my_location_24px.svg")} alt="image" />
                                    Near me
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {
                (showModal) &&
                <PaymentMethodModal
                    isShow={showModal}
                    onHide={() => { setShowModal(false) }}
                    onClickSave={(selectedPaymentMethod: any, selectedValue: any) => {
                        dispatch(searchFilter({
                            oldSpeciality: selectedSpeciality,
                            newSpeciality: selectedSpeciality,
                            location: location ? prepareAddress(location) : userLocation,
                            date: date !== null ? moment(date).format('YYYY-MM-DD') : date,
                            howToPay: { type: selectedPaymentMethod, value: selectedValue },
                            gender: '',
                            distance: '',
                            languages: '',
                            clinicId: '',
                            providerId: '',
                            patientUniqueId: '',
                            page: 1,
                            clinicPage: 1,
                            results: 0,
                        }));
                        dispatch(addClinicProviderList({
                            ...clinicProviderList,
                            clinicList: [],
                            providerList: [],
                            providerTotalCount: 0,
                            clinicTotalCount: 0,
                        }));
                        dispatch(addSlotData({ slot: [] }));
                        props.history.push({ pathname: '/list', state: { iFrame: iFram } })
                    }}
                />

            }
        </>
    )
}