import React from 'react';
import './PopUpStyle.css';
import apiConfigs from '../../configs/apiConfigs';
import { emailValidation } from '../../utils/validators';
import { fetchPatientRegister } from '../../services/results';
import Cookies from 'js-cookie';
interface Props {
    showNextPopUp: (emailAddress: any, userRegistrationResponse: any) => void;
    closePopUp: () => void;
    doctor: any;
}
interface State {
    emailAddress: string;
    btnDisabledContinue: boolean;
    alreadyPresent: boolean;
    isLoginData: any;
    loading: boolean;
}
class PopUpThree extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            emailAddress: '',
            btnDisabledContinue: true,
            alreadyPresent: false,
            isLoginData: '',
            loading: false
        }
    }
    componentDidMount() {
        let isLogin: any = Cookies.getJSON('patient_cookie');
        if (isLogin) {
            this.setState({ alreadyPresent: true, isLoginData: isLogin });
        }
    }
    continueClick = (emailAddress: any, userRegistrationResponse: any) => {
        this.props.showNextPopUp(emailAddress, userRegistrationResponse);
    }
    closePopUp = () => {
        this.props.closePopUp();
    }
    checkEmailAddress = () => {
        this.setState({ loading: true });
        fetchPatientRegister({ email: this.state.emailAddress }).then((success) => {
            this.setState({ loading: false });
            this.continueClick(this.state.emailAddress, success);
        }).catch((error) => {
            this.setState({ loading: false });
        });
    }
    onYesClick() {
        this.setState({ loading: true });
        fetchPatientRegister({ email: this.state.isLoginData.xprEmail }).then((success) => {
            this.setState({ loading: false });
            this.continueClick(this.state.isLoginData.xprEmail, success);
        }).catch((error) => {
            this.setState({ loading: false });
        });
    }
    onNoClick() {
        this.setState({ alreadyPresent: false });
    }
    render() {
        return (

            <div className="step-3">
                <div className="modal-header">
                    <button className="btn book-close-btn" onClick={(e) => { this.closePopUp(); }}>
                        <img src={process.env.PUBLIC_URL + "/images/close-icon-white.svg"} className="img-fluid" />
                    </button>

                    <div className="media">
                        <div className="media-img mr-3 img-box">
                            {
                                (this.props.doctor.photo && this.props.doctor.photo.name && this.props.doctor.photo.type) ?
                                    <img src={apiConfigs.IMAGE_URL + '/doctor/' + this.props.doctor.photo.name + '-200x200.' + this.props.doctor.photo.type} className="img-fluid" /> :
                                    <img src={apiConfigs.DEFAULT_IMAGE_URL_DOCTOR} className="img-fluid" />
                            }
                        </div>
                        <div className="media-body align-self-end">
                            <div>Book your appointment with</div>
                            <div className="p-name">{this.props.doctor.fullname}</div>
                        </div>
                    </div>

                </div>

                <div className="modal-body">
                    <div className="publp_form">
                        {
                            (this.state.alreadyPresent === false) ?
                                <>
                                    <div className="publp_title">
                                        <h6>Please enter your contact information</h6>
                                    </div>
                                    <div className="form-group">
                                        <label>Email Address</label>
                                        <input type="email" className="form-control" value={this.state.emailAddress} onChange={(e) => {
                                            if (emailValidation(e.target.value)) {
                                                this.setState({ btnDisabledContinue: false });
                                            } else {
                                                this.setState({ btnDisabledContinue: true });
                                            }
                                            this.setState({ emailAddress: e.target.value });
                                        }} />
                                    </div>

                                    <div className="form-group d-flex pop_btnsec">
                                        <button
                                            disabled={this.state.btnDisabledContinue || this.state.loading}
                                            className="btn btn-custom w-100" onClick={(e) => {
                                                this.checkEmailAddress();
                                            }}>Continue</button>
                                    </div>
                                    {
                                        this.state.loading &&
                                        <div className="text-center cs_popload"><i className="fas fa-circle-notch fa-spin"></i></div>
                                    }
                                    <div className="note-txt">By submitting your contact information, you agree to our <a href="">Terms of Service</a> and <a href="">Privacy Policy</a> and consent to having Xcarepro contact you by text message and email.</div>

                                </> :
                                <>

                                    <div className="publp_title">
                                        <h6>Hi {this.state.isLoginData.xprUserName}</h6>
                                    </div>

                                    <div className="row justify-content-center">
                                        <div className="col-8">
                                            <div className="lginfo_dom text-center">
                                                <p>You are currently logged In with {this.state.isLoginData.xprEmail} on XcarePro.</p>
                                                <p>Are you want to continue?</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group d-flex pop_btnsec">
                                        <button className="btn btn-custom" onClick={() => { this.onYesClick(); }} disabled={this.state.loading}>Yes</button>
                                        <button className="btn btn-custom" onClick={() => { this.onNoClick(); }} disabled={this.state.loading}>No</button>
                                    </div>
                                    {
                                        this.state.loading &&
                                        <div className="text-center cs_popload"><i className="fas fa-circle-notch fa-spin"></i></div>
                                    }

                                    <div className="note-txt">By submitting your contact information, you agree to our <a href="">Terms of Service</a> and <a href="">Privacy Policy</a> and consent to having Xcarepro contact you by text message and email.</div>


                                </>
                        }



                    </div>

                </div>
            </div>

        )
    }
}
export default PopUpThree;