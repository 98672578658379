import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Animated } from 'react-animated-css';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import configs from '../../../../configs/apiConfigs';
import { LABELS, TITLES_LABELS } from '../../constants';
import { checkLogin, patientLoginNew } from '../../services/auth';
import { signInWithCustomToken } from '../../../../utils/firebaseUtils';
import { emailValidation } from '../../../../utils/validators';
import { Modal, Spinner } from 'react-bootstrap';
import { OTPModal } from './Otp';
import { addClinicProviderList } from '../../../../store/clinicAndProviderList/actions';
import { IAppState } from '../../../../store';
import { Register } from '../Auth/Register';
import { ForgotPassword } from './forgotpassword/forgotpassword';
import { ForgotPasswordOtp } from './forgotpassword/forgotpasswordotp';
import { ResetPassword } from './forgotpassword/resetpassword';
import { GuestSession } from './guestSession/GuestSession';
export const Login = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const { register, setError, handleSubmit, setValue, getValues, errors, trigger } = useForm();
    const [askFor, setAskFor] = useState("");
    const [isShowOTPModal, setIsShowOTPModal] = useState(false);
    const [patientId, setPatientId] = useState();
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();
    const dispatch = useDispatch();
    const [isLogin, setIsLogin] = useState(props.isVisible)
    const clinicProviderList: any = useSelector((state: IAppState | any) => state.clinicProviderList.clinicProviderList);
    const [iFram, setiFram] = useState(false);
    const history: any = useHistory();
    const [isRegister, setIsRegister] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [showForgotPasswordOtp, setShowForgotPasswordOtp] = useState(false);
    const [showForgotPasswordReset, setShowForgotPasswordReset] = useState(false);
    const [patientOtpId, setPatientOtpId] = useState("");
    const [patientIdReset, setPatientPatientIdReset] = useState("");
    const [frgtObj, setFrgtObj] = useState(null);


    useEffect(() => {
        if (new URLSearchParams(history.location.search).get('iframe') === "yes") {
            setiFram(true);
        }
        // from clinic or provider profile
        if (history && history.location && history.location.state && history.location.state.iFrame) {
            setiFram(true);
        }
    }, []);
    const onLoginClick = (data: any) => {
        if (getValues('password')) {
            let requestData: any = {
                password: getValues('password')
            };
            if (isNaN(getValues('emailorphone'))) {
                requestData = {
                    ...requestData,
                    email: getValues('emailorphone'), phone: getValues('phone')
                }
            } else {
                requestData = {
                    ...requestData,
                    phone: getValues('emailorphone'), email: getValues('email')
                };
            }
            setIsLoading(true);
            patientLoginNew(requestData).then((success) => {
                setIsLoading(false);
                if (success && success.status && success.status.error === false) {
                    if (success.phone !== null && success.phoneVerified === null && success.email !== null && success.emailVerified === null) {
                        setPhone(success.phone);
                        setEmail(success.email);
                        setPatientId(success.id);
                        setIsShowOTPModal(true);
                        return;
                    } else if (success.phone !== null && success.phoneVerified === null) {
                        setPhone(success.phone);
                        setPatientId(success.id);
                        setIsShowOTPModal(true);
                        return;
                    } else if (success.email !== null && success.emailVerified === null) {
                        setEmail(success.email);
                        setPatientId(success.id);
                        setIsShowOTPModal(true);
                        return;
                    }
                    postLoginAction(success);
                }
            }).catch((err) => {
                setIsLoading(false);
                setError('password', { type: 'custom', message: err.msg });
                console.log(err);
            });
        }
    }
    const emailOrPhoneBlur = async (e: any) => {
        try {
            const isValid = await trigger('emailorphone');
            if (isValid) {
                if (isNaN(getValues('emailorphone'))) {
                    if (!emailValidation(getValues('emailorphone'))) {
                        setError('emailorphone', { type: 'custom', message: 'Invalid email' });
                        return;
                    }
                } else {
                    if (getValues('emailorphone').length > 10 || getValues('emailorphone').length < 10) {
                        setError('emailorphone', { type: 'custom', message: 'Invalid phone' });
                        return;
                    }
                }
                setIsLoading(true);
                checkLogin({ login: getValues('emailorphone') }).then((success) => {
                    setIsLoading(false);
                    if (success && success.status && success.status.error === false) {
                        setAskFor(success.askFor);
                    }
                }).catch((err) => {
                    setIsLoading(false);
                    console.log(err);
                    setError('emailorphone', { type: 'custom', message: err.msg });
                });
            }
        } catch (err) {
            console.log(err);
        }
    }
    const postLoginAction = (data: any) => {
        setIsLoading(true);
        signInWithCustomToken(data.jwtToken).then((response) => {
            if (data.insurance && data.iPlan) {
                dispatch(addClinicProviderList({
                    ...clinicProviderList,
                    primaryInsurance: {
                        insurancePlan: data.insurance.name,
                        insurencePlanName: data.iPlan.name
                    }
                }));
            }
            setIsLoading(false);
            if (response) {
                const key1 = 'xprUser.patient-' + data.id;
                const key2 = 'xpr_user_id';
                const key3 = 'xprAuthUser';
                const key4 = 'xprUserName';
                const key5 = 'jwtToken';
                const key6 = 'xprIsGuarantor';
                var json: any = {};
                json[key1] = true;
                json[key2] = data.uniqueId;
                json[key3] = data.authParam;
                json[key4] = data.firstName + " " + data.lastName;
                json[key5] = data.jwtToken;
                json[key6] = data.isGuarantor;
                Cookies.remove('patient_cookie');
                Cookies.remove('patient_cookie', { domain: configs.COOKIE_DOMAIN });
                Cookies.set('patient_cookie', json, { domain: configs.COOKIE_DOMAIN });
                Cookies.set('patient_cookie', json);
                // history.replace('/');
                if (props.from && props.from === 'AppointmentBook') {
                    props.onLoginSuccess();
                } else {
                    props.hideModal();
                }
            } else {
                toast.error('Something went wrong');
            }
        }).catch((error) => {
            setIsLoading(false);
            toast.error('Something went wrong');
        });
    }
    const hideRegisterModal = () => {
        setIsRegister(false);
        props.hideModal();
    }
    const handleOnForgotPasswordSuccess = (otpId: any, frgtObj: any) => {
        console.log(frgtObj);
        setShowForgotPassword(false);
        setShowForgotPasswordOtp(true);
        setPatientOtpId(otpId);
        setFrgtObj(frgtObj);
    }
    const handleOnForgotPasswordOtpSuccess = (patientId: any) => {
        setShowForgotPasswordOtp(false);
        setShowForgotPasswordReset(true);
        setPatientPatientIdReset(patientId);
    }
    const handleOnForgotPasswordResetSuccess = () => {
        props.hideModal();
        setShowForgotPasswordReset(false);
    }

    return (
        <>
            <Modal className="master_popup public_popup loginPopup" tabIndex={1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" show={isLogin} onHide={props.hideModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{TITLES_LABELS.LOGIN.PATIENT_LOGIN}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label className="label-form">{LABELS.EMAILORPHONE}</label>
                        <input type="text" disabled={isLoading} className="form-control" name="emailorphone" ref={register({ required: true })} onBlur={emailOrPhoneBlur} />
                        {
                            (errors && errors.emailorphone && errors.emailorphone.type === 'required') &&
                            <div className="text-danger error">Email or phone is required.</div>
                        }
                        {
                            (errors && errors.emailorphone && errors.emailorphone.type === 'custom') &&
                            <div className="text-danger error">{errors.emailorphone.message}</div>
                        }
                    </div>
                    {
                        askFor === "email" &&
                        <div className="form-group">
                            <p>
                                We have detected multiple accounts with this mobile number, please enter mobile number associated with this account to sign in.
                                In case if you do not have mobile number associated with this account please enter password directly to sign in
                            </p>
                            <label className="label-form">{LABELS.EMAIL}</label>
                            <input type="text" disabled={isLoading} className="form-control" name="email" ref={register({
                                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            })} />
                            {
                                (errors && errors.email && errors.email.type === 'pattern') &&
                                <div className="text-danger error">Email is invalid</div>
                            }
                        </div>
                    }
                    {
                        askFor === "phone" &&
                        <div className="form-group">
                            <p>
                                We have detected multiple accounts with this email, please enter email associated with this account to sign in.
                                In case if you do not have email associated with this account please enter password directly to sign in
                            </p>
                            <label className="label-form">{LABELS.PHONE}</label>
                            <input type="text" disabled={isLoading} className="form-control" name="phone" ref={register({
                                maxLength: 10, minLength: 10
                            })} />
                            {
                                (errors && errors.phone && errors.phone.type === 'maxLength') &&
                                <div className="text-danger error">Mobile number is invalid</div>
                            }
                            {
                                (errors && errors.phone && errors.phone.type === 'minLength') &&
                                <div className="text-danger error">Mobile number is invalid</div>
                            }
                        </div>
                    }
                    {
                        (askFor === "password" || askFor === "email" || askFor === "phone") &&
                        <div className="form-group">
                            <label className="label-form">{LABELS.PASSWORD}</label>
                            <input type="password" disabled={isLoading} className="form-control" name={'password'} ref={register({ required: true })} />
                            {
                                (errors && errors.password && errors.password.type === 'required') &&
                                <div className="text-danger error">Password is required.</div>
                            }
                            {
                                (errors && errors.password && errors.password.type === 'custom') &&
                                <div className="text-danger error">{errors.password.message}</div>
                            }
                        </div>
                    }


                    <div className="d-flex justify-content-between">
                        <div>
                            <button type="button" className="btn btn-dark" onClick={handleSubmit(onLoginClick)}>
                                <span className="aft_load right">
                                    <>
                                        {
                                            isLoading === true &&
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        }
                                    </>
                                </span>
                                {LABELS.BUTTON.SIGNIN}
                            </button>
                        </div>

                        <div className="text-right">
                            <div>
                                <a href="javascript:void(0)" onClick={(e) => {
                                    setIsLogin(false);
                                    setShowForgotPassword(true);
                                }}>{TITLES_LABELS.LINKS.FORGOT_PASSWORD}</a>
                            </div>
                            <div>
                                {
                                    // (props.from && props.from !== 'AppointmentBook') &&
                                    (!iFram) && !['app.xcarepro.com', 'app.us.xcarepro.com'].includes(window.location.hostname) &&
                                    <a href="javascript:void(0)" onClick={(e) => {
                                        setIsRegister(true);
                                        setIsLogin(false);
                                    }}>{TITLES_LABELS.LINKS.REGISTER}</a>
                                }
                            </div>
                        </div>
                    </div>

                    <hr />
                    <div className="text-center my-3">
                        Not ready to sign up yet !!!
                    </div>
                    <div className="text-center mb-3">
                        <button className="btn btn-dark" onClick={() => {
                            props.continueAsGuest()
                        }}>Proceed as Guest</button>
                    </div>

                    <div className="text-center">
                        You can decide to sign up at the end of the appointment booking process if desired
                    </div>


                </Modal.Body>
            </Modal>
            {
                isShowOTPModal &&
                <OTPModal hideModal={() => {
                    setIsShowOTPModal(false);
                    props.hideModal();
                }}
                    sentEmail={email}
                    sentPhone={phone}
                    patientId={patientId}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    codeVerifySuccessLogin={postLoginAction}
                ></OTPModal>
            }
            {
                isRegister &&
                <Register
                    isRegister={isRegister}
                    hideModal={hideRegisterModal}
                    onRegisterSuccess={() => {
                        props.onLoginSuccess();
                    }}
                    clickBackToSignIn={() => {
                        setIsRegister(false);
                        setIsLogin(true);
                    }}
                />
            }

            {
                showForgotPassword &&
                <ForgotPassword
                    hideModal={() => {
                        props.hideModal();
                        setShowForgotPassword(false);
                    }}
                    onSuccess={handleOnForgotPasswordSuccess}
                    backToSignin={() => {
                        setShowForgotPassword(false);
                        setIsLogin(true);
                    }}
                    backToRegister={() => {
                        setShowForgotPassword(false);
                        setIsRegister(true);
                    }}
                ></ForgotPassword>
            }

            {
                showForgotPasswordOtp && patientOtpId !== "" &&
                <ForgotPasswordOtp
                    frgtObj={frgtObj}
                    patientOtpId={patientOtpId}
                    hideModal={() => {
                        props.hideModal();
                        setShowForgotPasswordOtp(false);
                    }}
                    onSuccess={handleOnForgotPasswordOtpSuccess}
                ></ForgotPasswordOtp>
            }

            {
                showForgotPasswordReset && patientIdReset !== "" &&
                <ResetPassword
                    patientIdReset={patientIdReset}
                    hideModal={() => {
                        props.hideModal();
                        setShowForgotPasswordReset(false);
                    }}
                    onSuccess={handleOnForgotPasswordResetSuccess}
                ></ResetPassword>
            }
        </>
    )
}