import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { NavLink, Link } from 'react-router-dom';
import { CONSTANT } from '../../constants';
import { addAvailability, removeAvailability } from '../../store/formwizard/actions';
import { FormWizardState } from '../../store/formwizard/types';
interface Props {
    addAvailability: typeof addAvailability;
    removeAvailability: typeof removeAvailability;
    formwizardState: FormWizardState;
    history: any;
}
class Availability extends React.Component<Props> {
    constructor(props: any) {
        super(props);
    }
    selectAvailability(availability: any) {
        const availabilityFound = this.props.formwizardState.formwizard.availabilities.findIndex((value) => {
            return value.value === availability.value;
        });
        if (availabilityFound !== -1) {
            this.props.removeAvailability({ availability: availability });
        } else {
            this.props.addAvailability({ availability: availability });
        }
    }
    render() {
        return (
            <div className="col-12 col-md-10 available-time wizard">
                <div className="row  gutter-5">
                    <div className="col-12 ">
                      
                        <div className="txt-box">
                            <h4 className="title">When is the best time of day for your visit?</h4>
                            <div className="sub-title">Choose all of the times that work for you.</div>
                        </div>
                    </div>
                    {
                        CONSTANT.QUIZ.availabilities.map((availability) => {
                            return (
                                <div className="col-lg-3 list-box" key={Math.random()}>
                                    <a href="javascript:void(0);"
                                        className={"custom-click btn " + (this.props.formwizardState.formwizard.availabilities.findIndex((value) => { return value.value === availability.value }) !== -1 ? 'active' : '')}
                                        onClick={(e) => { this.selectAvailability(availability) }}
                                    >

                                        <div className="check_corner">
                                            <i className={"far " + (this.props.formwizardState.formwizard.availabilities.findIndex((value) => { return value.value === availability.value }) !== -1 ? 'fa-check-circle' : 'fa-circle')}></i>
                                        </div>

                                        <div className="img-container">
                                            <img src={process.env.PUBLIC_URL + "/" + availability.img} />
                                        </div>
                                        <div className="content-container">
                                            <h5 className="main-text">{availability.value}</h5>
                                            <div className="sub-text">{availability.time}</div>
                                        </div>
                                    </a>
                                </div>
                            )
                        })
                    }
                    <div className="col-12">
                        <div className="bottom_bar justify-content-between d-flex">
                            <button className="btn isvg btn-back" onClick={(e) => {
                                this.props.history.goBack();
                            }}>back</button>
                            <Link to="/quiz/how-soon" className={"btn btn-toggle selected-none btn-next " + (this.props.formwizardState.formwizard.availabilities.length !== 0 ? 'activeBtn' : 'changeTxt')}>{
                                (this.props.formwizardState.formwizard.availabilities.length === 0 ? "None of the above" : "Continue")
                            }</Link>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
const mapStateToProps = (state: IAppState) => ({
    formwizardState: state.formwizard
});
const mapDispatchToProps = (dispatch: any) => ({
    addAvailability: (availability: any) => {
        dispatch(addAvailability(availability));
    },
    removeAvailability: (availability: any) => {
        dispatch(removeAvailability(availability));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(Availability);