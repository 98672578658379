import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/js/script.js';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory } from 'react-router-dom';
import HomeContainer from './containers/HomeContainer/HomeContainer';
import { Provider } from 'react-redux';
import configureStore from './store';
import FormWizardContainer from './containers/FormWizardContainer/FormWizardContainer';
import Listing from './components/Listing/Listing';
import Results from './components/Results/Results';
import './configs/firebaseConfigs';
import Invite from './components/Invite/Invite';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import axios from 'axios';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import configs from './configs/apiConfigs';
import firebase from 'firebase';

const store = configureStore();
const Routing = () => {
    const history = useHistory();
    axios.interceptors.response.use(
        function (successRes) {
            return successRes;
        },
        function (error) {
            if (error.message === "Request failed with status code 401") {
                Cookies.remove('patient_cookie');
                Cookies.remove('patient_cookie', { domain: configs.COOKIE_DOMAIN });
                history.replace('/');
                if (firebase.auth().currentUser) {
                    firebase.auth().signOut();
                }
                toast.warn('Your session expired please login again.');
            }
            return Promise.reject(error);
        }
    );
    return (
        <Switch>
            <Route exact path="/quiz/results" component={Listing} key={Math.random()}></Route>
            <Route exact path="/quiz/results/:uniqueId" component={Results} key={Math.random()}></Route>
            <Route exact path="/invites/:inviteCode" component={Invite} key={Math.random()}></Route>
            <Route path="/quiz" component={FormWizardContainer} key={Math.random()}></Route>
            <Route path="/" component={HomeContainer}></Route>
        </Switch>
    )
};
const App = () => {
    return (
        <Provider store={store}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Router>
                    <Routing></Routing>
                </Router>
            </MuiPickersUtilsProvider>
        </Provider>
    )
}
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
