import React, { useState, useRef } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import * as _ from 'lodash';
export const HygieneRating: React.FC<{ HygieneFeatures: any, hygieneList: any }> = (props: { HygieneFeatures: any, hygieneList: any }) => {
    const [show, setShow] = useState(false);
    const target: any = useRef(null);
    return (
        <React.Fragment>
            <div className="hygiene_rating" ref={target} onMouseOver={() => {
                setShow(!show);
            }} onMouseOut={() => {
                setShow(!show);
            }}>
                <img src={process.env.PUBLIC_URL + "/images/hygine_icon.svg"} className="mr-2" />
                {(props.HygieneFeatures.length / props.hygieneList.list.length) * 100}% hygiene
            </div>
            {/* <Overlay target={target.current} show={show} placement="right">
                <Tooltip id="overlay-right" className="profile-tooltip">
                    {
                        props.hygieneList.list.map((value: any, index: number) => {
                            const founded = _.findIndex(props.HygieneFeatures, function (item: any) {
                                return item.id === value.id;
                            });
                            return (
                                <div key={value.id + index} className="tooltip-box">
                                    <label style={{ textDecoration: founded !== -1 ? 'none' : 'line-through' }}>{value.title}</label>
                                    
                                </div>
                            )
                        })
                    }
                </Tooltip>
            </Overlay> */}
        </React.Fragment>
    );
}