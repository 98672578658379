import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { NavLink } from 'react-router-dom';
import { CONSTANT } from '../../constants';
import { FormWizardState } from '../../store/formwizard/types';
import { addGender, setPercentage } from '../../store/formwizard/actions';
interface Props {
    formwizardState: FormWizardState;
    addGender: typeof addGender;
    history: any;
    setPercentage: typeof setPercentage;
}
class Gender extends React.Component<Props> {
    constructor(props: any) {
        super(props);
    }
    selectGender(gender: any) {
        this.props.addGender({ gender: gender });
        this.props.setPercentage(90);
        this.props.history.push(gender.link);
    }
    render() {
        const selectedGender: any = this.props.formwizardState.formwizard.gender;
        return (
            <div className="col-12 col-md-10 wizard">
                <div className="row justify-content-start gutter-5">
                    <div className="col-12">                       
                        <div className="txt-box">
                            <h4 className="title">Is the gender of your dentist important to you?</h4>
                            <div className="sub-menu">There's no right or wrong answer! If you have a preference, we'll take it into account.</div>
                        </div>
                    </div>
                    {
                        CONSTANT.QUIZ.gender.map((gender) => {
                            return (
                                <div className="col-lg-3 list-box" key={Math.random()}>
                                    <button
                                        className={"custom-click btn " + ((selectedGender && selectedGender !== "" && selectedGender.value === gender.value) ? 'active' : '')}
                                        onClick={(e) => { this.selectGender(gender) }} >
                                        <div className="img-container">
                                            <img src={process.env.PUBLIC_URL + '/' + gender.img} />
                                        </div>
                                        <div className="content-container">
                                            <div className="sub-text">{gender.value}</div>
                                        </div>
                                    </button>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

        );
    }
}
const mapStateToProps = (state: IAppState) => ({
    formwizardState: state.formwizard
});
const mapDispatchToProps = (dispatch: any) => ({
    addGender: (gender: any) => {
        dispatch(addGender(gender));
    },
    setPercentage: (percent: number) => {
        dispatch(setPercentage(percent));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(Gender);