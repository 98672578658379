import { QUIZ } from './quiz';
import { CONTACTMETHODS } from './contactMethods';
import { TITLES_LABELS } from './titles';
import { LABELS } from './labels';
export * from './location';
export const CONSTANT = {
    QUIZ,
    REWARD: 50,
    CONTACTMETHODS,
    TITLES_LABELS,
    LABELS,

}