import React,{useState,useEffect} from 'react';

function Tab(props:any) {
    const [selectedTab,setSelectedTab]=useState(props.changeTab);
    useEffect(()=>{
        setSelectedTab(props.changeTab);
    },[props.changeTab]);

    const clickListOfClinic=()=>{
        setSelectedTab('clinic');
        // props.history({pathname:'/clinic'});
        props.selectedTab('clinic');
    }

    const clickListOfProvider=()=>{
        setSelectedTab('provider');
        // props.history({pathname:'/provider'});
        props.selectedTab('provider');
    }
    return (
            <ul className="nav nav-tabs px-4 justify-content-center justify-content-md-start" id="myTab" role="tablist">
                <li className="nav-item">
                    <button onClick={() => { clickListOfProvider() }} className={selectedTab === 'provider' ? "nav-link active" : "nav-link"} id="home-tab" aria-controls="home" aria-selected="true">List of providers</button>
                </li>
                <li className="nav-item">
                    <button className={selectedTab === 'clinic' ? "nav-link active" : "nav-link"} onClick={() => { clickListOfClinic() }} id="profile-tab" aria-controls="profile" aria-selected="false">List of clinics</button>
                </li>
            </ul>
    )
}

export default Tab
