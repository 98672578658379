import React, { useState, useEffect } from 'react';
import { clinicAndProviderList } from '../../../../services/landingPageSearch';
import { toast } from 'react-toastify';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { IAppState } from '../../../../store';
import { Week } from '../../components/Provider/Week';
import configs from '../../../../configs/apiConfigs';
import { AppointmentSlot } from '../../components/Provider/AppointmentSlot';
import BookAppointment from '../../../../components/LandingPageSearch/BookAppointment';
import { patientblocksearch, appointmentSlot } from '../../../../services/landingPageSearch';
import * as _ from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { AppointmentBook } from '../AppointmentBook/AppointmentBook';
import Pagination from 'react-js-pagination';
import { addFilterItems } from '../../../../store/filters/actions';
import { searchFilter } from '../../../../store/v2/searchFilter/actions';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../../store/loadingIndicator/actions';
import { addClinicProviderList } from '../../../../store/clinicAndProviderList/actions';
import { addSlotData } from '../../../../store/slot/actions';
import $ from 'jquery';
import { showAddress } from '../../components/ShowAddress/ShowAddress';
function ProviderList(props: any) {
    let patientId: any = '';
    const [providerList, setProviderList] = useState([]);
    const [week, setWeek] = useState([]);
    const [isAppointmentBook, setIsAppointmentBook] = useState(false);
    const [selectedDoctor, setSelectedDoctor] = useState('');
    const [selectedSlot, setSelectedSlot] = useState('');
    const [blockSearchList, setBlockSearchList] = useState([]);
    const clinicProviderList: any = useSelector((state: IAppState | any) => state.clinicProviderList.clinicProviderList, shallowEqual);
    const loading = useSelector((state: IAppState) => state.loading.pending);
    const [groupByDate, setGroupByDate] = useState([]);
    const history: any = useHistory();
    const [iFram, setiFram] = useState(false);
    const slot: any = useSelector((state: IAppState) => state.slot.slot);
    const [patientBlockSearchRequest, setPatientBlockSearchRequest]: any = useState('');
    const [page, setPage] = useState(1);
    const [results, setResults] = useState(25);
    const [totalCount, setTotalCount] = useState(0);
    const [totalCountProvider, setTotalCountProvider] = useState(0);
    const dispatch = useDispatch();
    const [start, setStart] = useState(1);
    const [end, setEnd] = useState(25);
    const [apiAppointmentSlotRequest, setAppointmentSlotRequest]: any = useState();
    const [apiClinicProviderRequest, setApiClinicProviderRequest]: any = useState();
    const [once, setOnce] = useState(true);

    const searchFilterData = useSelector((state: IAppState) => state.searchFilter.searchFilterData, shallowEqual);

    useEffect(() => {
        if (new URLSearchParams(history.location.search).get('iframe') === "yes") {
            setiFram(true);

        }
        // from clinic or provider profile
        if (history && history.location && history.location.state && history.location.state.iFrame) {
            setiFram(true);
        }
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        // getClinicProviderList();
    }, []);
    useEffect(() => {
        patientId = new URLSearchParams(history.location.search).get('q');
        console.log('patientId', patientId);
        if (searchFilterData.newSpeciality && once && !patientId) {
            getClinicProviderList();
            setOnce(false);
        }
        if (once && patientId) {
            console.log('patientId3', patientId);
            patientId = new URLSearchParams(history.location.search).get('q');
            console.log('patientId4', patientId);
            if (patientId && !searchFilterData.patientUniqueId) {
                console.log('patientId5', patientId);
                dispatch(searchFilter({
                    ...searchFilterData,
                    patientUniqueId: patientId
                }));
            }
            getClinicProviderList();
        }

    }, [searchFilterData]);
    useEffect(() => {
        setProviderList(clinicProviderList.providerList);
    }, [clinicProviderList.providerList]);

    useEffect(() => {
        if (providerList.length > 0) {
            // DO NOT REMOVE BELOW CODE
            // handlePatientBlockSearch();
        }
    }, [providerList]);
    useEffect(() => {
        setGroupByDate(slot.slot);
    }, [slot]);

    const handlePatientBlockSearch = () => {
        var request: any = [];
        if (providerList.length > 0) {
            providerList.map((item: any) => {
                request.push({
                    "doctorId": item.id,
                    "clinicId": item.clinic_id,
                });
            });
            let data = { 'blockData': request };
            if (JSON.stringify(patientBlockSearchRequest) !== JSON.stringify(data)) {
                setPatientBlockSearchRequest(data);
                patientblocksearch(data).then((success: any) => {
                    try {
                        if (success && success.response && success.response.data && success.response.status === 200) {
                            if (success.response.data && success.response.data.status.error === false) {
                                setBlockSearchList(success.response.data.blockdata)
                            } else {
                                if (success.response.data && success.response.data.status.error === true) {
                                    // toast.error(success.response.data.status.msg);
                                }
                                setBlockSearchList([]);
                            }
                        }
                    } catch (error) {
                        console.log('error', error);
                    }
                });
            }
        }
    }
    const fetchAppointmentSlot = () => {
        var request: any = [];
        if (providerList.length > 0) {
            providerList.map((item: any) => {
                request.push({
                    "doctorId": item.id,
                    "clinicId": item.clinic_id,
                    "start_date": moment(week[0]).format('YYYY-MM-DD'),
                    "end_date": moment(week[4]).format('YYYY-MM-DD')
                });
            });
            let data = { 'appointmentData': request };
            appointmentSlot(data).then((success: any) => {
                try {
                    if (success && success.response && success.response.data && success.response.status === 200) {
                        if (success.response.data && success.response.data.status.error === false) {
                            var result = groupBy(success.response.data.appointments, function (item: any) {
                                return [item.doctorid, item.clinicid];
                            });
                            let data: any = [];
                            result.map((item: any) => {
                                let groupData = _.groupBy(item, 'startdate');
                                data.push(groupData);
                            })
                            setGroupByDate(data);
                            // this.setState({ appointmentSlotData: result,isAppointmentBook:false,isLoading:false });
                        } else {
                            if (success.response.data && success.response.data.status.error === true) {
                                toast.error(success.response.data.status.msg);
                            }
                        }
                    }
                } catch (error) {
                    console.log('error', error);
                }
            });
        }
    }
    const groupBy = (array: any, f: any) => {
        var groups: any = {};
        array.forEach(function (o: any) {
            var group = JSON.stringify(f(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
        });
        return Object.keys(groups).map(function (group) {
            return groups[group];
        })
    }

    const onClickClinicName = (item: any) => {
        history.push({ pathname: '/' + item.locality + '/' + item.clinic_name.split(' ').join('-') + '/' + item.clinic_uniqueId + '/c', state: { iFrame: iFram } });
    }

    const bookAppointment = (slot: any, doctor: any) => {
        setSelectedDoctor(doctor);
        setSelectedSlot(slot);
        setIsAppointmentBook(true);
    }

    const appointmentBookModalClose = () => {
        setIsAppointmentBook(false);
    }

    const clickAddToWaitList = (item: any) => {
        setSelectedDoctor(item);
        setSelectedSlot('');
        setIsAppointmentBook(true);
    }

    useEffect(() => {
        const start = (page * results) / results;
        const end = clinicProviderList.providerTotalCount > ((page * results) / results) + 24 ? ((page * results) / results) + 24 : clinicProviderList.providerTotalCount;
        setStart(start);
        setEnd(end);
        setTotalCount(clinicProviderList.providerTotalCount);
        setPage(clinicProviderList.providerTotalCount <= results ? 1 : page);
    }, [clinicProviderList.providerTotalCount]);

    const handlePageChange = (pageNUmber: any) => {
        if (page !== pageNUmber) {
            setStart((pageNUmber * results) - (results - 1));
            setEnd((pageNUmber * results) > totalCount ? totalCount : (pageNUmber * results));
            setPage(pageNUmber);
            dispatch(searchFilter({
                ...searchFilterData,
                page: pageNUmber
            }));
            getClinicProviderList(pageNUmber);
        }
    }

    const getClinicProviderList = (pageNumber?: any) => {
        console.log('checkRedux', searchFilterData);
        const { newSpeciality, location, date, howToPay, patientUniqueId, page, languages, distance, gender, clinicId, providerId } = searchFilterData;
        let latitude: any = "", longitude: any = "", langIds: any = [], clinic: any = '';
        if (location !== "") {
            latitude = location.latitude;
            longitude = location.longitude;
        }
        if (languages && languages.length > 0) {
            langIds = languages.map((item: any) => {
                return item.value
            });
        }
        let request = {
            location: {
                latitude: latitude,
                longitude: longitude,
                specialityId: newSpeciality,
                searchdate: date !== null ? date : "",
                radiuskm: distance,
                gender: gender ? gender.toString() : '',
                languages: langIds.toString(),
                insuranceProviderId: howToPay.type === 'insurance' ? howToPay.value : '',
                acceptAssignment: howToPay.type === 'cash' ? 'no' : '',
                socialAssistTypeID: howToPay.type === 'socialAssistance' ? howToPay.value : '',
                clinicId: clinicId,
                providerId: providerId,
                patientUniqueId: patientId ? patientId : patientUniqueId,
                page: pageNumber ? pageNumber : 1,
                results: results
            }
        }
        let isApiCall: boolean = false;
        if (JSON.stringify(apiClinicProviderRequest) !== JSON.stringify(request)) {
            setApiClinicProviderRequest(request);
            isApiCall = true;
        }
        if (isApiCall) {
            dispatch(fetchLoadingPending());
            try {
                clinicAndProviderList(request).then((success: any) => {
                    if (success && success.response && success.response.data && success.response.status === 200) {
                        if (success.response.data && success.response.data.status.error === false) {
                            setTimeout(() => {
                                dispatch(fetchLoadingSuccess(false));
                            }, 2000);
                            if (success.response.data && success.response.data.total) {
                                setTotalCountProvider(success.response.data.total);
                            }
                            dispatch(addClinicProviderList({
                                ...clinicProviderList,
                                clinicList: _.uniqBy(success.response.data.clinic, 'id'),
                                providerList: success.response.data.doctors,
                                providerTotalCount: success.response.data.total ? success.response.data.total : totalCountProvider
                            }));
                            // appointmentSlotApi(success.response.data.doctors);
                            document.body.scrollTop = 0;
                            document.documentElement.scrollTop = 0;
                        } else if (success.response.data && success.response.data.status.error === true) {
                            dispatch(addClinicProviderList({
                                ...clinicProviderList,
                                clinicList: [],
                                providerList: [],
                                providerTotalCount: 0,
                                page: 1
                            }));
                            console.log('error');
                            dispatch(fetchLoadingSuccess(false));
                        }
                    }
                });
            } catch (error) {
                toast.error('Something went wrong.');
                console.log('error', error);
                dispatch(fetchLoadingSuccess(false));
            }
        } else {
            // dispatch(fetchLoadingSuccess(false));
        }
    }

    const appointmentSlotApi = (providerList?: any, currentWeek?: any) => {
        var request: any = [];
        let startDate: any = searchFilterData.date ? searchFilterData.date : moment().format('YYYY-MM-DD');
        let endDate: any = searchFilterData.date ? moment(searchFilterData.date).add('days', 4).format('YYYY-MM-DD') : moment().add('days', 4).format('YYYY-MM-DD');
        if (providerList.length > 0) {
            providerList.map((item: any) => {
                if (item.sharecalendar && item.sharecalendar.calender) {
                    request.push({
                        "doctorId": item.id,
                        "clinicId": item.clinic_id,
                        "start_date": startDate,
                        "end_date": endDate
                    });
                }
            });
            if (request.length > 0) {
                let data = { 'appointmentData': request };
                let isApiCall: boolean = false;
                if (JSON.stringify(apiAppointmentSlotRequest) !== JSON.stringify(data)) {
                    setAppointmentSlotRequest(data);
                    isApiCall = true;
                }
                if (isApiCall) {
                    dispatch(fetchLoadingPending());
                    appointmentSlot(data).then((success: any) => {
                        try {
                            if (success && success.response && success.response.data && success.response.status === 200) {
                                if (success.response.data && success.response.data.status.error === false) {
                                    dispatch(fetchLoadingSuccess(false));
                                    var result = groupBy(success.response.data.appointments, function (item: any) {
                                        return [item.doctorid, item.clinicid];
                                    });
                                    let data: any = [];
                                    result.map((item: any) => {
                                        let groupData = _.groupBy(item, 'startdate');
                                        data.push(groupData);
                                    })
                                    // setGroupByDate(data);
                                    dispatch(addSlotData({ slot: data }));
                                    // this.setState({ appointmentSlotData: result,isAppointmentBook:false,isLoading:false });
                                } else {
                                    if (success.response.data && success.response.data.status.error === true) {
                                        toast.error(success.response.data.status.msg);
                                    }
                                    dispatch(fetchLoadingSuccess(false));
                                }
                            }
                        } catch (error) {
                            toast.error('Something went wrong.');
                            console.log('error', error);
                            dispatch(fetchLoadingSuccess(false));
                        }
                    });
                } else {
                    dispatch(fetchLoadingSuccess(false));
                }
            }
        }
    }

    return (
        <>
            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div className="provider-list-cal">
                    <div className="d-md-flex justify-content-between calender-view-sec px-4 py-3">
                        {/* <h2>{providerList && providerList.length > 0 && providerList.length} Providers</h2> */}
                        {clinicProviderList.providerTotalCount !== 0 ?
                            <div className="text-muted align-self-center mb-3 mb-md-0">{start}-{end} of {totalCount} Providers</div>
                            :
                            <div className="text-muted align-self-center mb-3 mb-md-0">Providers</div>
                        }
                        {/* DO NOT REMOVE BELOW CODE */}
                        {/* {(providerList && providerList.length > 0) &&
                            <Week
                                selectedWeek={(selectedWeek: any) => {
                                    if (selectedWeek[0] !== week[0]) {
                                        setWeek(selectedWeek);
                                        props.selectedWeek(selectedWeek);
                                    }
                                }}
                            />
                        } */}
                    </div>
                    {(providerList && providerList.length > 0) ?
                        <ul className="provider-list-box px-4" id="providerList">
                            {
                                providerList.map((item: any, indexs: number) => {
                                    if (item.fullname) {
                                        let doctorName = item.fullname.replace("Dr.", "");
                                        doctorName = doctorName.trim();
                                        doctorName = doctorName.split(" ");
                                        const letterOne = doctorName[0] ? doctorName[0].charAt(0) : '';
                                        const letterTwo = doctorName[1] ? doctorName[1].charAt(0) : '';
                                    }
                                    return (
                                        <li key={item.id + indexs + 'provider' + item.id} className="d-md-flex justify-content-between">
                                            <div className="left-sec">
                                                <div className="media">
                                                    <div className="media-img mr-4 text-center">
                                                        <div className="img-box">
                                                            {(item && item.photo && item.photo.name) ?
                                                                <img src={configs.IMAGE_URL + 'doctor/' + item.photo.name + '-200x200.' + item.photo.type} className="img-fluid" />
                                                                :
                                                                <div key={indexs + Math.random()} className="img-box">
                                                                    {/* <Avatar>{letterOne}{letterTwo}</Avatar> */}
                                                                </div>
                                                            }
                                                        </div>
                                                        <button onClick={() => {
                                                            history.push({ pathname: '/' + item.locality + '/' + item.fullname.split(' ').join('-') + '/' + item.provideruniqueid + '/d', state: { iFrame: iFram } });
                                                        }} className="btn btn-link px-0 mx-auto">View profile</button>
                                                    </div>
                                                    <div className="media-body">
                                                        <div className="pro-main-txt my-1">{item.fullname}</div>
                                                        <div className="pro-dent-link my-1">{item.speciality_name && item.speciality_name} - <a
                                                            onClick={() => {
                                                                onClickClinicName(item)
                                                            }}>{item.clinic_name && item.clinic_name}</a></div>
                                                        {/* <div className="pro-add my-1">{item.street ? item.street : ''} {item.route ? item.route : ''} {item.locality ? item.locality : ''} {item.address_level_1 ? item.address_level_1 : ''}</div> */}
                                                        <div className="pro-add my-1">{showAddress(item)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div key={indexs + 'appointmentSlot' + item.id} className="right-sec text-right align-self-center">
                                                {/* DO NOT REMOVE BELOW CODE */}
                                                {/* {
                                                    item.sharecalendar && item.sharecalendar.calender &&
                                                    <React.Fragment key={item.id + indexs + 'appointmentSlot'}>
                                                        <AppointmentSlot
                                                            key={item.id + indexs + 'appointmentSlot1'}
                                                            blockSearchList={blockSearchList}
                                                            groupByDate={groupByDate}
                                                            week={week}
                                                            provider={item}
                                                            bookAppointment={bookAppointment}
                                                        />
                                                    </React.Fragment>

                                                } */}
                                                {/* <button className="border-btn btn"><img src={require("./images/view-availability.svg")} className="mr-2"/>View availability</button> */}
                                                <button onClick={() => { 
                                                    /* DO NOT REMOVE CODE */
                                                    // clickAddToWaitList(item) 
                                                    history.push({ pathname: '/' + item.locality + '/' + item.fullname.split(' ').join('-') + '/' + item.provideruniqueid + '/d', state: { iFrame: iFram } });
                                                }} className="border-btn btn">
                                                    {/* <img src={require("./images/addToWaitList.svg")} className="mr-2" /> */}
                                                    {/* DO NOT REMOVE BELOW CODE */}
                                                    {/* {(item.sharecalendar && item.sharecalendar.calender) ?
                                                        'Add to wait list'
                                                        : 'Request for an appointment'
                                                    } */}
                                                    Request for an appointment
                                                </button>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        : loading ? null : <h3 className="px-4 pt-3">No result found.</h3>
                    }
                </div>
                {
                    (providerList && providerList.length > 0) &&
                    <div className="d-flex justify-content-end px-4 pt-3">
                        <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            hideDisabled
                            activePage={page}
                            itemsCountPerPage={results}
                            totalItemsCount={Number(totalCount)}
                            onChange={handlePageChange}
                        />
                    </div>
                }
            </div>
            {
                (isAppointmentBook) &&
                <AppointmentBook
                    selectedDoctor={selectedDoctor}
                    selectedSlot={selectedSlot}
                    blockSlotData={blockSearchList}
                    hideModal={appointmentBookModalClose}
                    bookAppointmentSuccess={() => {
                        handlePatientBlockSearch();
                        fetchAppointmentSlot();
                    }}
                />
            }
        </>
    )
}

export default ProviderList
