import React from 'react';
import { IAppState } from '../../store';
import { connect } from 'react-redux';
import { FormWizardState } from '../../store/formwizard/types';
import { CONSTANT } from '../../constants';
import { addAttitude, setPercentage } from '../../store/formwizard/actions';
import { Modal } from 'react-bootstrap';
import Cookies from 'js-cookie';
interface Props {
    formwizardState: FormWizardState;
    addAttitude: typeof addAttitude;
    history: any;
    match: any;
    setPercentage: typeof setPercentage;
}
interface State {
    showModal: boolean;
}
class Attitude extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            showModal: false
        }
        this.selectAttitude = this.selectAttitude.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }
    componentDidMount() {
        const value: any = localStorage.getItem('front_key_store');
        if (value === undefined || value === null || value === "") {
            this.props.history.push('/');
        } else {
            const valueObj = JSON.parse(value);
            if (valueObj.formwizard.formwizard.location === "") {
                this.props.history.push('/');
            } else {

                if (Cookies.get('modallive') === undefined) {
                    Cookies.set('modallive', 'true', { expires: 1 });
                    this.setState({ showModal: true });
                }
            }
        }
    }
    selectAttitude(attitude: any) {
        console.log(this.props.match);
        this.props.addAttitude({ attitude: attitude });
        this.props.setPercentage(20);
        this.props.history.push(attitude.link);
    }
    shouldComponentUpdate(nextProps: Props, nextState: any) {
        console.log(nextProps);
        return true;
    }
    hideModal() {
        this.setState({ showModal: false });
    }
    render() {
        console.log(this.props);
        const selectedAttitude: any = this.props.formwizardState.formwizard.attitude;
        return (
            <>
                <Modal className="helPopup" show={this.state.showModal} onHide={this.hideModal} backdrop={true} keyboard={true} centered>
                    <Modal.Body>
                        <div className="hptitle">Help us</div>
                        <div className="hp-text">Help us understand your needs so we can find the best dentist for you in your area.</div>
                        <button onClick={this.hideModal} className="btn btn-white">let's Start</button>
                    </Modal.Body>
                </Modal>

                <div className="col-12 col-md-10 wizard">
                    <div className="row justify-content-center gutter-5">
                        <div className="col-12">                          
                            <div className="txt-box">
                                <h4 className="title">When it comes to my mouth wellness, I feel...</h4>
                            </div>
                        </div>
                        {
                            CONSTANT.QUIZ.attitude.map((attitude) => {
                                console.log(selectedAttitude, attitude);
                                return (
                                    <div className="col-lg-3" key={Math.random()}>
                                        <button className={"custom-click btn " + ((selectedAttitude && selectedAttitude !== "" && selectedAttitude.value === attitude.value) ? 'active' : '')}
                                            onClick={(e) => { this.selectAttitude(attitude) }} >
                                            <div className="img-container">
                                                <img src={process.env.PUBLIC_URL + '/' + attitude.img} />
                                            </div>
                                            <div className="content-container">
                                                <h5 className="main-text">{attitude.value}</h5>
                                                <div className="sub-text">{attitude.label}</div>
                                            </div>
                                        </button>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </>
        );
    }
}
const mapStateToProps = (state: IAppState) => ({
    formwizardState: state?.formwizard
});
const mapDispatchToProps = (dispatch: any) => ({
    addAttitude: (attitude: any) => {
        dispatch(addAttitude(attitude));
    },
    setPercentage: (percent: number) => {
        dispatch(setPercentage(percent));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(Attitude);