import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { signInWithCustomToken } from "../../../../utils/firebaseUtils";
import { emailValidation } from "../../../../utils/validators";
import { LABELS } from "../../constants";
import { checkForgotpasswordAccount, checkLogin, forgotPassword, patientLoginNew, registerUser, resetPasswordApi, verifyCode, verifyOtpForgotpassword } from "../../services/auth";
import Cookies from 'js-cookie';
import configs from "../../../../configs/apiConfigs";
import { toast } from "react-toastify";
import PinInput from "react-pin-input";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { getAge, getCookie } from "../../../../utils/global";
import { registerUserGuest } from "../../../../services/auth";
import firebase from "firebase";
import { addDependant, appointmentRequest, bookAppointmentGuest, getPatientList } from "../../services/AppointmentBook";
import { fetchFirstAvailableConsultationsSlots } from "../../../../services/dentist";

export const AppointmentScheduling = ({
    handleConsultationTypeChange,
    selectedConsultation,
    consultationTypes,
    slots,
    clinicName,
    providerName,
    clinicId,
    doctorId,
    appointmentTypeId,
    clinicUniueId,
    firstAvailable,
    flexibleSchedulingOn,
    source
}: any) => {
    const [stepVisible, setStepVisible] = useState(1);
    const [selectedDate, setSelectedDate] = useState(moment().toDate());
    const [selectedSlot, setSelectedSlot]: any = useState(null);
    const [selectedDays, setSelectedDays]: any = useState([]);
    const [selectedFlexiSlot, setSelectedFlexiSlot] = useState("");
    const [areYouExistingPatient, setAreYouExistingPatient]: any = useState(null);
    const [selectedPatient, setSelectedpatient]: any = useState(null);
    const [selectedGuarantor, setSelectedGuarantor]: any = useState(null);
    const [isGuest, setIsGuest] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showFlexibleSchedule, setShowFlexibleSchedule] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [firstAvailableSlots, setFirstAvailableSlots] = useState();
    useEffect(() => {
        if (flexibleSchedulingOn) setShowFlexibleSchedule(false);
        else setShowFlexibleSchedule(true);
    },[flexibleSchedulingOn]);
    const startDateGroup = _.groupBy(slots, 'startdate');
    const dateWiseGroup = _.groupBy(slots.filter((item: any) => item.avability === "yes"), 'startdate');
    // useEffect(() => {
    //     if (firstAvailable && firstAvailable?.date && activeTab === 0) {
    //         setSelectedDate(moment(firstAvailable?.date).toDate());
    //     }
    // }, [firstAvailable]);
    useEffect(() => {
        if (doctorId & clinicId && selectedConsultation) {
            getFirstAvailable();
        }
    }, [doctorId, clinicId, selectedConsultation]);
    const getFirstAvailable = async () => {
        try {
            let _obj = {
                doctorId: doctorId,
                clinicId: clinicId,
                consultationTypeId: selectedConsultation,
                "start_date": moment().format("YYYY-MM-DD"),
                "end_date": moment().add(6, 'months').format("YYYY-MM-DD"),
            }
            const response: any = await fetchFirstAvailableConsultationsSlots({
                appointmentData: [_obj]
            });
            setFirstAvailableSlots(response?.response?.data?.firstAvailable);
            console.log("response", response?.response?.data?.firstAvailable);
        } catch (err) {
            console.log("err", err);
        }
    }
    const handleDaysChange = (value: string) => {
        if (selectedDays.includes(value)) {
            setSelectedDays((current: any) => current.filter((_item: any) => _item !== value));
        } else {
            setSelectedDays(selectedDays.concat(value));
        }
        setSelectedSlot(null);
    }
    const hidePatientListModal = () => {
        setStepVisible(1);
    }
    const handleConfirm = () => {
        console.log("confirmation");
        setIsLoading(true);
        bookAppointmentRequest();
    };
    const bookAppointmentRequest = () => {
        const clinic = {
            id: clinicId,
            doctor: {
                id: doctorId
            }
        };
        let slots: any = [];
        if (selectedFlexiSlot === "08:00 AM - 12:00 PM") {
            slots = ["Morning"];
        }
        if (selectedFlexiSlot === "12:00 PM - 04:00 PM") {
            slots = ["Afternoon"];
        }
        if (selectedFlexiSlot === "04:00 PM - 07:00 PM") {
            slots = ["Evening"];
        }
        if (selectedFlexiSlot === "08:00 AM - 07:00 PM") {
            slots = ["Anytime"];
        }
        let request: any = {
            isNewPatient: true,
            isExistingClinic: areYouExistingPatient,
            isFromApp: true,
            patient: {
                id: selectedPatient?.id,
            },
            clinic: clinic,
            appointmenttype: {
                id: appointmentTypeId
            },
            consultationtype: {
                id: selectedConsultation
            },
            dependant: {
                id: selectedPatient.id,
                etime: moment(getSlotTime().endtime).format('LT'),
                slot: slots?.toString() ?? '',
                day: selectedDays?.toString()
            },
            request: {
                isNewPatient: true,
                additionalNote: selectedPatient?.notes,
                slot: slots?.toString() ?? '',
                day: selectedDays?.toString()
            },
        };
        if (!selectedGuarantor && selectedPatient) {
            request = {
                ...request,
                dependant: {
                    ...request.dependant,
                    fname: selectedPatient.firstName,
                    lname: selectedPatient.lastName,
                    email: selectedPatient.email,
                    phone: selectedPatient.phone,
                    dob: moment(selectedPatient.dob).format('YYYY-MM-DD'),
                    gender: selectedPatient.gender,
                }
            }
        } else {
            request = {
                ...request,
                patient: {
                    id: selectedGuarantor
                }
            }
        }
        request = {
            ...request,
            dateTime: {
                "date": moment(getSlotTime().date).format('YYYY-MM-DD'),
                "time": moment(getSlotTime().starttime).format('LT'),
                "startTime": moment(getSlotTime().starttime).format('LT'),
                "endTime": moment(getSlotTime().endtime).format('LT')
            },
            source: source ?? "onlinescheduling"
        };
        if (!selectedGuarantor && selectedPatient && isGuest) {
            // IT is GUEST Appointment
            bookAppointmentGuest(request).then((success) => {
                if (success && success.status && success.status.error === false) {
                    toast.success(success.status.msg);
                    var db = firebase.firestore();
                    const obj = {
                        "sender": 'utility',
                        "action": 'Appt requested from utility.',
                        "actionType": 'STATUS',
                        "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                        "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                        "fromStatus": 'CREATED',
                        "toStatus": 'GUEST',
                        "sendToCId": clinicUniueId,
                        "dateTime": request.dateTime
                    };
                    db.collection(configs.CHAT_COLLECTION_NAME).doc().set(obj)
                        .then(() => {
                            console.log("added", obj);
                            setStepVisible(1);
                            // setIsLoading(false);
                            setSelectedSlot(null);
                            setSelectedDays([]);
                            setSelectedFlexiSlot("");
                            setAreYouExistingPatient(null);
                            setSelectedpatient(null);
                            setSelectedGuarantor(null);
                            // setSelectedDate(moment().toDate());
                            setTimeout(() => {
                                handleConsultationTypeChange(selectedConsultation, {
                                    consultationTypeId: selectedConsultation,
                                    start_date: moment(moment(selectedDate).toDate()).format("YYYY-MM-DD"),
                                    end_date: moment(moment(moment(selectedDate).toDate()).add("3", "day")).format("YYYY-MM-DD"),
                                });
                                setIsLoading(false);
                            }, 3000);
                        }).catch((err) => {
                            setStepVisible(1);
                            setIsLoading(false);
                            setSelectedSlot(null);
                            setSelectedDays([]);
                            setSelectedFlexiSlot("");
                            setAreYouExistingPatient(null);
                            setSelectedpatient(null);
                            setSelectedGuarantor(null);
                            // setSelectedDate(moment().toDate());
                        });
                } else if (success && success.status && success.status.error === true) {
                    setIsLoading(false);
                    if (success.status.msg) {
                        setSelectedSlot(null);
                        setSelectedDays([]);
                        setSelectedFlexiSlot("");
                        setAreYouExistingPatient(null);
                        setSelectedpatient(null);
                        setSelectedGuarantor(null);
                        // setSelectedDate(moment().toDate());
                        toast.error(success.status.msg);
                    } else {
                        toast.error('Something went wrong.');
                    }
                }
            }).catch((err) => {
                setIsLoading(false);
                console.log(err);
            })
        } else {
            appointmentRequest(request).then((success) => {
                if (success && success.status && success.status.error === false) {
                    addStatusToFirebase(success.appopintment, success.dependant, success.patient, success.status.msg);
                    setSelectedSlot(null);
                    setSelectedDays([]);
                    setSelectedFlexiSlot("");
                    setAreYouExistingPatient(null);
                    setSelectedpatient(null);
                    setSelectedGuarantor(null);
                    // setSelectedDate(moment().toDate());
                    setStepVisible(1);
                    setTimeout(() => {
                        handleConsultationTypeChange(selectedConsultation, {
                            consultationTypeId: selectedConsultation,
                            start_date: moment(moment(selectedDate).toDate()).format("YYYY-MM-DD"),
                            end_date: moment(moment(moment(selectedDate).toDate()).add("3", "day")).format("YYYY-MM-DD"),
                        });
                        setIsLoading(false);
                    }, 3000);
                } else if (success && success.status && success.status.error === true) {
                    setIsLoading(false);
                    if (success.status.msg) {
                        toast.error(success.status.msg);
                    } else {
                        toast.error('Something went wrong.');
                    }
                }
            }).catch((err) => {
                setIsLoading(false);
                console.log(err);
            })
        }
    }
    const getSlotTime = () => {
        let endtime: any = moment();
        let starttime: any = moment();
        let date: any = moment();
        if (selectedSlot) {
            endtime = moment(moment(selectedSlot.startdate + " " + selectedSlot?.endTime));
            starttime = moment(moment(selectedSlot.startdate + " " + selectedSlot?.startTime));
            date = moment(selectedSlot.startdate);
        } else if (selectedFlexiSlot && selectedDays) {
            starttime = moment(moment().format("DD MMM YYYY") + " " + selectedFlexiSlot.split("-")[0]);
            endtime = moment(moment().format("DD MMM YYYY") + " " + selectedFlexiSlot.split("-")[1]);
            date = moment(selectedDate);
        }
        console.log(starttime, endtime);
        return {
            date,
            starttime,
            endtime
        }
    }
    const addStatusToFirebase = (appointmentId: any, dependantId: any, patientId: any, successMessage: any) => {
        var db = firebase.firestore();
        let dateTimeObj: any = {};
        dateTimeObj = {
            "date": moment(getSlotTime().date).format('YYYY-MM-DD'),
            "time": moment(getSlotTime().starttime).format('LT'),
            "startTime": moment(getSlotTime().starttime).format('LT'),
            "endTime": moment(getSlotTime().endtime).format('LT')
        }
        const obj = {
            "appointmentId": Number(appointmentId),
            "senderId": Number(patientId),
            "sender": 'patient',
            "action": 'Changed status from CREATED to REQUESTED.',
            "actionType": 'STATUS',
            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
            "fromStatus": 'CREATED',
            "toStatus": 'REQUESTED',
            "sendToCId": clinicUniueId,
            "dependantId": Number(dependantId),
            "dateTime": {
                ...dateTimeObj
            },
        };
        db.collection(configs.CHAT_COLLECTION_NAME).doc().set(obj)
            .then(() => {
                console.log("Document successfully written!");
                toast.success(successMessage);
                // TODO Handle success
                setStepVisible(1);
            })
            .catch((error: any) => {
                toast.error('Something went wrong.');
                console.error("Error writing document: ", error);
            });
    }
    console.log("startDateGroup", startDateGroup);
    return (
        <>
            {
                stepVisible === 1 &&
                <div className="book-sec px-3 py-4">
                    <h2 className="text-left mb-4">Book Your Appointment</h2>
                    <div>
                        <label className="font-weight-bold">What is the reason for your visit?</label>

                        <select className="form-control" id="inputGroupSelect01" value={selectedConsultation} onChange={(e: any) => {
                            handleConsultationTypeChange(e.target.value, {
                                consultationTypeId: e.target.value,
                                start_date: moment(selectedDate).format("YYYY-MM-DD"),
                                end_date: moment(moment(selectedDate).add("3", "day")).format("YYYY-MM-DD"),
                            });
                        }}>
                            <option selected disabled>Select consultation type</option>
                            {
                                consultationTypes?.types?.map((item: any) => {
                                    return (
                                        <option value={item.id}>{item.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="mt-3 d-flex">
                        <label className="font-weight-bold mr-4 mb-0">Have you visited us before?</label>
                        <div className="d-flex">
                            <div className="form-check mr-2">
                                <input className="form-check-input" type="radio" value="existing" id="visited-yes" checked={areYouExistingPatient === true} onChange={() => {
                                    setAreYouExistingPatient(!areYouExistingPatient);
                                }} />
                                <label className="form-check-label" htmlFor="visited-yes">
                                    Yes
                                </label>
                            </div>
                            <div className="form-check ">
                                <input className="form-check-input" type="radio" value="existing" id="visited-no" checked={areYouExistingPatient === false} onChange={() => {
                                    if (areYouExistingPatient === null) setAreYouExistingPatient(false);
                                    else setAreYouExistingPatient(!areYouExistingPatient);
                                }} />
                                <label className="form-check-label" htmlFor="visited-no">
                                    No
                                </label>
                            </div>
                        </div>
                    </div>
                    {
                        areYouExistingPatient === null &&
                        <small className="text-danger">Please select one of the options.</small>
                    }

<div className="availability-tabs">
                        <div className="availability-tabs-items">
                            <div className={`availability-tabs-items-item ${activeTab === 0 ? 'active' : ''}`} onClick={() => {
                                setActiveTab(0)
                            }}>
                                1st Available
                            </div>
                            <div className={`availability-tabs-items-item ${activeTab === 1 ? 'active' : ''}`} onClick={() => {
                                setActiveTab(1)
                            }}>
                                All available
                            </div>
                        </div>
                        {
                            activeTab === 0 &&
                            <div className="availability-tabs-content first-available">
                                <div className="first-available-items">
                                    {
                                        firstAvailableSlots && Object.keys(firstAvailableSlots).map((item: any) => {
                                            return (
                                                <div className="first-available-items-item">
                                                    <div className="first-available-items-item__title">{moment(item).format('MMM DD')}</div>
                                                    <div className="first-available-items-item__slot">
                                                        {
                                                            firstAvailableSlots[item].map((slot: any) => {
                                                                return (
                                                                    <div className={`first-available-items-item__slot-item available_slot ${(selectedSlot?.startdate === slot?.startdate && selectedSlot?.startTime === slot?.startTime) && "active"}`} onClick={() => {
                                                                        setSelectedSlot(slot);
                                                                        setSelectedDays([]);
                                                                        setSelectedFlexiSlot("");
                                                                    }}>
                                                                        {slot.startTime}
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        }
                        {
                            activeTab === 1 &&
                            <div className="availability-tabs-content all-available">
                             
                                <div className="date-selector">
                                    <input type="date" min={moment().format("YYYY-MM-DD")} value={moment(selectedDate).format("YYYY-MM-DD")} onChange={(e) => {
                                        setSelectedDate(moment(e.target.value).toDate());
                                        handleConsultationTypeChange(selectedConsultation, {
                                            consultationTypeId: selectedConsultation,
                                            start_date: moment(moment(e.target.value).toDate()).format("YYYY-MM-DD"),
                                            end_date: moment(moment(moment(e.target.value).toDate()).add("3", "day")).format("YYYY-MM-DD"),
                                        });
                                    }}></input>
                                </div>
                                <div className="availability-box">
                                    <div className="days-title text-center">
                                        <a className="date-prev" onClick={() => {
                                            if (moment(selectedDate, "DD-MM-YYYY").format("DD-MM-YYYY") === moment().format("DD-MM-YYYY")) return;
                                            if (moment(selectedDate).isSameOrAfter(moment())) {
                                                setSelectedDate(moment(selectedDate).subtract(4, 'day').toDate())
                                                handleConsultationTypeChange(selectedConsultation, {
                                                    consultationTypeId: selectedConsultation,
                                                    start_date: moment(selectedDate).subtract(4, 'day').format("YYYY-MM-DD"),
                                                    end_date: moment(moment(moment(selectedDate).subtract(4, 'day')).add("3", "day")).format("YYYY-MM-DD"),
                                                });
                                            }
                                        }}><i className="bi bi-chevron-left text-primary"></i></a>
                                        <div className="date-slot">
                                            <small className="d-block">
                                                {
                                                    selectedDate &&
                                                    <>{moment(selectedDate).format('ddd')}</>
                                                }
                                            </small>
                                            <small className="d-block">
                                                {
                                                    selectedDate &&
                                                    <>{moment(selectedDate).format('MMM DD')}</>
                                                }
                                            </small>
                                        </div>
                                        <div className="date-slot">
                                            <small className="d-block">
                                                {
                                                    selectedDate &&
                                                    <>{moment(selectedDate).add(1, 'day').format('ddd')}</>
                                                }
                                            </small>
                                            <small className="d-block">
                                                {
                                                    selectedDate &&
                                                    <>{moment(selectedDate).add(1, 'day').format('MMM DD')}</>
                                                }
                                            </small>
                                        </div>
                                        <div className="date-slot">
                                            <small className="d-block">
                                                {
                                                    selectedDate &&
                                                    <>{moment(selectedDate).add(2, 'day').format('ddd')}</>
                                                }
                                            </small>
                                            <small className="d-block">
                                                {
                                                    selectedDate &&
                                                    <>{moment(selectedDate).add(2, 'day').format('MMM DD')}</>
                                                }
                                            </small>
                                        </div>
                                        <div className="date-slot">
                                            <small className="d-block">
                                                {
                                                    selectedDate &&
                                                    <>{moment(selectedDate).add(3, 'day').format('ddd')}</>
                                                }
                                            </small>
                                            <small className="d-block">
                                                {
                                                    selectedDate &&
                                                    <>{moment(selectedDate).add(3, 'day').format('MMM DD')}</>
                                                }
                                            </small>
                                        </div>
                                        <a className="date-next" onClick={() => {
                                            setSelectedDate(moment(selectedDate).add(4, 'day').toDate())
                                            handleConsultationTypeChange(selectedConsultation, {
                                                consultationTypeId: selectedConsultation,
                                                start_date: moment(selectedDate).add(4, 'day').format("YYYY-MM-DD"),
                                                end_date: moment(moment(moment(selectedDate).add(4, 'day')).add("3", "day")).format("YYYY-MM-DD"),
                                            });
                                        }}><i className="bi bi-chevron-right text-primary"></i></a>
                                    </div>
                                    <div className="slots my-1">
                                        {
                                            Object.keys(startDateGroup ?? {}).map((_item) => {
                                                return (
                                                    <div className="d-flex flex-column">
                                                        {
                                                            startDateGroup[_item].map((item) => {
                                                                if (item.avability === "no") return null;
                                                                return (
                                                                    <a href="javascript:void(0);" className={`text-center border rounded p-1 small w-100 ${item.avability === "no" ? "unavailable" : "available"} ${item.avability === "no" && "disabled"} ${(selectedSlot?.startdate === item?.startdate && selectedSlot?.startTime === item?.startTime) && "active text-white"}`} onClick={() => {
                                                                        if (item.avability === "no") return;
                                                                        setSelectedSlot(item);
                                                                        setSelectedDays([]);
                                                                        setSelectedFlexiSlot("");
                                                                    }}>{item.startTime}</a>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div> 
                        </div>
                        }
                    </div>

                    {/* <div className="mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                            <label className="font-weight-bold">Availability</label>
                            <input type="date" value={moment(selectedDate).format("YYYY-MM-DD")} style={{
                                border: "none",
                                background: "no-repeat",
                                color: "#0267D4",
                            }} onChange={(e) => {
                                setSelectedDate(moment(e.target.value).toDate());
                            }}></input>
                        </div>
                        <div className="bg-white border rounded availability-box ">
                            <div className="days-title text-center d-flex">
                                <a onClick={() => {
                                    if (moment(selectedDate, "DD-MM-YYYY").format("DD-MM-YYYY") === moment().format("DD-MM-YYYY")) return;
                                    setSelectedDate(moment(selectedDate).subtract(4, 'day').toDate())
                                    handleConsultationTypeChange(selectedConsultation, {
                                        consultationTypeId: selectedConsultation,
                                        start_date: moment(selectedDate).subtract(4, 'day').format("YYYY-MM-DD"),
                                        end_date: moment(moment(moment(selectedDate).subtract(4, 'day')).add("3", "day")).format("YYYY-MM-DD"),
                                    });
                                }}><i className="bi bi-chevron-left text-primary"></i></a>
                                <div className="w-4">
                                    <small className="d-block">
                                        {
                                            selectedDate &&
                                            <>{moment(selectedDate).format('ddd')}</>
                                        }
                                    </small>
                                    <small className="d-block">
                                        {
                                            selectedDate &&
                                            <>{moment(selectedDate).format('MMM DD')}</>
                                        }
                                    </small>
                                </div>
                                <div className="w-4">
                                    <small className="d-block">
                                        {
                                            selectedDate &&
                                            <>{moment(selectedDate).add(1, 'day').format('ddd')}</>
                                        }
                                    </small>
                                    <small className="d-block">
                                        {
                                            selectedDate &&
                                            <>{moment(selectedDate).add(1, 'day').format('MMM DD')}</>
                                        }
                                    </small>
                                </div>
                                <div className="w-4">
                                    <small className="d-block">
                                        {
                                            selectedDate &&
                                            <>{moment(selectedDate).add(2, 'day').format('ddd')}</>
                                        }
                                    </small>
                                    <small className="d-block">
                                        {
                                            selectedDate &&
                                            <>{moment(selectedDate).add(2, 'day').format('MMM DD')}</>
                                        }
                                    </small>
                                </div>
                                <div className="w-4">
                                    <small className="d-block">
                                        {
                                            selectedDate &&
                                            <>{moment(selectedDate).add(3, 'day').format('ddd')}</>
                                        }
                                    </small>
                                    <small className="d-block">
                                        {
                                            selectedDate &&
                                            <>{moment(selectedDate).add(3, 'day').format('MMM DD')}</>
                                        }
                                    </small>
                                </div>
                                <a onClick={() => {
                                    setSelectedDate(moment(selectedDate).add(4, 'day').toDate())
                                    handleConsultationTypeChange(selectedConsultation, {
                                        consultationTypeId: selectedConsultation,
                                        start_date: moment(selectedDate).add(4, 'day').format("YYYY-MM-DD"),
                                        end_date: moment(moment(moment(selectedDate).add(4, 'day')).add("3", "day")).format("YYYY-MM-DD"),
                                    });
                                }}><i className="bi bi-chevron-right text-primary"></i></a>
                            </div>
                            <div className="slots d-flex my-1 flex-wrap">
                                {
                                    Object.keys(startTimeGroup ?? {}).map((_item) => {
                                        return startTimeGroup[_item].map((item) => {
                                            return (
                                                <a href="javascript:void(0);" className={`text-center border rounded p-1 small w-4 ${item.avability === "no" && "disabled bg-light"} ${(selectedSlot?.startdate === item?.startdate && selectedSlot?.startTime === item?.startTime) && "active text-white"}`} onClick={() => {
                                                    if (item.avability === "no") return;
                                                    setSelectedSlot(item);
                                                    setSelectedDays([]);
                                                    setSelectedFlexiSlot("");
                                                }}>{item.startTime}</a>
                                            )
                                        });
                                    })
                                }
                            </div>
                        </div>
                    </div> */}
                    <div className="mt-3">
                        <label className={"cursor-pointer " + (flexibleSchedulingOn ? "btn-link" : "")} onClick={() => {
                            if (flexibleSchedulingOn) setShowFlexibleSchedule(!showFlexibleSchedule);
                        }}>Can't find preferred time? Book flexible time</label>
                        {
                            showFlexibleSchedule &&
                            <>
                                <div className="bg-white border rounded py-3 px-4 flexy-selection">
                                    <label className="font-weight-bold">Preferred Days</label>
                                    <div className="days-selection d-flex justify-content-between">
                                        <div className="custom-input select-day">
                                            <input type="checkbox" name="monday" id="" value="Monday" checked={selectedDays.includes("Monday")} onChange={() => { handleDaysChange("Monday") }} />
                                            <label htmlFor="M">M</label>
                                        </div>
                                        <div className="custom-input select-day">
                                            <input type="checkbox" name="tuesday" id="" value="Tuesday" checked={selectedDays.includes("Tuesday")} onChange={() => { handleDaysChange("Tuesday") }} />
                                            <label htmlFor="">T</label>
                                        </div>
                                        <div className="custom-input select-day">
                                            <input type="checkbox" name="wednesday" id="" value="Wednesday" checked={selectedDays.includes("Wednesday")} onChange={() => { handleDaysChange("Wednesday") }} />
                                            <label htmlFor="">W</label>
                                        </div>
                                        <div className="custom-input select-day">
                                            <input type="checkbox" name="thursday" id="" value="Thursday" checked={selectedDays.includes("Thursday")} onChange={() => { handleDaysChange("Thursday") }} />
                                            <label htmlFor="">T</label>
                                        </div>
                                        <div className="custom-input select-day">
                                            <input type="checkbox" name="friday" id="" value="Friday" checked={selectedDays.includes("Friday")} onChange={() => { handleDaysChange("Friday") }} />
                                            <label htmlFor="">F</label>
                                        </div>
                                        <div className="custom-input select-day">
                                            <input type="checkbox" name="saturday" id="" value="Saturday" checked={selectedDays.includes("Saturday")} onChange={() => { handleDaysChange("Saturday") }} />
                                            <label htmlFor="">S</label>
                                        </div>
                                    </div>
                                    <label className="font-weight-bold mt-3">Preferred Timeslot</label>
                                    <div className="time-selection d-flex flex-wrap">
                                        <div className="select-slot custom-input mr-1">
                                            <input type="radio" name="flexi-slot" id="" value="08:00 AM - 12:00 PM" checked={selectedFlexiSlot.includes("08:00 AM - 12:00 PM")} onChange={(e) => {
                                                setSelectedFlexiSlot(e.target.value);
                                            }} />
                                            <label htmlFor="" className="border small">Before 12pm</label>
                                        </div>
                                        <div className="select-slot custom-input mr-1">
                                            <input type="radio" name="flexi-slot" id="" value="12:00 PM - 04:00 PM" checked={selectedFlexiSlot.includes("12:00 PM - 04:00 PM")} onChange={(e) => {
                                                setSelectedFlexiSlot(e.target.value);
                                            }} />
                                            <label htmlFor="" className="border small">12 pm – 4pm</label>
                                        </div>
                                        <div className="select-slot custom-input mr-1">
                                            <input type="radio" name="flexi-slot" id="" value="04:00 PM - 07:00 PM" checked={selectedFlexiSlot.includes("04:00 PM - 07:00 PM")} onChange={(e) => {
                                                setSelectedFlexiSlot(e.target.value);
                                            }} />
                                            <label htmlFor="" className="border small">4 pm onwards</label>
                                        </div>
                                        <div className="select-slot custom-input">
                                            <input type="radio" name="flexi-slot" id="" value="08:00 AM - 07:00 PM" checked={selectedFlexiSlot.includes("08:00 AM - 07:00 PM")} onChange={(e) => {
                                                setSelectedFlexiSlot(e.target.value);
                                            }} />
                                            <label htmlFor="" className="border small">Anytime</label>
                                        </div>

                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    {
                        <button disabled={!(selectedFlexiSlot && selectedDays.length > 0 && (areYouExistingPatient === true || areYouExistingPatient === false))} className="w-100 btn btn-dark mt-2 mb-2" onClick={() => {
                            setStepVisible(2);
                            window.scrollTo(0, 0)
                        }}>Add to Waitlist (Emergency/ASAP)</button>
                    }
                    {
                        <button disabled={(!(selectedSlot || (selectedFlexiSlot && selectedDays.length > 0)))} className="w-100 btn btn-primary my-2" onClick={() => {
                            if (areYouExistingPatient === null) {
                                document.getElementById("visited-yes")?.scrollIntoView();
                                return;
                            }
                            setStepVisible(2);
                            window.scrollTo(0, 0)
                        }}>Continue Booking</button>
                    }
                </div>
            }
            {
                stepVisible === 2 &&
                <LoginForm
                    setStepVisible={setStepVisible}
                    onLoginSuccess={() => {
                        setStepVisible(5);
                    }}
                ></LoginForm>
            }
            {
                stepVisible === 5 &&
                <PatientList
                    hideModal={hidePatientListModal}
                    onClickNext={(guarantorId: any, dependant: any) => {
                        setStepVisible(4);
                        setSelectedpatient(dependant);
                        setSelectedGuarantor(guarantorId);
                    }}
                />
            }
            {
                stepVisible === 3 &&
                <GuestLogin
                    onSignupSuccess={(guarantor: any, dependant: any) => {
                        setStepVisible(4);
                        setSelectedpatient(dependant);
                        selectedGuarantor(guarantor);
                    }}
                    onProceedSuccess={(dependant: any) => {
                        setStepVisible(4);
                        setSelectedpatient(dependant);
                        setSelectedGuarantor(null);
                        setIsGuest(true)
                    }}
                    hideModal={() => {
                        setStepVisible(1);
                    }}
                ></GuestLogin>
            }

            {
                stepVisible === 4 &&
                <AppointmentConfirm
                    clinicName={clinicName}
                    providerName={providerName}
                    consultationType={consultationTypes?.types?.find((item: any) => item.id === selectedConsultation) ?? ""}
                    date={selectedDate}
                    slot={selectedSlot}
                    flexibleSlot={selectedFlexiSlot}
                    day={selectedDays}
                    handleConfirm={handleConfirm}
                    patient={selectedPatient}
                ></AppointmentConfirm>
            }
        </>
    )
}

const LoginForm = ({
    onLoginSuccess,
    setStepVisible
}: any) => {
    const { register, setError, handleSubmit, setValue, reset, getValues, errors, trigger } = useForm();
    const [showLogin, setShowLogin] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [askFor, setAskFor] = useState("");
    const [isShowOTPModal, setIsShowOTPModal] = useState(false);
    const [patientId, setPatientId] = useState();
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();
    const [isRegister, setIsRegister] = useState(false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [showForgotPasswordOtp, setShowForgotPasswordOtp] = useState(false);
    const [showForgotPasswordReset, setShowForgotPasswordReset] = useState(false);
    const [patientOtpId, setPatientOtpId] = useState("");
    const [patientIdReset, setPatientPatientIdReset] = useState("");
    const [frgtObj, setFrgtObj] = useState(null);
    const emailOrPhoneBlur = async (e: any) => {
        try {
            const isValid = await trigger('emailorphone');
            if (isValid) {
                if (isNaN(getValues('emailorphone'))) {
                    if (!emailValidation(getValues('emailorphone'))) {
                        setError('emailorphone', { type: 'custom', message: 'Invalid email' });
                        return;
                    }
                } else {
                    if (getValues('emailorphone').length > 10 || getValues('emailorphone').length < 10) {
                        setError('emailorphone', { type: 'custom', message: 'Invalid phone' });
                        return;
                    }
                }
                setIsLoading(true);
                checkLogin({ login: getValues('emailorphone') }).then((success) => {
                    setIsLoading(false);
                    if (success && success.status && success.status.error === false) {
                        setAskFor(success.askFor);
                    }
                }).catch((err) => {
                    setIsLoading(false);
                    console.log(err);
                    setError('emailorphone', { type: 'custom', message: err.msg });
                });
            }
        } catch (err) {
            console.log(err);
        }
    }
    const onLoginClick = (data: any) => {
        if (getValues('password')) {
            let requestData: any = {
                password: getValues('password')
            };
            if (isNaN(getValues('emailorphone'))) {
                requestData = {
                    ...requestData,
                    email: getValues('emailorphone'), phone: getValues('phone')
                }
            } else {
                requestData = {
                    ...requestData,
                    phone: getValues('emailorphone'), email: getValues('email')
                };
            }
            setIsLoading(true);
            patientLoginNew(requestData).then((success) => {
                setIsLoading(false);
                if (success && success.status && success.status.error === false) {
                    if (success.phone !== null && success.phoneVerified === null && success.email !== null && success.emailVerified === null) {
                        setPhone(success.phone);
                        setEmail(success.email);
                        setPatientId(success.id);
                        setIsShowOTPModal(true);
                        setShowLogin(false);
                        return;
                    } else if (success.phone !== null && success.phoneVerified === null) {
                        setPhone(success.phone);
                        setPatientId(success.id);
                        setIsShowOTPModal(true);
                        setShowLogin(false);
                        return;
                    } else if (success.email !== null && success.emailVerified === null) {
                        setEmail(success.email);
                        setPatientId(success.id);
                        setIsShowOTPModal(true);
                        setShowLogin(false);
                        return;
                    }
                    postLoginAction(success);
                }
            }).catch((err) => {
                setIsLoading(false);
                setError('password', { type: 'custom', message: err.msg });
                console.log(err);
            });
        }
    };
    const postLoginAction = (data: any) => {
        setIsLoading(true);
        signInWithCustomToken(data.jwtToken).then((response) => {
            setIsLoading(false);
            if (response) {
                const key1 = 'xprUser.patient-' + data.id;
                const key2 = 'xpr_user_id';
                const key3 = 'xprAuthUser';
                const key4 = 'xprUserName';
                const key5 = 'jwtToken';
                const key6 = 'xprIsGuarantor';
                var json: any = {};
                json[key1] = true;
                json[key2] = data.uniqueId;
                json[key3] = data.authParam;
                json[key4] = data.firstName + " " + data.lastName;
                json[key5] = data.jwtToken;
                json[key6] = data.isGuarantor;
                Cookies.remove('patient_cookie');
                Cookies.remove('patient_cookie', { domain: configs.COOKIE_DOMAIN });
                Cookies.set('patient_cookie', json, { domain: configs.COOKIE_DOMAIN });
                Cookies.set('patient_cookie', json);
                // history.replace('/');

                //TODO handle login success here
                onLoginSuccess(4);
            } else {
                toast.error('Something went wrong');
            }
        }).catch((error) => {
            setIsLoading(false);
            toast.error('Something went wrong');
        });
    }

    const handleOnForgotPasswordOtpSuccess = (patientId: any) => {
        setShowForgotPasswordOtp(false);
        setShowForgotPasswordReset(true);
        setPatientPatientIdReset(patientId);
    }
    const handleOnForgotPasswordResetSuccess = () => {
        setShowLogin(true);
        setShowForgotPasswordReset(false);
    }
    const handleOnForgotPasswordSuccess = (otpId: any, frgtObj: any) => {
        setShowForgotPassword(false);
        setShowForgotPasswordOtp(true);
        setPatientOtpId(otpId);
        setFrgtObj(frgtObj);
    }
    return (
        <>
            {
                showLogin &&
                <div className="authentication-box px-3 py-4 mt-4">
                    <div><button className="btn p-0 text-primary" onClick={() => {
                        setStepVisible(1);
                    }}>Back</button></div>
                    <h3 className="text-center mb-3">Sign in</h3>
                    <form action="">
                        <div className="form-group">
                            <label>Email or mobile no</label>
                            <input type="text" disabled={isLoading} className="form-control" name="emailorphone" ref={register({ required: true })} onBlur={emailOrPhoneBlur} />
                            {
                                (errors && errors.emailorphone && errors.emailorphone.type === 'required') &&
                                <div className="text-danger error">Email or phone is required.</div>
                            }
                            {
                                (errors && errors.emailorphone && errors.emailorphone.type === 'custom') &&
                                <div className="text-danger error">{errors.emailorphone.message}</div>
                            }
                        </div>
                        {
                            askFor === "email" &&
                            <div className="form-group">
                                <p>
                                    We have detected multiple accounts with this mobile number, please enter mobile number associated with this account to sign in.
                                    In case if you do not have mobile number associated with this account please enter password directly to sign in
                                </p>
                                <label className="label-form">{LABELS.EMAIL}</label>
                                <input type="text" disabled={isLoading} className="form-control" name="email" ref={register({
                                    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                })} />
                                {
                                    (errors && errors.email && errors.email.type === 'pattern') &&
                                    <div className="text-danger error">Email is invalid</div>
                                }
                            </div>
                        }
                        {
                            askFor === "phone" &&
                            <div className="form-group">
                                <p>
                                    We have detected multiple accounts with this email, please enter email associated with this account to sign in.
                                    In case if you do not have email associated with this account please enter password directly to sign in
                                </p>
                                <label className="label-form">{LABELS.PHONE}</label>
                                <input type="text" disabled={isLoading} className="form-control" name="phone" ref={register({
                                    maxLength: 10, minLength: 10
                                })} />
                                {
                                    (errors && errors.phone && errors.phone.type === 'maxLength') &&
                                    <div className="text-danger error">Mobile number is invalid</div>
                                }
                                {
                                    (errors && errors.phone && errors.phone.type === 'minLength') &&
                                    <div className="text-danger error">Mobile number is invalid</div>
                                }
                            </div>
                        }
                        {
                            (askFor === "password" || askFor === "email" || askFor === "phone") &&
                            <div className="form-group">
                                <label className="label-form">{LABELS.PASSWORD}</label>
                                <input type="password" disabled={isLoading} className="form-control" name={'password'} ref={register({ required: true })} />
                                {
                                    (errors && errors.password && errors.password.type === 'required') &&
                                    <div className="text-danger error">Password is required.</div>
                                }
                                {
                                    (errors && errors.password && errors.password.type === 'custom') &&
                                    <div className="text-danger error">{errors.password.message}</div>
                                }
                            </div>
                        }
                        <button type="submit" className="btn btn-primary w-100 btn-lg mb-3" onClick={handleSubmit(onLoginClick)}>Sign in</button>
                        <div className="d-flex justify-content-between">
                            <a href="javascript:void(0);" className="small" onClick={() => {
                                setIsRegister(true);
                                setShowLogin(false);
                            }}>Sign up</a>
                            <a href="javacript:void(0);" className="small" onClick={() => {
                                setShowLogin(false);
                                setShowForgotPassword(true);
                            }}>Forgot Password</a>
                        </div>
                        <hr />
                        <div className="text-center">Not ready to sign up yet !!!</div>
                        <a href="javascript:void(0)" className="btn btn-dark btn-lg w-100 my-4" onClick={() => {
                            setStepVisible(3);
                        }}>Proceed as Guest</a>
                        <div className="text-center">You can still decide to sign up at the end of the appointment
                            booking process if desired</div>
                    </form>
                </div>
            }

            {
                isShowOTPModal &&
                <OTPModal
                    hideModal={(dontShowLogin = false) => {
                        setIsShowOTPModal(false);
                        if (!dontShowLogin) {
                            setShowLogin(true);
                        }
                        reset();
                    }}
                    sentEmail={email}
                    sentPhone={phone}
                    patientId={patientId}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    codeVerifySuccessLogin={postLoginAction}
                ></OTPModal>
            }

            {
                isRegister &&
                <Register
                    hideModal={() => {
                        setIsRegister(false);
                        setShowLogin(true);
                    }}
                    onRegisterSuccess={() => {
                        // TODO Register Success
                        onLoginSuccess();
                        window.scrollTo(0,0);
                    }}
                    clickBackToSignIn={() => {
                        setIsRegister(false);
                        setShowLogin(true);
                    }}
                ></Register>
            }

            {
                showForgotPassword &&
                <ForgotPassword
                    hideModal={() => {
                        setShowLogin(true);
                        setShowForgotPassword(false);
                    }}
                    onSuccess={handleOnForgotPasswordSuccess}
                    backToSignin={() => {
                        setShowForgotPassword(false);
                        setShowLogin(true);
                    }}
                    backToRegister={() => {
                        setShowForgotPassword(false);
                        setIsRegister(true);
                        setShowLogin(false);
                    }}
                ></ForgotPassword>
            }

            {
                showForgotPasswordOtp && patientOtpId !== "" &&
                <ForgotPasswordOtp
                    frgtObj={frgtObj}
                    patientOtpId={patientOtpId}
                    hideModal={() => {
                        setShowLogin(true);
                        setShowForgotPasswordOtp(false);
                    }}
                    onSuccess={handleOnForgotPasswordOtpSuccess}
                ></ForgotPasswordOtp>
            }

            {
                showForgotPasswordReset && patientIdReset !== "" &&
                <ResetPassword
                    patientIdReset={patientIdReset}
                    hideModal={() => {
                        setShowLogin(true);
                        setShowForgotPasswordReset(false);
                    }}
                    onSuccess={handleOnForgotPasswordResetSuccess}
                ></ResetPassword>
            }
        </>
    )
}

const OTPModal = ({
    hideModal,
    sentPhone,
    isLoading,
    setIsLoading,
    patientId,
    codeVerifySuccessLogin,
    sentEmail
}: any) => {
    const [verificationCode, setVerificationCode] = useState('');
    const [verificationCodeEmail, setVerificationCodeEmail] = useState('');
    const [verificationCodeFalse, setVerificationCodeFalse] = useState('');
    const verifyCodeFunc = () => {
        if (patientId) {
            setIsLoading(true);
            const obj = {};
            if (verificationCode === '' && verificationCodeEmail === '') {
                setVerificationCodeFalse('Please enter code for atleat one.');
                return;
            }
            if (sentPhone) {
                Object.assign(obj, { code: verificationCode });
            }
            if (sentEmail) {
                Object.assign(obj, { ecode: verificationCodeEmail });
            }
            verifyCode(obj, patientId).then((success) => {
                setIsLoading(false);
                if (success && success.status && success.status.error === false) {
                    codeVerifySuccessLogin(success);
                    hideModal(true);
                    return;
                }
                setVerificationCodeFalse('Something went wrong');
            }).catch((err) => {
                setIsLoading(false);
                setVerificationCodeFalse(err.msg);
                console.log(err);
            })
        }
    }
     const sendVerificationCodeEmail = () => {
        if (patientId) {
            setIsLoading(true);
            axios.post(configs.API_URL + 'register1/' + patientId + '/resend/email/', {
                email: sentEmail
            }).then((success) => {
                setIsLoading(false);
                if (success.status === 200) {
                    if (success.data.status.error === false) {
                        toast.success(success.data.status.msg);
                        setVerificationCodeFalse('');
                    } else {
                        toast.error(success.data.status.msg);
                    }
                }
            }, (error) => {
                setIsLoading(false);
                toast.error('Something went wrong');
            });
        }
    }
    const sendVerificationCode = () => {
        if (sentPhone && patientId) {
            setIsLoading(true);
            axios.post(configs.API_URL + 'register1/' + patientId + '/resend/phone/', {
                phone: sentPhone
            }).then((success) => {
                setIsLoading(false);
                if (success.status === 200) {
                    if (success.data.status.error === false) {
                        toast.success(success.data.status.msg);
                        setVerificationCodeFalse('');
                    } else {
                        toast.error(success.data.status.msg);
                    }
                }
            }, (error) => {
                setIsLoading(false);
                toast.error('Something went wrong');
            });
        }
    }
    return (
        <div className="authentication-box popup-form text-center p-5">
            <div className="d-flex justify-content-between">
                <div>
                    <h3 className="text-center mb-3">OTP Verification</h3>
                </div>
                <div>
                    <div><button className="btn p-0 text-primary" onClick={() => {
                        hideModal();
                    }}>Back</button></div>
                </div>
            </div>
            <div className="popup-form text-center">
                <p>
                    {
                        (sentPhone) && (sentEmail) &&
                        <>We have sent a verification code, <br/>please check your mobile and email.</>
                    }
                    {
                        (sentPhone) && (!sentEmail) &&
                        <>We have sent a verification code, <br/>please check your mobile.</>
                    }
                    {
                        (sentEmail) && (!sentPhone) &&
                        <>We have sent a verification code, <br/>please check your email.</>
                    }
                </p>
                {
                    sentPhone &&
                    <>
                        <label className="form-label text-center d-block">Enter the OTP here for mobile number verification</label>
                        <div className="code_input text-center">
                            <PinInput
                                length={4}
                                initialValue={""}
                                onChange={(value, index) => {
                                    setVerificationCode(value);
                                }}
                                type="numeric"
                                style={{ padding: '10px' }}
                                inputStyle={{}}
                                inputFocusStyle={{ borderColor: '#000000' }}
                                onComplete={(value, index) => {
                                    setVerificationCode(value);
                                }}
                            />
                            {
                                <button className="btn btn-link fs-7" onClick={(e) => { sendVerificationCode() }}>
                                    Resend Code
                                </button>
                            }
                        </div>
                    </>
                }
                {
                    sentEmail &&
                    <>
                        <label className="form-label text-center d-block">Enter the OTP here for email verification</label>
                        <div className="code_input text-center">
                            <PinInput
                                length={4}
                                initialValue={""}
                                onChange={(value, index) => {
                                    setVerificationCodeEmail(value);
                                }}
                                type="numeric"
                                style={{ padding: '10px' }}
                                inputStyle={{}}
                                inputFocusStyle={{ borderColor: '#000000' }}
                                onComplete={(value, index) => {
                                    setVerificationCodeEmail(value);
                                }}
                            />
                            {
                                <button className="btn btn-link fs-7" onClick={(e) => { sendVerificationCodeEmail() }}>
                                    Resend Code
                                </button>
                            }
                        </div>
                    </>
                }
                {
                    verificationCodeFalse !== '' &&
                    <div className="text-danger error">{verificationCodeFalse}</div>
                }
                <button className="btn btn-primary send-btn"
                    onClick={(e) => { verifyCodeFunc() }}
                    disabled={((sentEmail && verificationCodeEmail.length !== 4) || (sentPhone && verificationCode.length !== 4)) || isLoading}>
                    Submit
                </button>
            </div>
        </div>
    )
}

const Register = ({
    hideModal,
    onRegisterSuccess,
    clickBackToSignIn
}: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isShowOTPModal, setIsShowOTPModal] = useState(false);
    const [patientId, setPatientId] = useState();
    const [phone, setPhone]: any = useState('');
    const [email, setEmail]: any = useState('');
    const dispatch = useDispatch();
    const [isRegister, setIsRegister] = useState(true);
    const { register, setError, handleSubmit, setValue, getValues, errors, trigger, control, watch } = useForm({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            dob: moment().subtract(13, 'years'),
            gender: 'Male',
            password: '',
            cpassword: '',
            globalError: '',
        }
    });
    const onRegisterClick = (data: any) => {
        if (getValues('email') === "" && getValues('phone') === "") {
            setError('globalError', { type: 'custom', message: 'Either email or phone is required.' });
            return;
        } /* else if (getValues('dob') && getAge(moment(getValues('dob')).format("YYYY-MM-DD")) < 13) {
            setError('globalError', { type: 'custom', message: 'You need to be 13 years and older to sign up.' });
            return;
        }  */else if (getValues('password') !== getValues('cpassword')) {
            setError('globalError', { type: 'custom', message: 'Password and confirm password should be same.' });
            return;
        }
        console.log("here", data);
        setError('globalError', {});
        let requestedData = {
            isIndividual: true,
            fname: getValues('firstName'),
            lname: getValues('lastName'),
            email: getValues('email'),
            phone: getValues('phone'),
            dob: moment(getValues('dob')).format('YYYY-MM-DD'),
            registeras: "normal",
            password: getValues('password'),
            gender: getValues('gender'),
            contactMethod: 'email,text',
            reminder: 'yes',
            request: {
                paymode: 'cash,credit,card,cheque'
            },
        }
        try {
            setIsLoading(true);
            registerUser(requestedData).then((success) => {
                setIsLoading(false);
                if (success && success.status && success.status.error === false) {
                    setPhone(getValues('phone'));
                    setEmail(getValues('email'));
                    setPatientId(success.id);
                    setIsShowOTPModal(true);
                    setIsRegister(false);
                    window.scrollTo(0, 0)
                }
            }).catch((err) => {
                setIsLoading(false);
                setError('globalError', { type: 'custom', message: err.msg ? err.msg : 'Theres something wrong.' });
            });
        } catch (err) {
            setIsLoading(false);
            setError('globalError', { type: 'custom', message: err.msg ? err.msg : 'Theres something wrong.' });
        }
    }
    const postLoginAction = (data: any) => {
        setIsLoading(true);
        signInWithCustomToken(data.jwtToken).then((response) => {
            setIsLoading(false);
            if (response) {
                const key1 = 'xprUser.patient-' + data.id;
                const key2 = 'xpr_user_id';
                const key3 = 'xprAuthUser';
                const key4 = 'xprUserName';
                const key5 = 'jwtToken';
                const key6 = 'xprIsGuarantor';
                var json: any = {};
                json[key1] = true;
                json[key2] = data.uniqueId;
                json[key3] = data.authParam;
                json[key4] = data.firstName + " " + data.lastName;
                json[key5] = data.jwtToken;
                json[key6] = data.isGuarantor;
                Cookies.remove('patient_cookie');
                Cookies.remove('patient_cookie', { domain: configs.COOKIE_DOMAIN });
                Cookies.set('patient_cookie', json, { domain: configs.COOKIE_DOMAIN });
                Cookies.set('patient_cookie', json);
                // history.replace('/');
                setIsLoading(false);
                setIsShowOTPModal(false);
                //TODO Register  success

                onRegisterSuccess();
            } else {
                setIsLoading(false);
                setError('globalError', { type: 'custom', message: 'Theres something wrong.' });
            }
        }).catch((error) => {
            setIsLoading(false);
            setError('globalError', { type: 'custom', message: 'Theres something wrong.' });
        });
    }
    console.log("errors", errors);
    return (
        <>
            {
                isRegister &&
                <div className="authentication-box px-3 py-4 mt-4">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h3 className="text-center mb-3">Sign Up</h3>
                        </div>
                        <div>
                            <div><button className="btn p-0 text-primary" onClick={() => {
                                hideModal();
                            }}>Back</button></div>
                        </div>
                    </div>
                    <form action="">

                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">First name</label>
                            <input type="text" className="form-control" name="firstName" placeholder="Michael" ref={register({ required: true })} />
                            {
                                (errors && errors.firstName && errors.firstName.type === 'required') &&
                                <div className="is-invalid">First name is required</div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Last Name</label>
                            <input type="text" className="form-control" name="lastName" placeholder="Philips" ref={register({ required: true })} />
                            {
                                (errors && errors.lastName && errors.lastName.type === 'required') &&
                                <div className="is-invalid">Last name is required</div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Email Address</label>
                            <input type="email" className="form-control" name="email" placeholder="michael@gmail.com" ref={register({
                                required: true,
                                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            })} />
                            {
                                (errors && errors.email && errors.email.type === 'required') &&
                                <div className="is-invalid">Email is required</div>
                            }
                            {
                                (errors && errors.email && errors.email.type === 'pattern') &&
                                <div className="is-invalid">Email is invalid</div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Mobile number</label>
                            <input type="text" className="form-control" placeholder="Ex. ### ### ####" name="phone" maxLength={10} ref={register({ required: true, minLength: 10, maxLength: 10 })} />
                            {
                                (errors && errors.phone && errors.phone.type === 'required') &&
                                <div className="is-invalid">Mobile number is required</div>
                            }
                            {
                                (errors && errors.phone && errors.phone.type === 'minLength') &&
                                <div className="is-invalid">Mobile number is invalid</div>
                            }
                            {
                                (errors && errors.phone && errors.phone.type === 'maxLength') &&
                                <div className="is-invalid">Mobile number is invalid</div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Gender</label>
                            <select className="form-control" name="gender" ref={register({ required: true })}>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Gender neutral</option>
                                <option value="Prefer not to respond">Prefer not to respond</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label className="d-block" htmlFor="exampleInputEmail1">Date of Birth</label>
                            <Controller
                                name="dob"
                                control={control}
                                defaultValue={moment().subtract(13, 'years')}
                                rules={{
                                    required: true
                                }}
                                render={(props) => (
                                    <KeyboardDatePicker
                                        id="date-picker-dialog"
                                        format="YYYY/MM/DD"
                                        name="dob"
                                        value={props.value}
                                        maxDate={moment().subtract(13, 'years')}
                                        onChange={(date: any) => {
                                            props.onChange(date)
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                )}
                            ></Controller>
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Password</label>
                            <input type="password" className="form-control" name="password" placeholder="Enter password" ref={register({
                                required: true,
                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
                            })} />
                            {
                                (errors && errors.password && errors.password.type === 'required') &&
                                <div className="is-invalid">Password is required</div>
                            }
                            {
                                (errors && errors.password && errors.password.type === 'pattern') &&
                                <div className="is-invalid">Password must contain 8 characters with at least 1 upper case, 1 lower case, 1 special and 1 numeric character.</div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Re-type Password</label>
                            <input type="password" className="form-control" name="cpassword" placeholder="Re-type password" ref={register({
                                required: true,
                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
                            })} />
                            {
                                (errors && errors.cpassword && errors.cpassword.type === 'required') &&
                                <div className="is-invalid">Confirm Password is required</div>
                            }
                            {
                                (errors && errors.cpassword && errors.cpassword.type === 'pattern') &&
                                <div className="is-invalid">Password must contain 8 characters with at least 1 upper case, 1 lower case, 1 special and 1 numeric character.</div>
                            }
                        </div>
                        {
                            (errors && errors.globalError && errors.globalError.type === 'custom') &&
                            <div className="text-danger error">{errors.globalError.message}</div>
                        }
                        <label className="mb-3 d-block"><b>Note:</b> Option to add minor/spouse or any dependent family member will be available after the sign up step.</label>
                        <button type="submit" disabled={isLoading} onClick={handleSubmit(onRegisterClick)} className="btn btn-primary w-100 btn-lg"> <span className="aft_load right">
                            <>
                                {
                                    isLoading &&
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                }
                            </>
                        </span> Sign Up & Continue</button>
                    </form>
                </div>}
            {
                isShowOTPModal &&
                <OTPModal hideModal={() => {
                    setIsShowOTPModal(false);
                }}
                    sentEmail={email}
                    sentPhone={phone}
                    patientId={patientId}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    codeVerifySuccessLogin={postLoginAction}
                ></OTPModal>
            }
        </>
    )
}

const ForgotPassword = ({
    onSuccess,
    hideModal,
    backToRegister,
    backToSignin
}: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const { register, setError, handleSubmit, setValue, getValues, errors, trigger, watch } = useForm();
    const [askFor, setAskFor] = useState("");
    const history = useHistory();
    const location = useLocation();
    const [isSingleAccount, setIsSingleAccount]: any = useState('');
    const [emailOrPhoneState, setEmailOrPhoneState]: any = useState('');
    const onClickForgotPassword = (data: any) => {
        console.log('call', isSingleAccount, data);
        let requestData: any = {
            modelType: 'patient'
        };
        if (isNaN(getValues('emailorphone'))) {
            requestData = {
                ...requestData,
                email: getValues('emailorphone'), mobile: getValues('phone') ? getValues('phone') : '', isSingleAccount: isSingleAccount
            }
        } else {
            requestData = {
                ...requestData,
                mobile: getValues('emailorphone'), email: getValues('email') ? getValues('email') : '', isSingleAccount: isSingleAccount
            };
        }
        let obj = {};
        if (isNaN(getValues('emailorphone'))) {
            obj = {
                ...obj,
                email: getValues('emailorphone'), phone: getValues('phone'), isSingleAccount: isSingleAccount
            }
        } else {
            obj = {
                ...obj,
                phone: getValues('emailorphone'), email: getValues('email'), isSingleAccount: isSingleAccount
            };
        }
        if (isSingleAccount !== "") {
            setIsLoading(true);
            forgotPassword(requestData).then((success) => {
                setIsLoading(false);
                if (success && success.status && success.status.error === false) {
                    toast.success(success.status.msg);
                    console.log(obj);
                    onSuccess(success.status.patientotpId, obj);
                }
            }).catch((err: any) => {
                console.log(err);
                setIsLoading(false);
                setError('emailorphone', { type: 'custom', message: err.msg ? err.msg : 'Something went wrong' });
            });
        }
    }

    const callForgotNew = (isSingleAccount: boolean) => {
        console.log("emailOrPhoneState", emailOrPhoneState)
        let requestData: any = {
            modelType: 'patient'
        };
        if (isNaN(emailOrPhoneState)) {
            requestData = {
                ...requestData,
                email: emailOrPhoneState, mobile: '', isSingleAccount: isSingleAccount
            }
        } else {
            requestData = {
                ...requestData,
                mobile: emailOrPhoneState, email: '', isSingleAccount: isSingleAccount
            };
        }
        let obj = {};
        if (isNaN(emailOrPhoneState)) {
            obj = {
                ...obj,
                email: emailOrPhoneState, isSingleAccount: isSingleAccount
            }
        } else {
            obj = {
                ...obj,
                phone: emailOrPhoneState, isSingleAccount: isSingleAccount
            };
        }
        setIsLoading(true);
        forgotPassword(requestData).then((success) => {
            setIsLoading(false);
            if (success && success.status && success.status.error === false) {
                toast.success(success.status.msg);
                onSuccess(success.status.patientotpId, obj);
            }
        }).catch((err: any) => {
            console.log(err);
            setIsLoading(false);
            setError('emailorphone', { type: 'custom', message: err.msg ? err.msg : 'Something went wrong' });
        });
    }

    const emailOrPhoneBlur = async (e: any) => {
        try {
            const isValid = await trigger('emailorphone');
            if (isValid) {
                if (isNaN(getValues('emailorphone'))) {
                    if (!emailValidation(getValues('emailorphone'))) {
                        setError('emailorphone', { type: 'custom', message: 'Invalid email' });
                        return;
                    }
                } else {
                    if (getValues('emailorphone').length > 10 || getValues('emailorphone').length < 10) {
                        setError('emailorphone', { type: 'custom', message: 'Invalid phone' });
                        return;
                    }
                }
                setIsLoading(true);
                checkForgotpasswordAccount({ login: getValues('emailorphone'), modelType: 'patient' }).then((success) => {
                    setIsLoading(false);
                    if (success && success.status && success.status.error === false) {
                        setAskFor(success.askFor);
                        setIsSingleAccount(success.isSingleAccount);
                        if (success.isSingleAccount) {
                            callForgotNew(success.isSingleAccount);
                        }
                    }
                }).catch((err) => {
                    setIsLoading(false);
                    console.log(err);
                    setError('emailorphone', { type: 'custom', message: err.msg });
                });
            }
        } catch (err) {
            console.log(err);
        }
    }
    useEffect(() => {
        setIsSingleAccount("");
        setEmailOrPhoneState(getValues('emailorphone'));
    }, [watch('emailorphone')])
    return (
        <div className="authentication-box px-3 py-4 mt-4">
            <div className="d-flex justify-content-between">
                <div>
                    <h3 className="text-center mb-3">Forgot password</h3>
                </div>
                <div>
                    <div><button className="btn p-0 text-primary" onClick={() => {
                        hideModal();
                    }}>Back</button></div>
                </div>
            </div>
            <div>Enter the email address or mobile number associated with your account.</div>
            <form action="">
                <div className="form-group">
                    {/* <label>Email or mobile no</label> */}
                    <input type="email" disabled={isLoading} className="form-control" placeholder="michael@gmail.com" name="emailorphone" ref={register({ required: true })} onBlur={emailOrPhoneBlur} />
                    {
                        (errors && errors.emailorphone && errors.emailorphone.type === 'required') &&
                        <div className="text-danger error">Email or phone is required.</div>
                    }
                    {
                        (errors && errors.emailorphone && errors.emailorphone.type === 'custom') &&
                        <div className="text-danger error">{errors.emailorphone.message}</div>
                    }
                </div>
                {
                    askFor === "email" &&
                    <div className="mb-3">
                        <p>
                            We have detected multiple accounts with this mobile number, please enter email associated with this account to forgot password.
                            In case if you do not have email associated with this account please directly hit button.
                        </p>
                        <label className="form-label fw-medium">{LABELS.EMAIL}</label>
                        <input type="text" disabled={isLoading} className="form-control" name="email" ref={register({
                            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        })} />
                        {
                            (errors && errors.email && errors.email.type === 'pattern') &&
                            <div className="text-danger error">Email is invalid</div>
                        }
                    </div>
                }
                {
                    askFor === "phone" &&
                    <div className="mb-3">
                        <p>
                            We have detected multiple accounts with this email, please enter mobile number associated with this account to forgot password.
                            In case if you do not have mobile number associated with this account please directly hit button.
                        </p>
                        <label className="form-label fw-medium">{LABELS.PHONE}</label>
                        <input type="text" disabled={isLoading} className="form-control" name="phone" ref={register({
                            maxLength: 10, minLength: 10
                        })} />
                        {
                            (errors && errors.phone && errors.phone.type === 'maxLength') &&
                            <div className="text-danger error">Mobile number is invalid</div>
                        }
                        {
                            (errors && errors.phone && errors.phone.type === 'minLength') &&
                            <div className="text-danger error">Mobile number is invalid</div>
                        }
                    </div>
                }
                <button type="submit" className="btn btn-primary w-100 btn-lg mb-3" onClick={handleSubmit(onClickForgotPassword)}>
                    <span>
                        <>
                            {
                                isLoading === true &&
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="ms-2"
                                />
                            }
                        </>
                    </span>
                    Send
                </button>
                <div className="d-flex justify-content-between">
                    <span className="small">Don't have an account? <a href="javascript:void(0);" onClick={backToRegister}>Sign up</a></span>
                    <a href="javascript:void(0);" className="small" onClick={backToSignin}>Sign in</a>
                </div>
            </form>
        </div>
    )
}

export const ForgotPasswordOtp = ({
    patientOtpId, hideModal, onSuccess, frgtObj
}: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const { register, setError, handleSubmit, setValue, getValues, errors, trigger } = useForm();
    const onOtpVerifyClick = (data: any) => {
        if (patientOtpId) {
            setIsLoading(true);
            const obj = {
                patientotpId: patientOtpId,
                modelType: "verifyOtp"
            };
            if (frgtObj && frgtObj.email) {
                Object.assign(obj, {
                    otpeverify: getValues('eotp')
                });
            }
            if (frgtObj && frgtObj.phone) {
                Object.assign(obj, {
                    otppverify: getValues('potp')
                });
            }
            verifyOtpForgotpassword(obj).then((success) => {
                setIsLoading(false);
                if (success && success.status && success.status.error === false) {
                    onSuccess(success.status.patientuniqueId);
                } else {
                    toast.error(success.status.msg);
                }
            }).catch((err) => {
                setIsLoading(false);
                toast.error(err.msg);
            });
        } else {
            toast.error("Invalid request");
        }
    }
    return (
        <div className="authentication-box p-4">
            <div className="d-flex justify-content-between">
                <div>
                    <h3 className="text-center mb-3">OTP Forgot Password</h3>
                </div>
                <div>
                    <div><button className="btn p-0 text-primary" onClick={() => {
                        hideModal();
                    }}>Back</button></div>
                </div>
            </div>
            <div className={"mb-3 " + ((frgtObj && frgtObj.email) ? '' : 'd-none')}>
                <label className="form-label fw-medium">Enter the OTP here for email verification.</label>
                <input type="text" disabled={isLoading} className="form-control" name="eotp" placeholder="1234" maxLength={4} ref={register({
                    required: (frgtObj && frgtObj.email) ? true : false,
                    minLength: 4, maxLength: 4,
                    pattern: /^[0-9]+$/
                })} />
                {
                    (errors && errors.eotp && errors.eotp.type === 'required') &&
                    <div className="text-danger error">OTP is required.</div>
                }
                {
                    (errors && errors.eotp && errors.eotp.type === 'pattern') &&
                    <div className="text-danger error">Only number allowed.</div>
                }
                {
                    (errors && errors.eotp && errors.eotp.type === 'minLength') &&
                    <div className="text-danger error">Min length is 4.</div>
                }
                {
                    (errors && errors.eotp && errors.eotp.type === 'maxLength') &&
                    <div className="text-danger error">Max length is 4.</div>
                }
                {
                    (errors && errors.eotp && errors.eotp.type === 'custom') &&
                    <div className="text-danger error">{errors.eotp.message}</div>
                }
            </div>
            <div className={"mb-3 " + ((frgtObj && frgtObj.phone) ? '' : 'd-none')}>
                <label className="form-label fw-medium">Enter the OTP here for mobile number verification.</label>
                <input type="text" disabled={isLoading} className="form-control" name="potp" placeholder="1234" maxLength={4} ref={register({
                    required: (frgtObj && frgtObj.phone) ? true : false,
                    minLength: 4, maxLength: 4,
                    pattern: /^[0-9]+$/
                })} />
                {
                    (errors && errors.potp && errors.potp.type === 'required') &&
                    <div className="text-danger error">OTP is required.</div>
                }
                {
                    (errors && errors.potp && errors.potp.type === 'pattern') &&
                    <div className="text-danger error">Only number allowed.</div>
                }
                {
                    (errors && errors.potp && errors.potp.type === 'minLength') &&
                    <div className="text-danger error">Min length is 4.</div>
                }
                {
                    (errors && errors.potp && errors.potp.type === 'maxLength') &&
                    <div className="text-danger error">Max length is 4.</div>
                }
                {
                    (errors && errors.potp && errors.potp.type === 'custom') &&
                    <div className="text-danger error">{errors.potp.message}</div>
                }
            </div>
            <button type="button" className="btn btn-dark w-100" onClick={handleSubmit(onOtpVerifyClick)}>
                <span className="aft_load right">
                    <>
                        {
                            isLoading === true &&
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        }
                    </>
                </span>
                Verify
            </button>
        </div>
    )
}

export const ResetPassword = ({
    patientIdReset, hideModal, onSuccess
}: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const { register, setError, handleSubmit, setValue, getValues, errors, trigger } = useForm();
    const resetPasswordClick = (data: any) => {
        if (patientIdReset) {
            if (getValues('newpassword').trim() !== getValues('cnewpassword').trim()) {
                setError('cnewpassword', { type: 'custom', message: 'Both password should match.' });
                return;
            }
            setIsLoading(true);
            resetPasswordApi({
                patientuniqueId: patientIdReset,
                newpwd: getValues('newpassword').trim(),
                modelType: "resetpwd"
            }).then((success) => {
                setIsLoading(false);
                if (success && success.status && success.status.error === false) {
                    toast.success('Reset password success');
                    onSuccess();
                } else {
                    toast.error(success.status.msg);
                }
            }).catch((err) => {
                setIsLoading(false);
                toast.error(err.msg);
            });
        } else {
            toast.error("Invalid request");
        }
    }
    return (
        <div className="authentication-box p-4">
            <div className="d-flex justify-content-between">
                <div>
                    <h3 className="text-center mb-3">Reset password</h3>
                </div>
                <div>
                    <div><button className="btn p-0 text-primary" onClick={() => {
                        hideModal();
                    }}>Back</button></div>
                </div>
            </div>
            <div className="mb-3">
                <label className="form-label fw-medium">New password</label>
                <input type="password" disabled={isLoading} className="form-control" name={'newpassword'} ref={register({
                    required: true,
                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
                })} />
                {
                    (errors && errors.newpassword && errors.newpassword.type === 'required') &&
                    <div className="text-danger error">New password is required.</div>
                }
                {
                    (errors && errors.newpassword && errors.newpassword.type === 'pattern') &&
                    <div className="text-danger error">Password must contain 8 characters with at least 1 upper case, 1 lower case, 1 special and 1 numeric character.</div>
                }
                {
                    (errors && errors.newpassword && errors.newpassword.type === 'custom') &&
                    <div className="text-danger error">{errors.newpassword.message}</div>
                }
            </div>
            <div className="mb-3">
                <label className="form-label fw-medium">Confirm new password</label>
                <input type="password" disabled={isLoading} className="form-control" name={'cnewpassword'} ref={register({
                    required: true,
                    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
                })} />
                {
                    (errors && errors.cnewpassword && errors.cnewpassword.type === 'required') &&
                    <div className="text-danger error">Confirm new password is required.</div>
                }
                {
                    (errors && errors.cnewpassword && errors.cnewpassword.type === 'pattern') &&
                    <div className="text-danger error">Password must contain 8 characters with at least 1 upper case, 1 lower case, 1 special and 1 numeric character.</div>
                }
                {
                    (errors && errors.cnewpassword && errors.cnewpassword.type === 'custom') &&
                    <div className="text-danger error">{errors.cnewpassword.message}</div>
                }
            </div>
            <div>
                <b>Note: </b> <label>For security reasons you will be asked to sign in again.</label>
            </div>
            <button type="button" className="btn btn-dark w-100" onClick={handleSubmit(resetPasswordClick)}>Reset password
                <span className="aft_load right">
                    <>
                        {
                            isLoading === true &&
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        }
                    </>
                </span>
            </button>
        </div>
    )
}

const GuestLogin = ({
    hideModal,
    onSignupSuccess,
    onProceedSuccess
}: any) => {
    const [showGuestSessionConfirm, setShowGuestSessionConfirm] = useState(false);
    const [showGuest, setShowGuest] = useState(true);
    const [guestDetails, setGuestDetails]: any = useState(null);
    const [apptRequiredASAP, setApptRequiredASAP] = useState(true);
    const [deptAppt, setDeptAppt] = useState(false);
    const [isShowOTPModal, setIsShowOTPModal] = useState(false);
    const [patientId, setPatientId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [guarantorId, setGuarantorId]: any = useState("");
    const [dependant, setDependant]: any = useState("");
    const dispatch = useDispatch();
    const { register, setError, handleSubmit, setValue, getValues, errors, trigger, control, watch } = useForm({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            notes: '',
            dob: moment().subtract(13, 'years'),
            gender: 'Male',
            globalError: ''
        }
    });
    const onRegisterClick = (values: any) => {
        console.log("ehere");
        if (getValues('dob') && getAge(moment(getValues('dob')).format("YYYY-MM-DD")) < 13) {
            setError('globalError', { type: 'custom', message: 'You need to be 13 years and older to sign up.' });
            return;
        }
        //TODO Date and Time for slot
        setGuestDetails({
            ...values,
        });
        // setValue('apptRequiredASAP', true);
        setValue('firstName', '');
        setValue('lastName', '');
        setValue('email', '');
        setValue('phone', '');
        setValue('dob', moment().subtract(13, 'years'));
        setValue('gender', 'Male');
        setValue('globalError', {});
        setShowGuest(false);
        setShowGuestSessionConfirm(true);
        setDeptAppt(true);
        window.scrollTo(0, 0);
    }
    const handleHideGuestSessionConfirm = () => {
        setShowGuestSessionConfirm(false);
        hideModal()
    }
    const onSignupClick = (values: any) => {
        console.log("onSignupClick", values, guestDetails);
        let requestedData = {
            isIndividual: true,
            fname: guestDetails.firstName,
            lname: guestDetails.lastName,
            email: guestDetails.email,
            phone: guestDetails.phone,
            registeras: "normal",
            dob: moment(guestDetails.dob).format('YYYY-MM-DD'),
            gender: guestDetails.gender,
            password: values.password,
            pcontactmethod: 'email,text',
            reminder: 'yes',
            request: {
                paymode: 'cash,credit,card,cheque'
            },
        }
        setIsLoading(true);
        registerUserGuest(requestedData).then((success) => {
            setIsLoading(false);
            if (success && success.status && success.status.error === false) {
                setPatientId(success.id);
                setIsShowOTPModal(true);
                setShowGuestSessionConfirm(false);
            }
        }).catch((err) => {
            setIsLoading(false);
            toast.error(err.msg ? err.msg : 'Something is wrong.');
        });
    }
    const handleOtpVerifySuccess = (data: any) => {
        console.log("handleOtpVerifySuccess", data);
        signInWithCustomToken(data.jwtToken).then((response) => {
            const key1 = 'xprUser.patient-' + data.id;
            const key2 = 'xpr_user_id';
            const key3 = 'xprAuthUser';
            const key4 = 'xprUserName';
            const key5 = 'jwtToken';
            const key6 = 'xprIsGuarantor';
            var json: any = {};
            json[key1] = true;
            json[key2] = data.uniqueId;
            json[key3] = data.authParam;
            json[key4] = data.firstName + " " + data.lastName;
            json[key5] = data.jwtToken;
            json[key6] = data.isGuarantor;
            Cookies.remove('patient_cookie');
            Cookies.remove('patient_cookie', { domain: configs.COOKIE_DOMAIN });
            Cookies.set('patient_cookie', json);
            getPatientList(data.uniqueId).then((success: any) => {
                setIsLoading(true);
                if (success && success.status && success.status.error === false) {
                    const dependant = success.dependant[0];
                    const guarantor = success.guarantor;
                    setGuarantorId(guarantor);
                    setDependant(dependant);
                    console.log(apptRequiredASAP)
                    onSignupSuccess(guarantor, dependant);
                }
                setIsLoading(false);
            }).catch((err) => {
                setIsLoading(false);
                console.log(err);
            })
        })
    };
    const onBookClick = (values: any) => {
        onProceedSuccess(guestDetails);
    }
    return (
        <>
            {
                showGuest &&
                <div className="authentication-box px-3 py-4 mt-4">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h3 className="text-start mb-3">Booking Details</h3>
                        </div>
                        <div>
                            <div><button className="btn p-0 text-primary" onClick={() => {
                                hideModal();
                            }}>Back</button></div>
                        </div>
                    </div>
                    <form action="">
                        <div className="form-group">
                            <label htmlFor="firstName">First name</label>
                            <input type="text" className="form-control" placeholder="Michael" id="firstName" name="firstName" ref={register({ required: true })} />
                            {
                                (errors && errors.firstName && errors.firstName.type === 'required') &&
                                <div className="is-invalid">First name is required</div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Last name</label>
                            <input type="text" className="form-control" placeholder="Philips" name="lastName" ref={register({ required: true })} />
                            {
                                (errors && errors.lastName && errors.lastName.type === 'required') &&
                                <div className="is-invalid">Last name is required</div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Email</label>
                            <input type="email" className="form-control" placeholder="michael@gmail.com" name="email" ref={register({
                                required: true,
                                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            })} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Mobile number</label>
                            <input type="text" className="form-control" placeholder="Ex. ### ### ####" name="phone" maxLength={10} ref={register({ required: true, minLength: 10, maxLength: 10 })} />
                            {
                                (errors && errors.phone && errors.phone.type === 'required') &&
                                <div className="is-invalid">Mobile number is required</div>
                            }
                            {
                                (errors && errors.phone && errors.phone.type === 'minLength') &&
                                <div className="is-invalid">Mobile number is invalid</div>
                            }
                            {
                                (errors && errors.phone && errors.phone.type === 'maxLength') &&
                                <div className="is-invalid">Mobile number is invalid</div>
                            }
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Gender</label>
                            <select className="form-control" name="gender" ref={register({ required: true })}>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Gender neutral</option>
                                <option value="Prefer not to respond">Prefer not to respond</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label className="d-block" htmlFor="exampleInputEmail1">Date of Birth</label>
                            <Controller
                                name="dob"
                                control={control}
                                defaultValue={moment().subtract(13, 'years')}
                                rules={{
                                    required: true
                                }}
                                render={(props) => (
                                    <KeyboardDatePicker
                                        id="date-picker-dialog"
                                        format="YYYY/MM/DD"
                                        name="dob"
                                        value={props.value}
                                        maxDate={moment().subtract(13, 'years')}
                                        onChange={(date: any) => {
                                            props.onChange(date)
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                )}
                            ></Controller>
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleFormControlTextarea1">Notes</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows={3} placeholder="Enter your notes" name="notes" ref={register({ required: false })}></textarea>
                        </div>
                        <input type="hidden" name="globalError" ref={register} />
                        {
                            (errors && errors.globalError && errors.globalError.type === 'custom') &&
                            <div className="text-danger error">{errors.globalError.message}</div>
                        }
                        <button type="submit" className="btn btn-primary w-100 btn-lg" onClick={handleSubmit(onRegisterClick)}>Continue Booking</button>
                    </form>
                </div>
            }
            {
                showGuestSessionConfirm &&
                <GuestSessionConfirm
                    showGuestSessionConfirm={showGuestSessionConfirm}
                    hideModal={handleHideGuestSessionConfirm}
                    onSignupClick={onSignupClick}
                    onBookClick={onBookClick}
                ></GuestSessionConfirm>
            }

            {
                isShowOTPModal && guestDetails && patientId !== "" &&
                <OTPModal hideModal={() => {
                    setIsShowOTPModal(false);
                    hideModal();
                }}
                    sentEmail={guestDetails.email}
                    sentPhone={guestDetails.phone}
                    patientId={patientId}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    codeVerifySuccessLogin={handleOtpVerifySuccess}
                ></OTPModal>
            }
        </>
    )
}

const GuestSessionConfirm = ({
    hideModal,
    showGuestSessionConfirm,
    onSignupClick,
    onBookClick
}: any) => {
    const { register, setError, handleSubmit, setValue, getValues, errors, trigger, control, watch } = useForm({
        defaultValues: {
            password: '',
            cpassword: '',
        }
    });
    const [isSignup, setIsSignup] = useState(false);
    const handleSignupClick = (values: any) => {
        onSignupClick(values);
    }
    const handleBookClick = (values: any) => {
        onBookClick(values)
    }
    return (

        <div className="authentication-box p-4">
            <div>Your appointment is being processed!!!</div>
            <div className="d-flex align-items-center flex-wrap">
                <label>Save time next time by signing up today.</label><br />
                <label>We will keep you informed about your appointment through mobile SMS and/or Email notifications</label>
            </div>
            <div className="d-flex align-items-center flex-wrap">
                <div className="form-check my-2 mr-3">
                    <input className="form-check-input" type="checkbox" name="wantToSignUp" id="wantToSignUp" checked={isSignup} onChange={() => {
                        setIsSignup(!isSignup);
                    }} />
                    <label className="form-check-label" htmlFor="wantToSignUp">I am ready to Sign up?</label>
                </div>
            </div>
            {
                isSignup &&
                <div className="form-row">
                    <div className="col-md-6">
                        <label className="label-form">Password</label>
                        <input type="password" className="form-control" name="password" placeholder="Enter password"
                            ref={register({
                                required: true,
                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
                            })} />
                        {
                            (errors && errors.password && errors.password.type === 'required') &&
                            <div className="is-invalid">Password is required</div>
                        }
                        {
                            (errors && errors.password && errors.password.type === 'pattern') &&
                            <div className="is-invalid">Password must contain 8 characters with at least 1 upper case, 1 lower case, 1 special and 1 numeric character.</div>
                        }
                    </div>
                    <div className="col-md-6">
                        <label className="label-form">Re-type Password</label>
                        <input type="password" className="form-control" name="cpassword" placeholder="Enter re-type password"
                            ref={register({
                                required: true,
                                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
                            })} />
                        {
                            (errors && errors.cpassword && errors.cpassword.type === 'required') &&
                            <div className="is-invalid">Confirm Password is required</div>
                        }
                        {
                            (errors && errors.cpassword && errors.cpassword.type === 'pattern') &&
                            <div className="is-invalid">Password must contain 8 characters with at least 1 upper case, 1 lower case, 1 special and 1 numeric character.</div>
                        }
                    </div>
                </div>
            }
            <div className="d-flex justify-content-end align-items-center pt-4">
                {
                    isSignup &&
                    <label>Note: For security reasons you may be asked to enter an OTP next time when you sign in.</label>
                }
                {
                    isSignup &&
                    <div className="flex-shrink-0">
                        <button onClick={handleSubmit(handleSignupClick)} type="submit" className="btn btn-dark">
                            <span className="aft_load right"></span>Sign up</button>
                    </div>
                }
                {
                    !isSignup &&
                    <button onClick={handleSubmit(handleBookClick)} type="submit" className="btn btn-dark">
                        <span className="aft_load right"></span>Proceed without Sign up</button>
                }
            </div>
            <input type="hidden" name="globalError" ref={register} />
        </div>
    )
}

const AppointmentConfirm = ({
    clinicName,
    providerName,
    date,
    slot,
    flexibleSlot,
    day,
    patient,
    consultationType,
    handleConfirm,
    isLoading
}: any) => {
    return (
        <>
            <div className="authentication-box px-3 py-4 mt-4">
                <h3 className="text-center mb-3">Confirm Appointment</h3>
                <div className="text-center">Your appointment with {clinicName ? clinicName : providerName}.</div>
                <div className="confirm-text">
                    {
                        slot &&
                        <>
                            <div className="confirm-text-item">
                                <div className="confirm-text-item-title">Appointment time</div>
                                <div>{slot?.startdate}</div>
                                <div>{slot?.startTime} to {slot?.endTime}</div>
                            </div>
                        </>
                    }
                    {
                        (flexibleSlot && day) &&
                        <>
                            <div className="confirm-text-item">
                                <div className="confirm-text-item-title">Preferred days</div>
                                <div>{day?.toString()}</div>
                                <div>{flexibleSlot}</div>
                            </div>
                        </>
                    }
                    {
                        patient &&
                        <>
                            <div className="confirm-text-item">
                                <div className="confirm-text-item-title">Appointment for</div>
                                <div>{patient?.fname ?? patient?.firstName ?? ""} {patient?.lname ?? patient?.lastName ?? ""}</div>
                            </div>
                        </>

                    }
                    {
                        consultationType &&
                        <>
                            <div className="confirm-text-item">
                                <div className="confirm-text-item-title">Consultation Type</div>
                                <div>{consultationType?.name}</div>
                            </div>
                        </>
                    }
                
                </div>
                <div className="">
                    <b>Note:</b> <label>Clinic will contact you to confirm this appointment. Please note that if the preferred time is not available, the clinic will suggest alternative date and time for you.</label>
                </div>
                <button className="btn mt-3 btn-primary w-100 btn-lg" disabled={isLoading} onClick={handleConfirm}>Confirm</button>
            </div>

        </>
    )
}

const PatientList = (props: any) => {
    const [isLoading, setIsLoading] = useState(true);
    const [patientList, setPatientList] = useState([] as any);
    const [isGuarantor, setIsGuarantor] = useState(false);
    const [isAddMember, setIsAddMember] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState('' as any);
    const [isNoRecord, setIsNoRecord] = useState(false);
    const [patientUniqueId, setPatientUniqueId] = useState('');
    const [selectedGuarantor, setSelectedGuarantor] = useState('');
    const [showPatientList, setShowPatientList] = useState(true);
    useEffect(() => {
        getPatientListApi();
        window.scrollTo(0, 0)
    }, []);
    const getPatientListApi = () => {
        let id: any = '';
        if (Cookies.get('patient_cookie')) {
            let data: any;
            data = Cookies.getJSON('patient_cookie');
            if (data.xpr_user_id !== "") {
                id = data.xpr_user_id;
                setPatientUniqueId(data.xpr_user_id);
                if (data && data.xprIsGuarantor && data.xprIsGuarantor === true) {
                    setIsGuarantor(true);
                }
            }
        }
        if (id) {
            setIsLoading(true);
            getPatientList(id).then((success) => {
                setIsLoading(false);
                if (success && success.status && success.status.error === false) {
                    setPatientList(success);
                    setSelectedGuarantor(success.guarantor);
                    if (success.dependant && success.dependant.length > 0) {
                        setIsNoRecord(true);
                    }
                }
            }).catch((err) => {
                setIsLoading(false);
                console.log(err);
            })
        }
    }
    const handleSelectPatient = (item: any) => {
        setSelectedPatient(item);
    }
    const hideModal = () => {
        setIsAddMember(false);
        setShowPatientList(true);
    }
    const handleNext = () => {
        props.onClickNext(selectedGuarantor, selectedPatient);
    }
    return (
        <>
            {
                showPatientList &&
                <>

                    <div className="authentication-box px-3 py-4 mt-4">
                        <h3 className="text-start mb-3">This Appointment is for</h3>
                        {
                            (isGuarantor) &&
                            <div className="d-flex justify-content-end mb-2">
                                <button className="border-btn btn btn-link pr-0" onClick={() => { setIsAddMember(true); setShowPatientList(false) }} >Add New Family Member</button>
                            </div>
                        }
                        {
                            (patientList && patientList.dependant && patientList.dependant.length > 0) ?
                                <ul className="list-group">
                                    {
                                        patientList.dependant.map((item: any, index: number) => {
                                            let check: any = false;
                                            if (item.id === selectedPatient.id) {
                                                check = true;
                                            }
                                            return (
                                                <li key={index + Math.random() + 'patient'} onClick={() => { handleSelectPatient(item) }} className={check ? "list-group-item active" : "list-group-item"}>
                                                    <div className="patient-name-txt">{item.firstName + " " + item.lastName}</div>
                                                    {/* <span className="text-muted small">{state.guarantorName}</span> */}
                                                </li>
                                            )
                                        })
                                    }
                                    <button disabled={selectedPatient === ""} className="btn mt-3 btn-primary w-100 btn-lg" onClick={() => { handleNext() }}>Continue Booking</button>
                                </ul>
                                :
                                isNoRecord ? <h3>No Record </h3> :
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                        }
                    </div>
                </>
            }
            {
                (isAddMember) &&
                <AddMember
                    isVisible={isAddMember}
                    hideModal={hideModal}
                    patientUniqueId={patientUniqueId}
                    addDependantSuccess={() => {
                        setIsAddMember(false);
                        setShowPatientList(true);
                        getPatientListApi();
                    }}
                />
            }
        </>
    )
}

export const AddMember = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const { register, setError, handleSubmit, setValue, getValues, errors, trigger, control, watch } = useForm({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            dob: moment().subtract(13, 'years'),
            gender: 'Male',
            automatedreminder: 'yes',
            pcontactmethod: 'email,text',
        }
    });
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    const handleAddDependant = (data: any) => {
        let requestedData = {
            fname: getValues('firstName'),
            lname: getValues('lastName'),
            email: getValues('email'),
            phone: getValues('phone'),
            dob: moment(getValues('dob')).format('YYYY-MM-DD'),
            gender: getValues('gender'),
            contactMethod: getValues('pcontactmethod') ? getValues('pcontactmethod') : '',
            reminder: getValues('automatedreminder') === "yes" ? true : false,
        }
        if (props.patientUniqueId) {
            setIsLoading(true);
            addDependant(props.patientUniqueId, requestedData).then((success) => {
                setIsLoading(false);
                if (success && success.status && success.status.error === false) {
                    toast.success('Member Added.');
                    props.addDependantSuccess();
                }
            }).catch((err) => {
                setIsLoading(false);
                console.log(err);
            })
        }
    }
    return (
        <>
            <div className="authentication-box px-3 py-4 mt-4">
                <div className="d-flex justify-content-between">
                    <h3 className="text-start mb-3">Add Family Member</h3>
                    <button className="btn btn-link" onClick={props.hideModal}>Back</button>
                </div>
                <div>
                    <div className="cal_pop">
                        <div className="mb-3">
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <label className="form-label">First Name:</label>
                                    <input type="text" className="form-control" placeholder="Patient first name" name="firstName" ref={register({ required: true })} />
                                    {
                                        (errors && errors.firstName && errors.firstName.type === 'required') &&
                                        <div className="is-invalid">First name is required</div>
                                    }
                                </div>
                                <div className="col-md-12 mb-3">
                                    <label className="form-label">Last Name:</label>
                                    <input type="text" className="form-control" placeholder="Patient last name" name="lastName" ref={register({ required: true })} />
                                    {
                                        (errors && errors.lastName && errors.lastName.type === 'required') &&
                                        <div className="is-invalid">Last name is required</div>
                                    }
                                </div>
                                <div className="col-md-12 mb-3">
                                    <label className="form-label">Communication email:</label>
                                    <input type="email" className="form-control" placeholder="Enter email" name="email" ref={register({
                                        required: true,
                                        pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                    })} />
                                    {
                                        (errors && errors.email && errors.email.type === 'required') &&
                                        <div className="is-invalid">Email is required</div>
                                    }
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label className="form-label">Communication mobile #</label>
                                    <input type="text" className="form-control" name="phone" maxLength={10} placeholder="Enter mobile #"
                                        ref={register({
                                            required: true,
                                            minLength: 10, maxLength: 10,
                                            pattern: /^[0-9]*$/
                                        })}
                                    />
                                    {console.log('error', errors.phone)}
                                    {
                                        (errors && errors.phone && errors.phone.type === 'required') &&
                                        <div className="is-invalid">Mobile number is required</div>
                                    }
                                    {
                                        (errors && errors.phone && errors.phone.type === 'minLength') &&
                                        <div className="is-invalid">Mobile number is invalid</div>
                                    }
                                    {
                                        (errors && errors.phone && (errors.phone.type === 'maxLength' || errors.phone.type === "pattern")) &&
                                        <div className="is-invalid">Mobile number is invalid</div>
                                    }
                                </div>
                                <div className="col-md-12 mb-3">
                                    <label className="form-label">Gender</label>
                                    <select className="form-select form-control" name="gender" ref={register({ required: true })}>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>
                                <div className="col-md-12 mb-3 miuif_margin">
                                    <label className="form-label d-block">Date of birth</label>
                                    {/* <input type="text" className="form-control" placeholder="Date of birth" /> */}
                                    <Controller
                                        name="dob"
                                        control={control}
                                        defaultValue={moment().subtract(13, 'years')}
                                        rules={{
                                            required: true
                                        }}
                                        render={(props) => (
                                            <KeyboardDatePicker
                                                id="date-picker-dialog"
                                                format="YYYY/MM/DD"
                                                name="dob"
                                                value={props.value}
                                                maxDate={moment().subtract(13, 'years')}
                                                onChange={(date: any) => {
                                                    props.onChange(date)
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        )}
                                    ></Controller>
                                </div>
                                <div className="col-md-12 mb-3">
                                    <label className="form-label">Automated Reminder?</label>
                                    <div className="d-flex align-items-center">
                                        <div className="form-check mr-3">
                                            <input className="form-check-input" type="radio" name="automatedreminder" id="a-yes" value="yes" ref={register({ required: true })} />
                                            <label className="form-check-label" htmlFor="a-yes">Yes</label>
                                        </div>
                                        <div className="form-check mr-3">
                                            <input className="form-check-input" type="radio" name="automatedreminder" id="a-no" value="no" ref={register({ required: true })} />
                                            <label className="form-check-label" htmlFor="a-no">No</label>
                                        </div>
                                    </div>
                                    {
                                        (errors && errors.automatedreminder && errors.automatedreminder.type === 'required') &&
                                        <div className="text-danger error">Automated reminder is required.</div>
                                    }
                                </div>
                                <div className="col-md-12 mb-3">
                                    {
                                        watch('automatedreminder') === 'yes' ?
                                            <>
                                                <label className="form-label">Preferred contact method?</label>
                                                <div className="d-flex align-items-center">
                                                    <div className="form-check mr-3">
                                                        <input className="form-check-input" type="radio" name="pcontactmethod" id="email-only" value={"email"} ref={register({ required: true })} />
                                                        <label className="form-check-label" htmlFor="email-only">Email</label>
                                                    </div>
                                                    <div className="form-check mr-3">
                                                        <input className="form-check-input" type="radio" name="pcontactmethod" id="text-only" value="text" ref={register({ required: true })} />
                                                        <label className="form-check-label" htmlFor="text-only">Text</label>
                                                    </div>
                                                    <div className="form-check mr-3">
                                                        <input className="form-check-input" type="radio" name="pcontactmethod" id="et-both" value="email,text" ref={register({ required: true })} />
                                                        <label className="form-check-label" htmlFor="et-both">Email & Text</label>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <p className="form-label">This patient will not receive notification.</p>
                                    }
                                </div>
                                {/* <div className="col-12 small text-muted">This patient will not receive notification.</div> */}
                            </div>
                        </div>
                        <button onClick={handleSubmit(handleAddDependant)} className="btn btn-primary w-100 btn-lg">
                            {isLoading &&
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            }
                            Continue</button>
                    </div>
                </div>

            </div>
        </>
    )
}