import React from 'react';
import { Helmet } from 'react-helmet';
import HeaderWhite from '../HeaderWhite/HeaderWhite';
import { loadMap, setGoogleMap, setMarkerToPostion } from '../../utils/googleFunction';
import { fetchClinicByLocation, getHygieneFeatures } from '../../services/results';
import { RatingComp } from '../RatingComponent';
import apiConfigs from '../../configs/apiConfigs';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PopUpOne from '../BookAppointmentPopUp/PopUpOne';
import PopUpTwo from '../BookAppointmentPopUp/PopUpTwo';
import PopUpThree from '../BookAppointmentPopUp/PopUpThree';
import PopUpFour from '../BookAppointmentPopUp/PopUpFour';
import { showAddress } from '../../components/renderAddress';
import { HygieneRating } from '../hygieneRating';
import { Avatar } from '@material-ui/core';
import PopUpAlreadyClinic from '../BookAppointmentPopUp/PopUpAlreadyClinic';
interface Props {
    match: any;
}
interface State {
    clinicList: any[];
    expandedFeatureId: string;
    loading: boolean;
    noRecordFound: { isTrue: boolean; msg: string };
    viewProvider: string;
    showPopupOne: boolean;
    showPopupTwo: boolean;
    showPopupThree: boolean;
    showPopupFour: boolean;
    data: any;
    selectedDoctor: any;
    selectedAppointment: any;
    noteForOffice: any;
    emailAddress: string;
    isPatientNew: boolean;
    userRegistrationResponse: any;
    viewCallTo: string;
    hygieneList: { status: boolean, list: any[] };
    showPopupAlreadyClinic: boolean;
    isExistingClinic: boolean;
}
function renderDoctorPhoto(doctor: any[], location: string) {
    return (
        <>
            {
                doctor.map((doctor, index) => {
                    let speciality = '';
                    if (doctor.specialities) {
                        const elementFound = doctor.specialities.findIndex((spec: any, index: number) => {
                            return spec.coreSpeciality === "1"
                        });
                        if (elementFound === -1 && doctor.specialities.length > 0) {
                            speciality = doctor.specialities[0].name;
                        } else {
                            speciality = doctor.specialities[elementFound].name;
                        }
                    }
                    if (doctor.photo && doctor.photo.name && doctor.photo.type) {
                        const imageUrl = apiConfigs.IMAGE_URL + '/doctor/' + doctor.photo.name + '-200x200.' + doctor.photo.type;
                        return (
                            <div className="col-sm-6 sedlist" key={index + Math.random()}>
                                <Link to={"/dentists/" + location + "/" + doctor.fullname.toLowerCase().trim().replace(/[.,_+]/g, '').replace(/\s+/g, "-") + "/" + doctor.providerId + "/d"}>
                                    <div className="media d-flex align-items-center">
                                        <div className="sc-dr-img mr-2">
                                            <div className="img-box"><img src={imageUrl} className="img-fluid rounded-circle" /></div>
                                        </div>
                                        <div className="media-body">
                                            <div className="d_name">{doctor.fullname}</div>
                                            <div className="ddeg">{speciality}</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        )
                    } else {
                        let doctorName = doctor.fullname.replace("Dr.", "");
                        doctorName = doctorName.trim();
                        doctorName = doctorName.split(" ");
                        const letterOne = doctorName[0] ? doctorName[0].charAt(0) : '';
                        const letterTwo = doctorName[1] ? doctorName[1].charAt(0) : '';
                        return (
                            <div className="col-sm-6 sedlist" key={index + Math.random()}>
                                <Link to={"/dentists/" + location + "/" + doctor.fullname.toLowerCase().trim().replace(/[.,_+]/g, '').replace(/\s+/g, "-") + "/" + doctor.providerId + "/d"}>
                                    <div className="media d-flex align-items-center">
                                        <div className="sc-dr-img mr-2">
                                            <div className="img-box">
                                                <Avatar>{letterOne}{letterTwo}</Avatar>
                                                {/* <img src={apiConfigs.DEFAULT_IMAGE_URL_DOCTOR} className="img-fluid rounded-circle" /> */}
                                            </div>
                                        </div>
                                        <div className="media-body">
                                            <div className="d_name">{doctor.fullname}</div>
                                            <div className="ddeg">{speciality}</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        )
                    }
                })
            }
        </>
    )
}
class PracticeList extends React.Component<Props, State> {
    googleMap: any;
    constructor(props: any) {
        super(props);
        this.state = {
            clinicList: [],
            expandedFeatureId: '',
            loading: true,
            noRecordFound: { isTrue: false, msg: '' },
            viewProvider: '',
            showPopupOne: false, showPopupTwo: false, showPopupThree: false, showPopupFour: false, data: '',
            selectedDoctor: '', selectedAppointment: '', noteForOffice: '', emailAddress: '', isPatientNew: false,
            userRegistrationResponse: '',
            viewCallTo: '', hygieneList: { status: false, list: [] },
            showPopupAlreadyClinic: false, isExistingClinic: false
        }
    }
    componentDidMount() {
        /* Fetch Hygiene list */
        this.fetchHygieneList();

        /* Google Map Loading... */
        try {
            loadMap().then((success) => {
                if (success) {
                    setGoogleMap({ latitude: 23.41, longitude: 72.12 }, 'map').then((success) => {
                        this.googleMap = success;
                    }, (error) => {
                    });
                }
            }, (error) => {
                console.log(error);
            });
        } catch (error) {
            console.log(error);
        }

        /* Fetch Clinic List by Location from url param*/
        this.fetchClincByLoc();
    }
    fetchClincByLoc() {
        let { location } = this.props.match.params;
        location = location.split("-")[0];
        fetchClinicByLocation(location).then((success) => {
            this.setState({ loading: false });
            if (success && success.status.error === true && success.status.msg) {
                this.setState({ noRecordFound: { isTrue: true, msg: success.status.msg } });
            } else if (success && success.status.error === false && success.clinics) {
                const clinicMarkers = success.clinics.map((clinic: any) => {
                    return {
                        latitude: clinic.address.latitude,
                        longitude: clinic.address.longitude
                    }
                });
                if (this.googleMap) {
                    this.clinicsMarker(clinicMarkers);
                } else {
                    setGoogleMap({ latitude: 23.41, longitude: 72.12 }, 'map').then((success) => {
                        this.googleMap = success;
                        setMarkerToPostion(this.googleMap, { latitude: 43.6205, longitude: -79.5132 });
                        this.clinicsMarker(clinicMarkers);
                    }, (error) => {
                    });
                }
                this.setState({ clinicList: success.clinics, noRecordFound: { isTrue: false, msg: '' } });
            } else {
                this.setState({ noRecordFound: { isTrue: true, msg: 'No records found.' } });
            }
        }).catch((error) => {
            console.log(error);
        });
    }
    clinicsMarker(markers: { latitude: number, longitude: number }[]) {
        //@ts-ignore
        var bounds = new window.google.maps.LatLngBounds();
        markers.forEach((marker: any, index: number) => {
            const markerReturn = setMarkerToPostion(this.googleMap, marker);
            bounds.extend(markerReturn.position);
        });
        this.googleMap.fitBounds(bounds);
    }
    async fetchHygieneList() {
        try {
            const results = await getHygieneFeatures();
            if (results.hygieneFeatures) {
                this.setState({ hygieneList: { status: true, list: results.hygieneFeatures } });
            }
        } catch (error) {
            this.setState({ hygieneList: { status: false, list: [] } });
        }
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>Find Trusted Providers in {this.props.match.params.location.split("-")[0].charAt(0).toUpperCase() + this.props.match.params.location.split("-")[0].slice(1)}, {this.props.match.params.location.split("-")[1].toUpperCase()} | Emergency Dental Clinic - XCarePro</title>
                    <meta name="description" content={"Are you looking for the best dental clinic in " + this.props.match.params.location.split("-")[0].charAt(0).toUpperCase() + this.props.match.params.location.split("-")[0].slice(1) + ", " + this.props.match.params.location.split("-")[1].toUpperCase() + "? Here is a list of the best-rated healthcare providers in " + this.props.match.params.location.split("-")[0].charAt(0).toUpperCase() + this.props.match.params.location.split("-")[0].slice(1) + ", including patient reviews and ratings."} />
                </Helmet>
                <HeaderWhite></HeaderWhite>
                <section className="cd-map-sec">
                    <div id="map" className="map-sec kflrui" style={{ height: "250px" }}></div>
                </section>
                <section className="list-page seo-list-page">
                    {
                        (this.state.loading === true && this.state.noRecordFound.isTrue === false) &&

                        <div className="frontloader">
                            <img src={process.env.PUBLIC_URL + "/images/loader-img.gif"} />
                        </div>
                    }
                    {
                        (this.state.loading === false && this.state.noRecordFound.isTrue === true) &&
                        <>{this.state.noRecordFound.msg}</>
                    }
                    {
                        (this.state.loading === false && this.state.noRecordFound.isTrue === false) &&
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 mt-5">
                                    {
                                        this.state.clinicList.map((clinic: any, index: number) => {
                                            return (
                                                <div className="list-box-sec" key={index + Math.random()}>

                                                    <div className="row align-items-stretch">

                                                        <div className="col-12 col-md-2 col d-none d-sm-none d-md-block">
                                                            {
                                                                (clinic.logo && clinic.logo.name && clinic.logo.type) ?
                                                                    <Link to={"/dentists/" + this.props.match.params.location + "/" + clinic.name.toLowerCase().trim().replace(/[.,_+]/g, '').replace(/\s+/g, "-") + "/" + clinic.uniqueId + "/c"} key={index + Math.random()}>
                                                                        <div className="media-img sc_cl_img"><img src={apiConfigs.IMAGE_URL + '/clinic/' + clinic.logo.name + '-200x200.' + clinic.logo.type} className="img-fluid img-fluid rounded-circle" /></div>
                                                                    </Link> :
                                                                    <Link to={"/dentists/" + this.props.match.params.location + "/" + clinic.name.toLowerCase().trim().replace(/[.,_+]/g, '').replace(/\s+/g, "-") + "/" + clinic.uniqueId + "/c"} key={index + Math.random()}>
                                                                        <div className="media-img sc_cl_img"><img src="https://staging.xcarepro.com/uploads/clinic/8587917446_clinic_logo_8167974485-300x300.png" className="img-fluid img-fluid rounded-circle" /></div>
                                                                    </Link>
                                                            }
                                                        </div>

                                                        <div className="col-12 col-md-10 col">
                                                            <div className="row">
                                                                <div className="col-12 col-md-7 col">
                                                                    <div className="clinic-details">
                                                                        {/* <div className="trusted-txt"><img src="/images/trusted-icon.svg" className="mr-2" />Trusted by Xcarepro</div> */}
                                                                        <div className="p-name">
                                                                            <Link to={"/dentists/" + this.props.match.params.location + "/" + clinic.name.toLowerCase().trim().replace(/[.,_+]/g, '').replace(/\s+/g, "-") + "/" + clinic.uniqueId + "/c"}>
                                                                                {clinic.name ? clinic.name : ''}
                                                                            </Link>
                                                                        </div>
                                                                        <div className="p-address">
                                                                            {
                                                                                showAddress(clinic.address)
                                                                            }
                                                                        </div>

                                                                        {
                                                                            clinic.rating !== 0 ? <div className="ratting-star">
                                                                                <RatingComp rating={clinic.rating}></RatingComp>
                                                                                <strong>
                                                                                    {
                                                                                        (clinic.rating > 0) &&
                                                                                        <>{clinic.rating}</>
                                                                                    }
                                                                                    {
                                                                                        (clinic.reviewCount > 0) &&
                                                                                        <>
                                                                                            ({clinic.reviewCount})
                                                                                        </>
                                                                                    }
                                                                                </strong>
                                                                            </div> : ""
                                                                        }
                                                                        {
                                                                            (this.state.hygieneList.status && clinic.HygieneFeatures) &&
                                                                            <HygieneRating key={'hrpl-1'} HygieneFeatures={clinic.HygieneFeatures} hygieneList={this.state.hygieneList}></HygieneRating>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-5 col align-self-center text-left text-md-right">


                                                                    {
                                                                        clinic.doctors.length !== 0 &&
                                                                        <button className="btn btn-custom" onClick={(e) => {
                                                                            this.setState({
                                                                                showPopupAlreadyClinic: true,
                                                                                data: clinic
                                                                            })
                                                                        }}>Book Appointment</button>
                                                                    }
                                                                    {
                                                                        (clinic.doctors.length === 0 && this.state.viewCallTo !== clinic.uniqueId) &&
                                                                        <button className="btn btn-custom" onClick={(e) => {
                                                                            this.setState({
                                                                                viewCallTo: clinic.uniqueId,
                                                                            });
                                                                        }}>See phone number</button>
                                                                    }
                                                                    {
                                                                        (clinic.doctors.length === 0 && this.state.viewCallTo === clinic.uniqueId) &&
                                                                        <a href={"tel:" + clinic.phone}><button className="btn btn-custom">Call {clinic.phone}</button></a>
                                                                    }


                                                                </div>

                                                                <div className="col-12 mt-3">
                                                                    <p className="m-0">
                                                                        {
                                                                            clinic.description ? clinic.description.substring(0, 150) + (clinic.description.length > 150 ? '...' : '') : ''
                                                                        }
                                                                    </p>
                                                                </div>


                                                            </div>

                                                            <hr />

                                                            <div className="row">
                                                                {
                                                                    renderDoctorPhoto(clinic.doctors.slice(0, 2), this.props.match.params.location)
                                                                }
                                                                {
                                                                    (this.state.viewProvider === clinic.uniqueId) &&
                                                                    renderDoctorPhoto(clinic.doctors.slice(2, clinic.doctors.length), this.props.match.params.location)
                                                                }
                                                                {
                                                                    (clinic.doctors.length > 2 && this.state.viewProvider !== clinic.uniqueId) &&
                                                                    <div className="col-sm-12">
                                                                        <a className="vaplink" href="javascript:void(0);" onClick={(e) => { this.setState({ viewProvider: clinic.uniqueId }); }}>View all providers</a>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>

                                                    </div>


                                                    {/* <div className="row">
                                                        <div className="col-12">
                                                            <ul className="img-list-sec">
                                                                {
                                                                    (clinic.features && isArray(clinic.features) && clinic.features.length > 0) &&
                                                                    <>
                                                                        {
                                                                            (clinic.features.map((feature: any, index: any) => {
                                                                                if (index > 5) {
                                                                                    return null;
                                                                                }
                                                                                const findexFeature = FEATURES.findIndex((featureEach: any, index: number) => {
                                                                                    return feature.id.toString() === featureEach.id.toString();
                                                                                });
                                                                                if (findexFeature !== -1) {
                                                                                    return (
                                                                                        <li key={index + Math.random()}><img alt={FEATURES[findexFeature].name} src={process.env.PUBLIC_URL + FEATURES[findexFeature].src} /></li>
                                                                                    )
                                                                                } else {
                                                                                    return null;
                                                                                }
                                                                            }))

                                                                        }
                                                                        {
                                                                            <>
                                                                                {
                                                                                    (clinic.features.length > 6 && this.state.expandedFeatureId !== clinic.uniqueId) &&
                                                                                    <>
                                                                                        <li onClick={(e) => { this.setState({ expandedFeatureId: clinic.uniqueId }) }}><span>More</span></li>
                                                                                    </>}
                                                                            </>
                                                                        }
                                                                        {
                                                                            (clinic.uniqueId === this.state.expandedFeatureId) &&
                                                                            <>
                                                                                {
                                                                                    (clinic.features.map((feature: any, index: any) => {
                                                                                        if (index < 6) {
                                                                                            return null;
                                                                                        }
                                                                                        const findexFeature = FEATURES.findIndex((featureEach: any, index) => {
                                                                                            return feature.id.toString() === featureEach.id.toString();
                                                                                        })
                                                                                        if (findexFeature !== -1) {
                                                                                            return (
                                                                                                <li key={index + Math.random()}><img alt={FEATURES[findexFeature].name} src={process.env.PUBLIC_URL + FEATURES[findexFeature].src} /></li>
                                                                                            )
                                                                                        }
                                                                                    }))
                                                                                }
                                                                            </>
                                                                        }
                                                                        {
                                                                            (this.state.expandedFeatureId !== '' && this.state.expandedFeatureId === clinic.uniqueId) &&
                                                                            <>
                                                                                <li onClick={(e) => { this.setState({ expandedFeatureId: '' }) }}><span>Less</span></li>
                                                                            </>
                                                                        }
                                                                    </>
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                <div className="col-md-4">
                                    <div className="best-rated-sec">
                                        <p><strong>Trusted {this.props.match.params.location.split("-")[0].charAt(0).toUpperCase() + this.props.match.params.location.split("-")[0].slice(1)} Dentists</strong></p>
                                        <p>Find the best <strong>healthcare providers in {this.props.match.params.location.split("-")[0].charAt(0).toUpperCase() + this.props.match.params.location.split("-")[0].slice(1)}</strong> that residents trust to meet their oral health needs. Browse through our list of vetted and highly rated dentists to find the dentist that best suits your needs. You can also read reviews from past patients and schedule your first appointment online. A trusted <strong>dentists in {this.props.match.params.location.split("-")[0].charAt(0).toUpperCase() + this.props.match.params.location.split("-")[0].slice(1)}</strong> is only a few clicks away.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mt-5">
                                    <p>Are you having a hard time trying to find a <strong>dentist in {this.props.match.params.location.split("-")[0].charAt(0).toUpperCase() + this.props.match.params.location.split("-")[0].slice(1)}</strong> you can trust? Many people don’t know where to start when searching for a reliable dentist. With so many dentists advertising online, it can be a challenge to identify a dentist you can trust to provide you with the care you need.</p>
                                    <p>For example, when using the search query <strong>‘dentist near me’</strong>, you will be bombarded with dental advertisements and other dental practice listings, many of which will not be relevant to the treatment needs you are seeking. Instead, try using XCarePro. We’ll provide you with a list of dentists that are closest to you and whose availability aligns with your schedule. We’ll even provide you with an interactive map that will show you the directions from your location to each dental practice you are considering. Whether you’re searching for an <strong>emergency dentist in {this.props.match.params.location.split("-")[0].charAt(0).toUpperCase() + this.props.match.params.location.split("-")[0].slice(1)}</strong> to help with an immediate problem or you just want to visit an <strong>{this.props.match.params.location.split("-")[0].charAt(0).toUpperCase() + this.props.match.params.location.split("-")[0].slice(1)} dentist</strong> for a regular check-up, we’ll help you find the best dentist to meet your needs.</p>
                                    <p>Maintaining good oral health is vital for your overall physical health and mental well-being, which is why we understand the importance of selecting the right dentist. Finding a dentist that meets your needs is key to ensuring you attain quality oral health care in an environment that is friendly and comfortable.</p>
                                    <p>Here at XCarePro, we’ve done all the hard work for you. Whether you are searching for an <strong>emergency dental clinic in {this.props.match.params.location.split("-")[0].charAt(0).toUpperCase() + this.props.match.params.location.split("-")[0].slice(1)}</strong> or have moved to Oakville recently and want to find a <strong>dental clinic in {this.props.match.params.location.split("-")[0].charAt(0).toUpperCase() + this.props.match.params.location.split("-")[0].slice(1)}</strong> for you and your family, we have you covered. You can rely on us to help make the search easier and quicker.</p>
                                    <p>Our database consists only of trusted <strong>{this.props.match.params.location.split("-")[0].charAt(0).toUpperCase() + this.props.match.params.location.split("-")[0].slice(1)} dentists.</strong> You no longer have to rely on word of mouth to get referred to a quality dentist. We’ll provide you with a list of <strong>dentists in {this.props.match.params.location.split("-")[0].charAt(0).toUpperCase() + this.props.match.params.location.split("-")[0].slice(1)}</strong> that have shown to consistently be competent, skilled, experienced and qualified.</p>
                                    <p>All the healthcare providers listed in our database have been vetted by our team to ensure they meet our standards to get registered. Our team visits each dental practice and holds interviews with the dentists before they are accepted. When you select a dentist from the XCarePro database, you can rest assured knowing you’re in good hands.</p>
                                    <p>Not sure which dentist to choose? Read the reviews from previous patients and then make your decision. Once you’ve decided which dentist you want to move ahead with, simply book an appointment within a few clicks.</p>
                                    <p>Have questions? Get in touch with our team today for more information!</p>
                                </div>
                            </div>
                        </div>
                    }
                </section>
                <Modal show={this.state.showPopupAlreadyClinic} className="public_popup master_popup" centered backdrop={true} onHide={() => {
                    this.setState({ showPopupAlreadyClinic: false });
                }}>
                    <PopUpAlreadyClinic showNextPopUp={(isExistingClinic: boolean) => {
                        this.setState({ showPopupAlreadyClinic: false, showPopupOne: true, isExistingClinic: isExistingClinic });
                    }} closePopUp={() => {
                        this.setState({ showPopupAlreadyClinic: false });
                    }} clinic={this.state.data}></PopUpAlreadyClinic>

                </Modal>
                <Modal show={this.state.showPopupOne} className="public_popup master_popup" centered backdrop={true} onHide={() => {
                    this.setState({ showPopupOne: false });
                }}>
                    <PopUpOne showNextPopUp={(clinic: any, selectedDoctor: any) => {
                        this.setState({ showPopupOne: false, showPopupTwo: true, selectedDoctor: selectedDoctor });
                    }} closePopUp={() => {
                        this.setState({ showPopupOne: false });
                    }} data={this.state.data}></PopUpOne>
                </Modal>
                <Modal show={this.state.showPopupTwo} className="public_popup master_popup" centered backdrop={true} onHide={() => {
                    this.setState({ showPopupTwo: false });
                }}>
                    <PopUpTwo showNextPopUp={(selectedAppointment: any, noteForOffice: any) => {
                        this.setState({ showPopupTwo: false, showPopupThree: true, selectedAppointment: selectedAppointment, noteForOffice: noteForOffice });
                    }} closePopUp={() => {
                        this.setState({ showPopupTwo: false });
                    }} data={this.state.data} doctor={this.state.selectedDoctor}></PopUpTwo>
                </Modal>
                <Modal show={this.state.showPopupThree} className="public_popup master_popup" centered backdrop={true} onHide={() => {
                    this.setState({ showPopupThree: false });
                }}>
                    <PopUpThree showNextPopUp={(emailAddress: any, userRegistrationResponse: any) => {
                        this.setState({
                            showPopupThree: false, showPopupFour: true,
                            emailAddress: emailAddress, isPatientNew: userRegistrationResponse.isNewPatient,
                            userRegistrationResponse: userRegistrationResponse
                        });
                    }} closePopUp={() => {
                        this.setState({ showPopupThree: false });
                    }} doctor={this.state.selectedDoctor}></PopUpThree>
                </Modal>
                <Modal show={this.state.showPopupFour} className="book-appointment-popup master_popup" centered backdrop={true} onHide={() => {
                    this.setState({ showPopupFour: false });
                }}>
                    <PopUpFour closePopUp={(doRedirect: boolean) => {
                        this.setState({ showPopupFour: false });
                        if (doRedirect) {
                            window.location.href = apiConfigs.PATIENT_APPOINTMENT_URL;
                        }
                    }} isNewPatient={this.state.isPatientNew} data={this.state.data} doctor={this.state.selectedDoctor}
                        potentialAppointment={this.state.selectedAppointment}
                        emailAddress={this.state.emailAddress}
                        noteForOffice={this.state.noteForOffice} userRegistrationResponse={this.state.userRegistrationResponse}
                        isExistingClinic={this.state.isExistingClinic}
                    ></PopUpFour>
                </Modal>
            </>
        );
    }
}
export default PracticeList;