import React, { useEffect, useState, useRef } from 'react';
import { prepareAddress } from '../../../../utils/googleFunction';
import { useSelector, useDispatch } from 'react-redux';
import { IAppState } from '../../../../store';
import { searchFilter } from '../../../../store/v2/searchFilter/actions';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../../store/loadingIndicator/actions';
import { clinicAndProviderList } from '../../../../services/landingPageSearch';
import { addClinicProviderList } from '../../../../store/clinicAndProviderList/actions';
import { addSlotData } from '../../../../store/slot/actions';
import { appointmentSlot } from '../../../../services/landingPageSearch';
import * as _ from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import { fetchClinicList } from '../../services/AppointmentBook';
//@ts-ignore
const google = window.google = window.google ? window.google : {}
function Location(props:any) {
    let autcompleteRef: any;
    let autocomplete: any;
    autcompleteRef = useRef();
    const [place, setPlace] = useState('');
    const [reload, setReload] = useState(true);
    const dispatch = useDispatch();
    const searchFilterData = useSelector((state: IAppState) => state.searchFilter.searchFilterData);
    const clinicProviderList: any = useSelector((state: IAppState | any) => state.clinicProviderList.clinicProviderList);
    const [apiAppointmentSlotRequest, setAppointmentSlotRequest]: any = useState();
    const [apiClinicProviderRequest, setApiClinicProviderRequest]: any = useState();
    const [totalCountProvider, setTotalCountProvider] = useState(0);
    const [apiClinicListRequest, setApiClinicListRequest]: any = useState();
    const [totalCount, setTotalCount] = useState(0);
    let selectedTabRef = useRef(props.selectedTab);
    useEffect(() => {
        if (props.selectedTab !== selectedTabRef.current) {
            selectedTabRef.current = props.selectedTab;
        }
    },[props.selectedTab]);
    
    useEffect(() => {
        if (searchFilterData.location.latitude && searchFilterData.location.longitude) {
            setPlace(searchFilterData.location.formatted_address);
       }
    },[searchFilterData.location]);
    useEffect(() => {
        if (!document.getElementById('googlePlaceScript') && reload === true) {
            const googleScript = document.createElement('script');
            googleScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDIDMklRxHmFYWU8Vvo1P-dVkB_nbHARj8&libraries=places`
            googleScript.id = "googlePlaceScript";
            window.document.body.appendChild(googleScript);
            googleScript.addEventListener('load', () => {
                try {
                    //@ts-ignore
                    autocomplete = new window.google.maps.places.Autocomplete(
                        autcompleteRef.current,
                        {
                            types: ['(regions)'],
                        }
                    );
                    setReload(false);
                    autocomplete.addListener('place_changed', () => {
                        let place = autocomplete.getPlace();
                        place = prepareAddress(place);
                        dispatch(searchFilter({
                            ...searchFilterData,
                            location: place,
                            clinicId: '',
                            providerId:''
                            // when map drag previous clinic unselecte so show new clinic list
                        }));
                        setPlace(place.formatted_address);
                        if (selectedTabRef.current === "provider") {
                            getClinicProviderList(place);
                        } else if (selectedTabRef.current === "clinic") {
                            fetchClinicListApi(place);
                        }
                    });
                } catch (err) {
                    console.log(err);
                }
            });
        } else {
            if (reload === false) {
                try {
                    //@ts-ignore
                    autocomplete = new window.google.maps.places.Autocomplete(
                        autcompleteRef.current,
                        {
                            types: ['(regions)'],
                        }
                    );
                    setReload(true);
                    autocomplete.addListener('place_changed', () => {
                        let place = autocomplete.getPlace();
                        place = prepareAddress(place);
                        // props.handleLocation(place,autcompleteRef);
                         dispatch(searchFilter({
                            ...searchFilterData,
                            location: place,
                            clinicId: '',
                            providerId:''
                            // when map drag previous clinic unselecte so show new clinic list
                        }));
                        setPlace(place.formatted_address);
                        if (selectedTabRef.current === "provider") {
                            getClinicProviderList(place);
                        } else if (selectedTabRef.current === "clinic") {
                            fetchClinicListApi(place);
                        }
                    });
                } catch (err) {
                    console.log(err);
                }
            }
        }
    }, [props]);
    const getClinicProviderList=(location?:any)=>{
        const { newSpeciality, date, howToPay, patientUniqueId, page, results,languages,distance,gender,clinicId,providerId } = searchFilterData;
        let latitude: any = "", longitude: any = "", langIds: any = [], clinic: any = '';
        if(location !== ""){
            latitude=location.latitude;
            longitude=location.longitude;
        }
        if (languages && languages.length > 0) {
            langIds = languages.map((item: any) => {
                return item.value
            });
        }
        let request={
            location:{
                latitude: latitude,
                longitude: longitude,
                specialityId: newSpeciality,
                searchdate:date !== null ? date : "",
                radiuskm: distance,
                gender:gender ? gender.toString():'',
                languages: langIds.toString(),
                insuranceProviderId: howToPay.type === 'insurance' ? howToPay.value : '',
                acceptAssignment: howToPay.type === 'cash' ? 'no' : '',
                socialAssistTypeID: howToPay.type === 'socialAssistance' ? howToPay.value : '',
                clinicId: '',
                providerId:'',
                patientUniqueId: patientUniqueId,
                page: 1,
                results:results
            }
        }
        let isApiCall: boolean=false;
        if ( JSON.stringify(apiClinicProviderRequest) !== JSON.stringify(request)) {
            setApiClinicProviderRequest(request);
            isApiCall = true;
        }
        if (isApiCall) {
            dispatch(fetchLoadingPending());
            try {
                clinicAndProviderList(request).then((success: any) => {
                    if (success && success.response && success.response.data && success.response.status === 200) {
                        if (success.response.data && success.response.data.status.error === false) {
                            setTimeout(() => {
                                dispatch(fetchLoadingSuccess(false));
                            }, 2000);
                            if (success.response.data && success.response.data.total) {
                                setTotalCountProvider(success.response.data.total);
                            }
                            dispatch(addClinicProviderList({
                                ...clinicProviderList,
                                clinicList:_.uniqBy(success.response.data.clinic, 'id'),
                                providerList: success.response.data.doctors,
                                providerTotalCount:success.response.data.total?success.response.data.total:totalCountProvider
                            }));
                            // appointmentSlotApi(success.response.data.doctors);
                        }else if(success.response.data && success.response.data.status.error === true){
                            dispatch(addClinicProviderList({
                                ...clinicProviderList,
                                clinicList:[],
                                providerList: [],
                                providerTotalCount: 0,
                                page:1
                            }));
                            console.log('error');
                            dispatch(fetchLoadingSuccess(false));
                        }
                    }
                });
            } catch (error) {
                toast.error('Something went wrong.');
                console.log('error', error);
                dispatch(fetchLoadingSuccess(false));
            }
        } else {
            // dispatch(fetchLoadingSuccess(false));
        }
    }

    const appointmentSlotApi = (providerList?: any, currentWeek?: any) => {
        var request: any = [];
        let startDate: any = searchFilterData.date ?searchFilterData.date:moment().format('YYYY-MM-DD');
        let endDate: any = searchFilterData.date ? moment(searchFilterData.date).add('days', 4).format('YYYY-MM-DD'):moment().add('days',4).format('YYYY-MM-DD');
        if (providerList.length > 0 ) {
            providerList.map((item: any) => {
                if (item.sharecalendar && item.sharecalendar.calender) {
                     request.push({
                        "doctorId": item.id,
                        "clinicId": item.clinic_id,
                        "start_date": startDate,
                        "end_date": endDate
                    });
                }
            });
            if (request.length > 0) {
                let data = { 'appointmentData': request };
                let isApiCall: boolean = false;
                if ( JSON.stringify(apiAppointmentSlotRequest) !== JSON.stringify(data)) {
                    setAppointmentSlotRequest(data);
                    isApiCall = true;
                }
                if (isApiCall) {
                    dispatch(fetchLoadingPending());
                    appointmentSlot(data).then((success: any) => {
                        try {
                            if (success && success.response && success.response.data && success.response.status === 200) {
                                if (success.response.data && success.response.data.status.error === false) {
                                    dispatch(fetchLoadingSuccess(false));
                                    var result = groupBy(success.response.data.appointments, function (item: any) {
                                        return [item.doctorid, item.clinicid];
                                    });
                                    let data: any = [];
                                    result.map((item: any) => {
                                        let groupData = _.groupBy(item, 'startdate');
                                        data.push(groupData);
                                    })
                                    // setGroupByDate(data);
                                    dispatch(addSlotData({ slot: data }));
                                    // this.setState({ appointmentSlotData: result,isAppointmentBook:false,isLoading:false });
                                } else {
                                    if (success.response.data && success.response.data.status.error === true) {
                                        toast.error(success.response.data.status.msg);
                                    }
                                    dispatch(fetchLoadingSuccess(false));
                                }
                            }
                        } catch (error) {
                            toast.error('Something went wrong.');
                            console.log('error', error);
                            dispatch(fetchLoadingSuccess(false));
                        }
                    });
                } else {
                    dispatch(fetchLoadingSuccess(false));
                }
            }
        }
    }
    const groupBy = (array: any, f: any) => {
        var groups: any = {};
        array.forEach(function (o: any) {
            var group = JSON.stringify(f(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
        });
        return Object.keys(groups).map(function (group) {
            return groups[group];
        })
    }

    const fetchClinicListApi = (loc:any) => {
        const { oldSpeciality, newSpeciality, date, howToPay, gender,distance,languages,clinicId, patientUniqueId, clinicPage, results } = searchFilterData;
        let latitude: any = "", longitude: any = "";
        if (loc !== "") {
            latitude=loc.latitude;
            longitude=loc.longitude;
        }
        let langIds: any = [];
        if (languages && languages.length > 0) {
            langIds = languages.map((item: any) => {
                return item.value
            });
        }
        let request: any={};
        request = {
            location: {
                latitude: latitude,
                longitude: longitude,
                specialityId: newSpeciality,
                searchdate: (date !== null) ? moment(date).format('YYYY-MM-DD') : '',
                radiuskm: distance,
                insuranceProviderId: howToPay.type === 'insurance' ? howToPay.value : '',
                acceptAssignment: howToPay.type === 'cash' ? 'no' : '',
                socialAssistTypeID: howToPay.type === 'socialAssistance' ? howToPay.value : '',
                clinicId: '',
                patientUniqueId: patientUniqueId,
                page: 1,
                results: 25
            }
        }
        let isApiCall: boolean = false;
        if ( JSON.stringify(apiClinicListRequest) !== JSON.stringify(request)) {
            setApiClinicListRequest(request);
            isApiCall = true;
        }
        if (isApiCall) {
            dispatch(fetchLoadingPending());
            fetchClinicList(request).then((success) => {
                dispatch(fetchLoadingSuccess(false));
                if (success && success.status && success.status.error === false) {
                    if (success && success.total) {
                        setTotalCount(success.total);
                    }
                    dispatch(addClinicProviderList({
                        ...clinicProviderList,
                        clinicList: _.uniqBy(success.clinic, 'id'),
                        clinicTotalCount: success.total ? success.total : totalCount
                    }));
                }
            }).catch((err) => {
                dispatch(fetchLoadingSuccess(false));
                dispatch(addClinicProviderList({
                    ...clinicProviderList,
                    clinicList: [],
                    clinicTotalCount: 0,
                }));
                dispatch(searchFilter({
                    ...searchFilterData,
                    clinicPage: 1
                }));
                console.log(err);
            })
        }
    }
    return (
        <React.Fragment>
            <input value={place} type="text" id="autocomplete" ref={autcompleteRef} placeholder="City or postal code" className="form-control" onChange={(e)=>{setPlace(e.target.value)}} />
        </React.Fragment>
    )
}

export default Location