import React from 'react';
import Footer from '../../components/Footer/Footer';
import Main from '../../components/Main/Main';
import { Switch, Route, Redirect } from 'react-router-dom';
import Dentist from '../../components/Dentist/Dentist';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Listing from '../../components/Listing/Listing';
import About from '../../components/About/About';
import HowItWorks from '../../components/HowItWorks/HowItWorks';
import NewPatients from '../../components/NewPatients/NewPatients';
import Referral from '../../components/Referral/Referral';
import PracticeProfile from '../../components/PracticeProfile/PracticeProfile';
import PracticeList from '../../components/PracticeList/PracticeList';
import DoctorProfile from '../../components/DoctorProfile/DoctorProfile';
import PrivacyPolicy from '../../components/privacypolicy/PrivacyPolicy';
import TermsAndCondition from '../../components/termandcondition/TermsAndCondition';
import Invite from '../../components/Invite/Invite';

import 'bootstrap/dist/css/bootstrap.min.css';
import './../../assets/css/style.scss';
import Landing from '../../components/Landing/Landing';
import ClinicSearch from '../../components/LandingPageSearch/ClinicSearch';
import ClinicAndProviderList from '../../components/LandingPageSearch/ClinicAndProviderList';
// import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import { LoadingState } from '../../store/loadingIndicator/types';
import { IAppState } from '../../store';
import { connect, useDispatch } from 'react-redux';
import { LandingPage } from '../../components/LandingPageSearchNew/pages/LandingPage/LandingPage';
import List from '../../components/LandingPageSearchNew/pages/List/List';
import ProviderProfile from '../../../src/components/LandingPageSearchNew/pages/ProviderProfile/ProviderProfile';
import ClinicProfile from '../../../src/components/LandingPageSearchNew/pages/ClinicProfile/ClinicProfile';
import LoadingIndicator from '../../components/LandingPageSearchNew/pages/LoadingIndicator/LoadingIndicator';
import { isLogin } from '../../utils/global';
import { signInWithCustomToken } from '../../utils/firebaseUtils';
import Cookies from 'js-cookie';
import { ClinicProfileNew } from '../../components/LandingPageSearchNew/pages/ClinicProfile/ClinicProfileNew';
import { ProviderProfileNew } from '../../components/LandingPageSearchNew/pages/ProviderProfile/ProviderProfileNew';
import { LoginPage } from '../../components/LandingPageSearchNew/pages/Login';
import { SignupPage } from '../../components/LandingPageSearchNew/pages/Signup';
import { ClinicProfileNewXcare } from '../../components/LandingPageSearchNew/pages/ClinicProfile/ClinicProfileNewXcare';
import { ProviderProfileNewXcare } from '../../components/LandingPageSearchNew/pages/ProviderProfile/ProviderProfileNewXcare';
toast.configure({
    autoClose: 2000,
    draggable: true,
});
interface Props {
    match: any;
    loadingState: LoadingState;
    history: any;
}
interface State {
    isLoading: boolean;
    isLogin: any;
 }
class HomeContainer extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state={
            isLoading: false, isLogin: '',
        }
    }

    componentDidMount() {
        let iFrame: any = false;
        if (this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.iFrame) {
            iFrame = true;
        }
        if (this.props.history && this.props.history.location && this.props.history.location.search) {
            iFrame = new URLSearchParams(this.props.history.location.search).get('iframe') === "yes" ? true : false;
        }
        if (!isLogin('patient_cookie') && iFrame === true) {
			this.setState({ isLogin: '' });
		} else if(iFrame === true) {
			let isLogin: any = Cookies.getJSON('patient_cookie');
			this.setState({ isLogin: isLogin });
			try {
				signInWithCustomToken(isLogin.jwtToken).then(async (signInSuccess) => {
					console.log('firebase Login success');
				});
			} catch (error) {
				console.log('error Firebase Login', error);
			}
		}
    }
    shouldComponentUpdate(nextProps: Props, nextState: any){
        if (nextProps.loadingState.pending !== this.props.loadingState.pending) {
            console.log('Indicator')
            if (nextProps.loadingState.pending === true) {
                console.log('Indicator: true');
                this.setState({ isLoading: true });
            } else {
                console.log('Indicator: false');
                this.setState({ isLoading: false });
            }
        }
        return true;
    }
    render() {
        return (
            <>
            {
                this.state.isLoading &&
                <>
                    <LoadingIndicator></LoadingIndicator>
                </>
            }
                <ToastContainer />
                <Switch>
                    {/* <Route exact path="/" component={Main}></Route> */}
                    {/* <Route exact path="/" component={ClinicSearch}></Route>
                    <Route exact path="/list" component={ClinicAndProviderList}></Route> */}
                    <Route exact path="/" component={LandingPage}></Route>
                    <Route exact path="/login" component={LoginPage}></Route>
                    <Route exact path="/signup" component={SignupPage}></Route>
                    <Route exact path="/list" component={List}></Route>
                    <Route exact path="/privacy-policy" component={PrivacyPolicy}></Route>
                    <Route exact path="/terms-condition" component={TermsAndCondition}></Route>
                    <Route exact path="/landing" component={Landing}></Route>
                    <Route exact path="/clinic-registration" component={Dentist}></Route>
                    <Route exact path="/about" component={About}></Route>
                    <Route exact path="/how-it-works" component={HowItWorks}></Route>
                    <Route exact path="/new-patients" component={NewPatients}></Route>
                    <Route exact path="/referral" component={Referral}></Route>
                    <Route exact path="/:location/:name/:uniqueId/c" component={ClinicProfileNewXcare}></Route>
                    <Route exact path="/:location/:name/:uniqueId/d" component={ProviderProfileNewXcare}></Route>
                    <Route exact path="/:name/:uniqueId/c" component={ClinicProfileNew}></Route>
                    <Route exact path="/:name/:uniqueId/d" component={ProviderProfileNew}></Route>
                    <Route exact path="/dentists/:location" component={PracticeList}></Route>
                    <Route exact path="/listing" component={Listing}></Route>
                    <Route exact path="/invites/:inviteCode" component={Invite}></Route>
                    <Route render={(props) => (<Redirect to="/"></Redirect>)}></Route>
                </Switch>
                {/* <Footer></Footer> */}
            </>
        );
    }
}
const mapStateToProps = (state: IAppState) => ({
    loadingState: state.loading,
});
const mapDispatchToProps = (dispatch: any) => ({

});
export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);