import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { NavLink } from 'react-router-dom';
import { CONSTANT } from '../../constants';
import { FormWizardState } from '../../store/formwizard/types';
import { addInsurance, setPercentage } from '../../store/formwizard/actions';
interface Props {
    formwizardState: FormWizardState;
    addInsurance: typeof addInsurance;
    history: any;
    setPercentage: typeof setPercentage;
}
class Insurance extends React.Component<Props> {
    constructor(props: any) {
        super(props);
    }
    selectInsurance(insurance: any) {
        this.props.addInsurance({ insurance: insurance });
        this.props.setPercentage(70);
        this.props.history.push(insurance.link);
    }
    render() {
        const selectedInsurance: any = this.props.formwizardState.formwizard.insurance;
        return (
            <div className="col-12 col-md-10 wizard">
                <div className="row justify-content-start gutter-5">
                    <div className="col-12">                       
                        <div className="txt-box">
                            <h4 className="title">Do you have dental insurance?</h4>
                            <div className="sub-title">Why do we ask? We want to connect you with a dentist who accepts your plan!</div>
                        </div>
                    </div>
                    {
                        CONSTANT.QUIZ.isInsured.map((insured) => {
                            return (
                                <div className="col-lg-4 list-box" key={Math.random()}>
                                    <button
                                        className={"custom-click btn " + ((selectedInsurance && selectedInsurance !== "" && selectedInsurance.value === insured.value) ? 'active' : '')}
                                        onClick={(e) => { this.selectInsurance(insured) }}
                                    >
                                        <div className="img-container">
                                            <img src={process.env.PUBLIC_URL + '/' + insured.img} />
                                        </div>
                                        <div className="content-container">
                                            <h5 className="main-text">{insured.value}</h5>
                                        </div>
                                    </button>
                                </div>
                            )
                        })
                    }
                </div>
            </div>


        );
    }
}
const mapStateToProps = (state: IAppState) => ({
    formwizardState: state.formwizard
});
const mapDispatchToProps = (dispatch: any) => ({
    addInsurance: (insurance: any) => {
        dispatch(addInsurance(insurance));
    },
    setPercentage: (percent: number) => {
        dispatch(setPercentage(percent));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(Insurance);