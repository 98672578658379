import React from 'react';
import { HashRouter, Route, Redirect, Switch, BrowserRouter } from 'react-router-dom';
import Attitude from '../../components/Attitude/Attitude';
import $ from 'jquery';
import Slider from "react-slick";
import Reason from '../../components/Reason/Reason';
import Goals from '../../components/Goals/Goals';
import DentistService from '../../components/DentistService/DentistService';
import DentistUrgent from '../../components/DentistUrgent/DentistUrgent';
import Criteria from '../../components/Criteria/Criteria';
import LastVisited from '../../components/LastVisited/LastVisited';
import DentalAnxiety from '../../components/DentalAnxiety/DentalAnxiety';
import HowSoon from '../../components/HowSoon/HowSoon';
import Availability from '../../components/Availability/Availability';
import Insurance from '../../components/Insurance/Insurance';
import SocialAssistance from '../../components/SocialAssistance/SocialAssistance';
import Gender from '../../components/Gender/Gender';
import InsuranceDetails from '../../components/InsuranceDetails/InsuranceDetails';
import NotReadyReason from '../../components/NotReadyReason/NotReadyReason';
import ReadyToBook from '../../components/ReadyToBook/ReadyToBook';
import Email from '../../components/Email/Email';
import { FormWizardState } from '../../store/formwizard/types';
import { IAppState } from '../../store';
import { connect } from 'react-redux';
import Process from '../../components/Process/Process';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import { LoadingState } from '../../store/loadingIndicator/types';

interface Props {
    match: any;
    history: any;
    formwizardState: FormWizardState;
    location: any;
    loadingState: LoadingState;
}
interface State {
    isStateLoaded: boolean;
    isLoading:boolean;
}
class FormWizardContainer extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            isStateLoaded: false,isLoading:false
        }
    }
    componentDidMount() {
        const value: any = localStorage.getItem('front_key_store');
        if (value === undefined || value === null || value === "") {
            this.props.history.push('/');
        } else {
            const valueObj = JSON.parse(value);
            if (valueObj.formwizard.formwizard.location === "") {
                this.props.history.push('/');
            } else {
                this.setState({ isStateLoaded: true });
            }
        }
    }
    setPercentageToView(value: any) {
        //@ts-ignore
        // var ps = new window.PerfectScrollbar('.scrollable ');
        var val = value;
        var $circle = $('#svg #bar');
        if (isNaN(val)) {
            val = 100;
        } else {
            var r: any = $circle.attr('r');
            var c = Math.PI * (r * 2);
            if (val < 0) { val = 0; }
            if (val > 100) { val = 100; }
            var pct = ((100 - val) / 100) * c;
            $circle.css({ strokeDashoffset: pct });
            $('#cont').attr('data-pct', val);
            $('#cnum').html(val + "<sup>%</sup>");
        }
    }
    shouldComponentUpdate(nextProps: Props, nextState: any) {
        if (this.props !== nextProps) {
            this.setPercentageToView(nextProps.formwizardState.percent);
            if (nextProps.formwizardState.formwizard.location !== "") {
                this.setState({ isStateLoaded: true });
            } else {
                this.setState({ isStateLoaded: false });
            }
        }
        return true;
    }
    render() {
        const settings = {
            slidesToShow: 1,
            arrows: false,
            autoplay: true,
            dots: false,
            infinite: false,
            Speed: 600
        };
        const settingsQuote = {
            slidesToShow: 1,
            arrows: false,
            autoplay: true,
            fade: true,
            cssEase: 'linear',
            dots: false,
            infinite: true,
            Speed: 1000
        }
        return (
            <>
            <div className="formApp">
                <div className="side-nav">
                    {
                        this.props.match.path + "/process" !== this.props.location.pathname &&
                        <PerfectScrollbar>
                            <div className="side-nav-inner scrollable">
                                <div className="wiz_logo">
                                    <img src={process.env.PUBLIC_URL + "/images/logo-black.svg"} className="img-fluid" />
                                </div>
                                <div className="bar-sec">
                                    <div className="progress">
                                        <div className="progress-bar" style={{ height: this.props.formwizardState.percent + "%" }}></div>
                                    </div>
                                </div>
                                <div className="text-sec">
                                    <div className="sub-title">Your progress</div>
                                    <div className="main-title">{this.props.formwizardState.percent}% completed</div>
                                </div>
                            </div>
                        </PerfectScrollbar>
                    }
                </div>
                <div className="page-container">
                    <div className="form-wizard">
                        <div className="container">
                            <div className="row  h-100 ">
                                {
                                    this.state.isStateLoaded === true &&
                                    <>
                                     
                                        <Switch>
                                            <Route exact path={this.props.match.path + "/attitude"} component={Attitude}></Route>
                                            <Route exact path={this.props.match.path + "/reason"} component={Reason}></Route>
                                            <Route exact path={this.props.match.path + "/goals"} component={Goals}></Route>
                                            <Route exact path={this.props.match.path + "/criteria"} component={Criteria}></Route>
                                            <Route exact path={this.props.match.path + "/dentist-services"} component={DentistService}></Route>
                                            <Route exact path={this.props.match.path + "/dentist-urgent"} component={DentistUrgent}></Route>
                                            <Route exact path={this.props.match.path + "/last-visited"} component={LastVisited}></Route>
                                            <Route exact path={this.props.match.path + "/dental-anxiety"} component={DentalAnxiety}></Route>
                                            <Route exact path={this.props.match.path + "/availability"} component={Availability}></Route>
                                            <Route exact path={this.props.match.path + "/how-soon"} component={HowSoon}></Route>
                                            <Route exact path={this.props.match.path + "/insurance"} component={Insurance}></Route>
                                            <Route exact path={this.props.match.path + "/social-assistance"} component={SocialAssistance}></Route>
                                            <Route exact path={this.props.match.path + "/insurance-details"} component={InsuranceDetails}></Route>
                                            <Route exact path={this.props.match.path + "/gender"} component={Gender}></Route>
                                            <Route exact path={this.props.match.path + "/ready-to-book"} component={ReadyToBook}></Route>
                                            <Route exact path={this.props.match.path + "/not-ready-reason"} component={NotReadyReason}></Route>
                                            <Route exact path={this.props.match.path + "/email"} component={Email}></Route>
                                            <Route exact path={this.props.match.path + "/process"} component={Process}></Route>
                                            {/* <Route exact path="/" render={(props) => (
                                            <Redirect to="/quiz/attitude"></Redirect>
                                        )}></Route>
                                        <Route render={(props) => (<Redirect to="/quiz/attitude"></Redirect>)}></Route> */}
                                        </Switch>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }
}
const mapStateToProps = (state: IAppState) => ({
    formwizardState: state.formwizard,
    loadingState: state.loading,
});
const mapDispatchToProps = (dispatch: any) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(FormWizardContainer);